import React from 'react';
import { Card } from 'react-fidelity-ui';

const RowShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Row
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <pre>
            {`import { Grid } from 'react-fidelity-ui';

const { Row } = Grid;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component is part of the <code>Grid</code> composite component. <code>Row</code> always wraps <code>Cols</code> and it does not expose any specific props.
        </p>
      </Card>
    </div>
  );
};

export default RowShowcase;
