import React from 'react';
import { Card, Table, Icon, Notification } from 'react-fidelity-ui';
import notificationApi from '../../../config/components/notificationApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const NotificationShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Notification
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {'import { Notification } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          Standard notification component.
        </p>
      </Card>

      <h2>Types</h2>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="info">
            <span className="h3">Info</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="info">
  <span className="h3">Info</span>
  <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="warning">
            <span className="h3">Warning</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="warning">
  <span className="h3">Warning</span>
  <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="success">
            <span className="h3">Success</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="success">
  <span className="h3">Success</span>
  <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="danger">
            <span className="h3">Danger</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="danger">
  <span className="h3">Danger</span>
  <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="primary">
            <span className="h3">Primary</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="primary">
    <span className="h3">Primary</span>
    <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
  </Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification type="secondary">
            <span className="h3">Secondary</span>
            <p className="mb-0">{dummyTextShort}</p>
          </Notification>

          <pre>
            {`<Notification type="secondary">
  <span className="h3">Secondary</span>
  <p className="mb-0">${dummyTextShort.substr(0, 20)}...</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification
            type="success"
            icon={<Icon id="ion-android-done" size={48} />}
          >
            <span className="h3">Success</span>
            <p className="mb-0">Well done! You successfully read this important alert message.</p>
          </Notification>

          <pre>
            {`<Notification 
  type="success"
  icon={<Icon id="ion-android-done" size={48} />}
>
  <span className="h3">Success</span>
  <p className="mb-0">Well done! You successfully read this important alert message.</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification
            type="info"
            elevated
            hovered
            rounded={false}
            bordered={false}
          >
            Heads up! This alert needs your attention, but it's not super important.
          </Notification>

          <pre>
            {`<Notification
  type="info"
  elevated
  hovered
  rounded={false}
  bordered={false}
>
  Heads up! This alert needs your attention, but it's not super important.
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification
            type="warning"
            hovered
            prependIcon={false}
            icon={<Icon id="ion-android-warning" size={56} />}
          >
            <span className="h3">Warning</span>
            <p className="mb-0">Heads up! This alert needs your attention and is somewhat important.</p>
          </Notification>

          <pre>
            {`<Notification
  type="warning"
  hovered
  prependIcon={false}
  icon={<Icon id="ion-android-warning" size={56} />}
>
  <span className="h3">Warning</span>
  <p className="mb-0">Heads up! This alert needs your attention and is somewhat important.</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div>
          <Notification
            type="danger"
            hovered
            rounded={false}
            bordered={false}
          >
            <span className="h3">Danger</span>
            <p className="mb-0">Oh snap! Change a few things up and try submitting again.</p>
          </Notification>

          <pre>
            {`<Notification
  type="danger"
  hovered
  rounded={false}
  bordered={false}
>
  <span className="h3">Danger</span>
  <p className="mb-0">Oh snap! Change a few things up and try submitting again.</p>
</Notification>`}
          </pre>
        </div>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={notificationApi}
          columns={apiColumns}
          itemId="property"
          className="mb-0"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default NotificationShowcase;
