import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardDivider, Icon, Notification } from 'react-fidelity-ui';

const { scrollTo } = window;

const Icons = () => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Icons</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-android-done-all"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Icons are treated as an external resource in Fidelity UI.</strong><br />
            To keep the overall size of the library small and to prevent coupling with specific icons sets, Fidelity UI does not expose icons. Instead, it enables configuring icons via its <Link to="/components/app"><code>App</code></Link> component and displaying them using the <Link to="/components/icon"><code>Icon</code></Link> component.
          </p>
        </div>
      </Card>

      <h2>Configuring Icons</h2>

      <Notification
        hovered
        bordered={false}
      >
        <p className="h4 mb-1">Fidelity UI works best with icon sprites.</p>
        <p className="mb-0">
          This approach was selected with performance considerations in mind as icon sprites generally load faster than single svg icons.
        </p>
      </Notification>

      <Card
        hovered
        bordered={false}
      >
        <p>
          <strong>You need to pass your icon sprite file to the <Link to="/components/app"><code>App</code></Link> component</strong>:<br />
        </p>

        <pre className="mt-1 mb-1">
          {'import { App } from \'react-fidelity-ui\';\n'}
          {'import iconsSprite from \'./icons-sprite.svg\';\n'}
          {'...\n\n'}
          {'<App iconsSprite={iconsSprite}>\n ...\n</App>'}
        </pre>

        <small>Consider using <Link to="/components/app"><code>App</code></Link> as your app's wrapper component.</small><br />

        <small>
          Underneath, <Link to="/components/app"><code>App</code></Link> stores and exposes the icon sprite using the <a href="https://reactjs.org/docs/context.html" target="_blank" rel="noopener noreferrer">React Context API</a>.
        </small><br />
        <small>In the above example <code>'./icons-sprite.svg'</code> should be replaced with the path to your icon sprite.</small>

        <CardDivider />

        <p>
          <strong>Now, <Link to="/components/icon"><code>Icon</code></Link> components inside the <Link to="/components/app"><code>App</code></Link> component tree can be used with your icons.</strong><br />
        </p>

        <pre className="mt-1 mb-1">
          {'import { Icon } from \'react-fidelity-ui\';\n'}
          {'...\n\n'}
          {'<App iconsSprite={iconsSprite}>\n ...\n <Icon id="icon-id" />\n</App>'}
        </pre>

        <small>
          The <Link to="/components/icon"><code>Icon</code></Link> component will work properly only when inside a <Link to="/components/app"><code>App</code></Link> component with correctly configured <code>iconsSprite</code>.
        </small><br />

        <small>
          In the above example <code>'icon-id'</code> should be replaced with a valid icon id from your icon sprite.
        </small>

        <CardDivider />

        <div className="d-flex align-items-center">
          <Icon
            id="ion-alert"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">This website uses <a href="https://ionicons.com/" target="_blank" rel="noopener noreferrer">ion icons</a> in a <a href="https://rastasheep.com/ionicons-sprite/" target="_blank" rel="noopener noreferrer">sprite format</a>.</p>
        </div>
      </Card>
    </div>
  );
};

export default Icons;
