import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, Layout, Button, ButtonGroup, Input, Email, Password, InputGroup, Icon, Checkbox, Dropdown, Transition, Badge, utils } from 'react-fidelity-ui';
import ReactGA from 'react-ga';
import { SketchPicker } from 'react-color';
import AppLayout from '../../components/AppLayout';
import { AppContext } from '../../context';
import { setTitle } from '../../context/actions';
import app from '../../config/app';
import { generateColor, debounce, uid } from '../../utils';

const { Content } = Layout;
const { Row, Col } = Grid;

const Home = () => {
  const { dispatch } = useContext(AppContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [secondaryColor, setSecondaryColor] = useState(utils.getCssVar('--color-secondary'));
  const [isColorPickerOn, setIsColorPickerOn] = useState(false);
  const [checkA, setCheckA] = useState(true);

  const onChangeColor = ({ hex, rgb }) => {
    const { r, g, b, a } = rgb;
    const nextColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    setSecondaryColor(nextColor);

    Home.debouncer(() => {
      const nextColorLight = generateColor(hex, 40);
      const nextColorDark = generateColor(hex, -40);

      utils.setCssVar('--color-secondary', nextColor);
      utils.setCssVar('--color-secondary--light', nextColorLight);
      utils.setCssVar('--color-secondary--dark', nextColorDark);
    });
  };

  useEffect(() => {
    setTitle(dispatch)(null);
    // ReactGA.pageview(window.location.pathname);
    setSecondaryColor(utils.getCssVar('--color-secondary'));
  }, [dispatch]);

  return (
    <AppLayout>
      <Content
        align="vcenter"
        className="live-theme-demo"
      >
        <Grid>
          <Row className="m-0">
            <Col widths={['md-12', 'lg-6']}>
              <Transition type="fade-in-right">
                <Badge
                  size="md"
                  className="mb-1 mb-lg-0"
                >
                  <a
                    href={app.githubLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="color--white"
                  >
                    v0.1.2
                  </a>
                </Badge>
                <h2 className="color--secondary">{app.slogan}</h2>
                <div className="app-description">
                  <p>
                    <strong>{app.description}</strong>
                  </p>
                </div>

                <Dropdown
                  active={isColorPickerOn}
                  onClickOutside={() => {
                    if (isColorPickerOn) {
                      setIsColorPickerOn(!isColorPickerOn);
                    }
                  }}
                >
                  <Button
                    type="secondary"
                    size="lg"
                    onClick={() => setIsColorPickerOn(!isColorPickerOn)}
                  >
                    Change color
                  </Button>

                  <Dropdown.Body
                    style={{
                      top: 'calc(100% + 10px)',
                      left: '50%',
                      transform: 'translateX(-50%)'
                    }}
                  >
                    <Dropdown.Item className="p-0">
                      <div className="sketch-wrapper">
                        <SketchPicker
                          color={secondaryColor}
                          onChange={onChangeColor}
                        />
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Body>
                </Dropdown>
              </Transition>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <Row className="m-0">
                <Col
                  widths={['sm-12', 'md-10']}
                  className="col-form p-0 p-lg-2"
                >
                  <Transition type="fade-in-left">
                    <Card hovered>
                      <InputGroup isRow>
                        <Input
                          value={firstName}
                          placeholder="First name"
                          onChange={({ target }) => {
                            setFirstName(target.value);
                          }}
                        />

                        <Input
                          value={lastName}
                          placeholder="Last name"
                          onChange={({ target }) => {
                            setLastName(target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup>
                        <Input
                          value={address}
                          icon={(
                            <Icon
                              id="ion-android-map"
                              className="color--secondary"
                            />
                          )}
                          placeholder="Address"
                          onChange={({ target }) => {
                            setAddress(target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup>
                        <Email
                          value={email}
                          icon={(
                            <Icon
                              id="ion-email"
                              className="color--secondary"
                            />
                          )}
                          placeholder="Email"
                          onChange={({ target }) => {
                            setEmail(target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup>
                        <Password
                          value={password}
                          placeholder="Password"
                          autoComplete="new-password"
                          onChange={({ target }) => {
                            setPassword(target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup className="text-align--left">
                        <Checkbox
                          label="Toggle setting"
                          isSwitch
                          checked={checkA}
                          onChange={({ target }) => {
                            setCheckA(target.checked);
                          }}
                        />
                      </InputGroup>

                      <ButtonGroup
                        align="right"
                        stacked
                        rounded
                      >
                        <Button outlined>
                          Cancel
                        </Button>

                        <Button type="secondary">
                          OK
                        </Button>
                      </ButtonGroup>
                    </Card>
                  </Transition>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </Content>
    </AppLayout>
  );
};

Home.debouncer = debounce(uid(), 150);

export default Home;
