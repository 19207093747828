import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { RESET, SET_TITLE, SET_DEFAULT_SECONDARY_COLOR } from './types';
import app from '../config/app';

export const initialState = {
  defaultSecondaryColor: null,
  title: app.name
};

const reducer = (state, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case SET_TITLE:
      return {
        ...state,
        title: action.title
      };
    case SET_DEFAULT_SECONDARY_COLOR:
      return {
        ...state,
        defaultSecondaryColor: action.color,
        defaultSecondaryColorLight: action.colorLight,
        defaultSecondaryColorDark: action.colorDark
      };
    default:
      return state;
  }
};

export const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {
    state,
    dispatch
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export const AppConsumer = AppContext.Consumer;
