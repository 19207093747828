import React from 'react';
import { Card, Table, Grid } from 'react-fidelity-ui';
import cardApi from '../../../config/components/cardApi';
import apiColumns from '../../../config/apiColumns';
import dummyText from '../../../mock/dummyText';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const CardShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'Card'}
        </h2>

        <small>
          <span>
            {'Composite stateless functional component'}
          </span>
          <br />
          <code>
            {`import { Card } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Displays content related to a single subject.'}
        </p>
      </Card>

      <h2>
        {'States'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card hovered>
                {'Hovered'}
              </Card>

              <pre>
                {'<Card hovered> \n Hovered\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card padded>
                {'Padded'}
              </Card>

              <pre>
                {'<Card padded> \n Padded\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card bordered>
                {'Bordered'}
              </Card>

              <pre>
                {'<Card bordered> \n Bordered\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card loading>
                {'Loading'}
              </Card>

              <pre>
                {'<Card loading> \n Loading\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card flat>
                {'Flat'}
              </Card>

              <pre>
                {'<Card flat> \n Flat\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card rounded>
                {'Rounded'}
              </Card>

              <pre>
                {'<Card rounded> \n Rounded\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Padding sizes'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-4']}>
              <Card paddingSize="sm">
                {'Small padding'}
              </Card>

              <pre>
                {'<Card paddingSize="sm"> \n Small padding\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Card>
                {'Medium padding'}
              </Card>

              <pre>
                {'<Card> \n Medium padding\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Card paddingSize="lg">
                {'Large padding'}
              </Card>

              <pre>
                {'<Card paddingSize="lg"> \n Large padding\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card
                flat
                hovered
              >
                {dummyText}
              </Card>

              <pre>
                {'<Card \n flat \n hovered\n> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card padded>
                {dummyText}
              </Card>

              <pre>
                {'<Card padded> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card
                hovered
                flat={true}
                bordered={false}
                rounded={false}
              >
                {dummyText}
              </Card>

              <pre>
                {'<Card \n bordered={false} \n rounded={false}\n> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card
                flat
                hovered
                rounded={false}
              >
                {dummyText}
              </Card>

              <pre>
                {'<Card \n flat \n rounded\n> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <Card
                hovered
                bordered={false}
                padded={false}
              >
                {dummyTextShort}
              </Card>

              <pre>
                {'<Card \n hovered \n bordered={false} \n padded\n> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Card
                paddingSize="lg"
                hovered
                rounded={false}
              >
                {dummyTextShort}
              </Card>

              <pre>
                {'<Card \n paddingSize="lg" \n hovered \n rounded="false"\n> \n Lorem ipsum...\n</Card>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={cardApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default CardShowcase;
