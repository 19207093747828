import React, { useState } from 'react';
import { Card, Button, Table, Modal, Grid, Icon } from 'react-fidelity-ui';
import modalHeaderApi from '../../../config/components/modalHeaderApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const ModalHeaderShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Header</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Modal } from 'react-fidelity-ui';
            
const { Header } = Modal;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          A modal can have a header.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsAOn(!isAOn)}>
                Simple Header
              </Button>

              <Modal active={isAOn}>
                <Modal.Backdrop>
                  <Modal.Content size="md">
                    <Modal.Header>
                      <h3 className="mb-0">Simple Header</h3>
                    </Modal.Header>

                    <Modal.Body className="pt-0">
                      <p>{dummyTextShort}</p>

                      <Button onClick={() => setIsAOn(false)}>
                        Close
                      </Button>
                    </Modal.Body>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Simple Header
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="md">
      <Modal.Header>
        <h3 className="mb-0">Simple Header</h3>
      </Modal.Header>
      
      <Modal.Body className="pt-0">
        <p>Lorem ipsum dolor sit amet...</p>
        
        <Button onClick={disable}>Close</Button>
      </Modal.Body>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsBOn(!isBOn)}>
                Header with a close button
              </Button>

              <Modal active={isBOn}>
                <Modal.Backdrop>
                  <Modal.Content size="lg">
                    <Modal.Header>
                      <h3 className="mb-0">Header with a close button</h3>

                      <Button
                        type="icon"
                        onClick={() => setIsBOn(false)}
                      >
                        <Icon id="ion-close" />
                      </Button>
                    </Modal.Header>

                    <Modal.Body className="pt-0">
                      <p className="mb-0">{dummyTextShort}</p>
                    </Modal.Body>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Header with a close button
</Button>

<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="lg">
      <Modal.Header>
        <h3 className="mb-0">Header with a close button</h3>

        <Button
          type="icon"
          onClick={() => setIsBOn(false)}
        >
          <Icon id="ion-close" />
        </Button>
      </Modal.Header>
      
      <Modal.Body className="pt-0">
        <p className="mb-0">Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={modalHeaderApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ModalHeaderShowcase;
