import React, { useState } from 'react';
import { InputGroup, Input, Email, Textarea, Icon, Button, Card, Grid } from 'react-fidelity-ui';
import { validateEmail } from '../../../utils/validators';

const { Row, Col } = Grid;

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [isMessageTouched, setIsMessageTouched] = useState(false);
  const [isNameTouched, setIsNameTouched] = useState(false);
  const isEmailFilled = email !== '';
  const isValidEmail = validateEmail(email);
  const isMessageFilled = message !== '';
  const isNameFilled = name !== '';
  const isDisabled = !isEmailFilled
    || !isValidEmail
    || !isMessageFilled
    || !isNameFilled;
  const messageTitle = isMessageTouched && !isMessageFilled
    ? 'Message is required.'
    : null;
  const nameTitle = isNameTouched && !isNameFilled
    ? 'Name is required.'
    : null;
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      paddingSize="lg"
      bordered={false}
    >
      <h2 className="text-align--center">
        {'Contact Us'}
      </h2>

      <form>
        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="E-mail"
            icon={<Icon id="ion-email" />}
            hovered
            validated={isEmailFilled}
            valid={!isEmailFilled || isValidEmail}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Textarea
            value={message}
            label="Your message"
            placeholder="Message"
            title={messageTitle}
            hovered
            validated={isMessageTouched}
            valid={isMessageFilled}
            onChange={({ target }) => {
              setIsMessageTouched(true);
              setMessage(target.value);
            }}
          />
        </InputGroup>

        <Grid>
          <Row className="mr-0">
            <Col
              widths={['sm-12', 'md-6']}
              className="pr-0"
            >
              <InputGroup>
                <Input
                  value={name}
                  label="Your name"
                  placeholder="Name"
                  title={nameTitle}
                  hovered
                  validated={isNameTouched}
                  valid={isNameFilled}
                  onChange={({ target }) => {
                    setIsNameTouched(true);
                    setName(target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="pr-0"
            >
              <Button
                type="success"
                className="mt-3"
                expanded
                disabled={isDisabled}
                onClick={onClick}
              >
                {'Send Message'}
              </Button>
            </Col>
          </Row>
        </Grid>
      </form>
    </Card>
  )
};

export default ContactUs;
