import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Sidebar, SidebarItem, Tabs, Tab, Button, Grid, Layout } from 'react-fidelity-ui';
import AppLayout from '../../components/AppLayout';
import AppShowcase from '../../components/Components/App';
import BadgeShowcase from '../../components/Components/Badge';
import ButtonShowcase from '../../components/Components/Button';
import ButtonGroupShowcase from '../../components/Components/ButtonGroup';
import CardShowcase from '../../components/Components/Card';
import CardDividerShowcase from '../../components/Components/CardDivider';
import CollapseShowcase from '../../components/Components/Collapse';
import DropdownShowcase from '../../components/Components/Dropdown';
import DropdownBodyShowcase from '../../components/Components/DropdownBody';
import DropdownTextShowcase from '../../components/Components/DropdownText';
import DropdownItemShowcase from '../../components/Components/DropdownItem';
import DropdownDividerShowcase from '../../components/Components/DropdownDivider';
import EmptyShowcase from '../../components/Components/Empty';
import GridShowcase from '../../components/Components/Grid';
import RowShowcase from '../../components/Components/Row';
import ColShowcase from '../../components/Components/Col';
import IconShowcase from '../../components/Components/Icon';
import ImageShowcase from '../../components/Components/Image';
import ProgressShowcase from '../../components/Components/Progress';
import NotificationShowcase from '../../components/Components/Notification';
import DividerShowcase from '../../components/Components/Divider';
import FillableShowcase from '../../components/Components/Fillable';
import InputShowcase from '../../components/Components/Input';
import LayoutShowcase from '../../components/Components/Layout';
import TransitionShowcase from '../../components/Components/Transition';
import { AppContext } from '../../context';
import { setTitle } from '../../context/actions';
import componentCategories from '../../config/components';
import { isStr } from '../../utils';
import TabsShowcase from '../../components/Components/Tab';
import TableShowcase from '../../components/Components/Table';
import SidebarShowcase from '../../components/Components/Sidebar';
import NavShowcase from '../../components/Components/Nav';
import ModalShowcase from '../../components/Components/Modal';
import ModalBackdropShowcase from '../../components/Components/ModalBackdrop';
import ModalContentShowcase from '../../components/Components/ModalContent';
import ModalHeaderShowcase from '../../components/Components/ModalHeader';
import ModalBodyShowcase from '../../components/Components/ModalBody';
import ModalFooterShowcase from '../../components/Components/ModalFooter';

const { Content } = Layout;
const { Row, Col } = Grid;
const { scrollTo } = window;

const Components = ({ match, history }) => {
  const { params } = match;
  const hasPredefinedTab = (tab) => isStr(tab)
    && componentCategories.some(({ name }) => name === tab);
  const firstCategory = hasPredefinedTab(params.tab)
    ? componentCategories.find(({ name }) => name === params.tab)
    : componentCategories[0];
  const firstComponent = firstCategory.components.length > 0
    ? firstCategory.components[0]
    : firstCategory;
  const [activeCategory, setActiveCategory] = useState(firstCategory);
  const [activeComponent, setActiveComponent] = useState(firstComponent);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    setTitle(dispatch)('Components');
    // ReactGA.pageview(window.location.pathname);
    scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    const firstCategory = hasPredefinedTab(params.tab)
      ? componentCategories.find(({ name }) => name === params.tab)
      : componentCategories[0];
    const firstComponent = firstCategory.components.length > 0
      ? firstCategory.components[0]
      : firstCategory;
    setActiveCategory(firstCategory);
    setActiveComponent(firstComponent);
  }, [params]);

  return (
    <AppLayout>
      <Content className="width-100">
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-3']}>
              <Sidebar>
                {componentCategories.map((category) => {
                  const isActive = category.name === activeCategory.name;

                  return (
                    <SidebarItem
                      key={`demo-${category.name}`}
                      active={isActive}
                      renderItem={(className) => {
                        const itemClassName = classNames({
                          'bg--transparent': true,
                          'color--secondary': isActive
                        });

                        return (
                          <Button
                            type="text"
                            bordered={false}
                            squared
                            className={`${className} ${itemClassName}`}
                            onClick={() => {
                              setActiveCategory(category);

                              const nextComponent = category.components.length === 0
                                ? category
                                : category.components[0];

                              setActiveComponent(nextComponent);
                              scrollTo(0, 0);
                              history.push(`/components/${category.name}`);
                            }}
                          >
                            {category.label}
                          </Button>
                        );
                      }}
                    />
                  );
                })}
              </Sidebar>
            </Col>

            <Col widths={['sm-12', 'md-9']}>
              {!activeCategory.hideTabs && (
                <Tabs>
                  {activeCategory.components.map(component => (
                    <Tab
                      key={`category-${activeCategory.name}-component-${component.name}`}
                      active={activeComponent.name === component.name}
                    >
                      <Button
                        type="text"
                        onClick={() => {
                          setActiveComponent(component);
                        }}
                      >
                        {component.label}
                      </Button>
                    </Tab>
                  ))}
                </Tabs>
              )}

              {activeComponent.name === 'app' && (
                <AppShowcase />
              )}

              {activeComponent.name === 'badge' && (
                <BadgeShowcase />
              )}

              {activeComponent.name === 'button' && (
                <ButtonShowcase />
              )}

              {activeComponent.name === 'button_group' && (
                <ButtonGroupShowcase />
              )}

              {activeComponent.name === 'card' && (
                <CardShowcase />
              )}

              {activeComponent.name === 'card_divider' && (
                <CardDividerShowcase />
              )}

              {activeComponent.name === 'collapse' && (
                <CollapseShowcase />
              )}

              {activeComponent.name === 'divider' && (
                <DividerShowcase />
              )}

              {activeComponent.name === 'empty' && (
                <EmptyShowcase />
              )}

              {activeComponent.name === 'dropdown' && (
                <DropdownShowcase />
              )}

              {activeComponent.name === 'dropdown_body' && (
                <DropdownBodyShowcase />
              )}

              {activeComponent.name === 'dropdown_text' && (
                <DropdownTextShowcase />
              )}

              {activeComponent.name === 'dropdown_item' && (
                <DropdownItemShowcase />
              )}

              {activeComponent.name === 'dropdown_divider' && (
                <DropdownDividerShowcase />
              )}

              {activeComponent.name === 'fillable' && (
                <FillableShowcase />
              )}

              {activeComponent.name === 'grid' && (
                <GridShowcase />
              )}

              {activeComponent.name === 'grid_row' && (
                <RowShowcase />
              )}

              {activeComponent.name === 'grid_col' && (
                <ColShowcase />
              )}

              {activeComponent.name === 'icon' && (
                <IconShowcase />
              )}

              {activeComponent.name === 'image' && (
                <ImageShowcase />
              )}

              {activeComponent.name === 'input' && (
                <InputShowcase />
              )}

              {activeComponent.name === 'layout' && (
                <LayoutShowcase />
              )}

              {activeComponent.name === 'modal' && (
                <ModalShowcase />
              )}

              {activeComponent.name === 'modal_backdrop' && (
                <ModalBackdropShowcase />
              )}

              {activeComponent.name === 'modal_content' && (
                <ModalContentShowcase />
              )}

              {activeComponent.name === 'modal_header' && (
                <ModalHeaderShowcase />
              )}

              {activeComponent.name === 'modal_body' && (
                <ModalBodyShowcase />
              )}

              {activeComponent.name === 'modal_footer' && (
                <ModalFooterShowcase />
              )}

              {activeComponent.name === 'nav' && (
                <NavShowcase />
              )}

              {activeComponent.name === 'notification' && (
                <NotificationShowcase />
              )}

              {activeComponent.name === 'progress' && (
                <ProgressShowcase />
              )}

              {activeComponent.name === 'sidebar' && (
                <SidebarShowcase />
              )}

              {activeComponent.name === 'tabs' && (
                <TabsShowcase />
              )}

              {activeComponent.name === 'table' && (
                <TableShowcase />
              )}

              {activeComponent.name === 'transition' && (
                <TransitionShowcase />
              )}
            </Col>
          </Row>
        </Grid>
      </Content>
    </AppLayout>
  );
};

Components.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string
    })
  }).isRequired
};

export default Components;
