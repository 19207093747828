import React from 'react';
import { Badge } from 'react-fidelity-ui';
import { isNull } from '../utils';

export default [{
  name: 'property',
  label: 'Property',
  width: '20%',
  selected: true,
  default: true,
  render: item => (
    <strong>{item.property}</strong>
  )
}, {
  name: 'description',
  label: 'Description',
  width: '45%',
  selected: true,
  default: true,
  render: item => item.description
}, {
  name: 'type',
  label: 'Type',
  width: '20%',
  selected: true,
  default: true,
  render: item => (
    <Badge
      type="warning"
      size="md"
    >
      {item.type}
    </Badge>
  )
}, {
  name: 'default',
  label: 'Default',
  width: '15%',
  selected: true,
  default: true,
  render: item => !isNull(item.default) && (
    <Badge
      type="info"
      size="md"
      className="mb-1"
      outlined
    >
      {item.default}
    </Badge>
  )
}];
