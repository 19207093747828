import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { App as FidelityUIApp, utils } from 'react-fidelity-ui';
import 'react-fidelity-ui/dist/index.css';
import Home from './views/Home';
import Docs from './views/Docs';
import Components from './views/Components';
import Assets from './views/Assets';
import Philosophy from './views/Philosophy';
import NotFound from './views/NotFound';
import iconsSprite from './images/ionicons-sprite.svg';
import { AppContext } from './context';
import { setDefaultSecondaryColor } from './context/actions';
import app from './config/app';
import { isNonEmptyStr } from './utils';
import './config.css';
import './App.css';

const { location, document } = window;

// initialize analytics
ReactGA.initialize(app.googleAnalyticsKey);

function App() {
  const { state, dispatch } = useContext(AppContext);
  const getTitle = (page) => (isNonEmptyStr(page) ? `${page} - ${app.name}` : app.name);

  useEffect(() => {

    if (!state.defaultSecondaryColor) {
      const secondaryColor = utils.getCssVar('--color-secondary');
      const secondaryColorLight = utils.getCssVar('--color-secondary--light');
      const secondaryColorDark = utils.getCssVar('--color-secondary--dark');
      setDefaultSecondaryColor(dispatch)(secondaryColor, secondaryColorLight, secondaryColorDark);
    }
  }, [state.defaultSecondaryColor, dispatch]);

  useEffect(() => {
    const { pathname, search } = location;
    const { title } = state;
    document.title = getTitle(title);

    // track page view
    // ReactGA.pageview(`${pathname}${search}`);
  }, [state]);

  return (
    <FidelityUIApp iconsSprite={iconsSprite}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            component={Home}
          />

          <Route
            path="/assets/:tab?"
            exact
            component={Assets}
          />

          <Route
            path="/docs/:tab?"
            exact
            component={Docs}
          />

          <Route
            path="/components/:tab?"
            exact
            component={Components}
          />

          <Route
            path="/philosophy/:tab?"
            exact
            component={Philosophy}
          />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </FidelityUIApp>
  );
}

export default App;
