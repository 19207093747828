import React from 'react';
import { Card, Button, Transition, ButtonGroup } from 'react-fidelity-ui';

const CardB = () => {
  return (
    <div className="bg-image e-commerce-b-bg mb-3">
      <Card
        paddingSize="lg"
        className="bg--transparent color--white mb-0"
        hovered
        bordered={false}
      >
        <Transition type="fade-in-left">
          <h1 className="color--white text-align--right">
            {'Hand Embroidered Scarf'}
          </h1>

          <p className="text-align--right mb-4">
            {'This I have produced as a scantling of Jack\'s great eloquence and the force of his reasoning upon such abstruse matters'}
          </p>

          <ButtonGroup
            align="right"
            stacked
            rounded
          >
            <Button size="lg">
              {'$39.99'}
            </Button>
            <Button size="lg">
              {'Buy Now'}
            </Button>
          </ButtonGroup>
        </Transition>
      </Card>
    </div>
  )
};

export default CardB;
