import React from 'react';
import { Card, CardDivider } from 'react-fidelity-ui';
import dummyText from '../../../mock/dummyText';
import dummyTextShort from '../../../mock/dummyTextShort';

const CardDividerShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'CardDivider'}
        </h2>

        <small>
          <span>
            {'Stateless functional component'}
          </span>
          <br />
          <code>
            {`import { CardDivider } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Separates card content. Partially controlled by <Card> props.'}
        </p>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <div>
        <Card
          paddingSize="sm"
          bordered={false}
        >
          <p>
            {dummyText}
          </p>

          <CardDivider />

          <p>
            {dummyTextShort}
          </p>

          <CardDivider />

          <p>
            {dummyText}
          </p>

          <CardDivider />

          <pre>
            {'<Card \n paddingSize="sm" \n bordered={false}\n>\n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider />\n</Card>'}
          </pre>
        </Card>

        <Card
          paddingSize="md"
          bordered={false}
        >
          <p>
            {dummyText}
          </p>

          <CardDivider />

          <p>
            {dummyTextShort}
          </p>

          <CardDivider />

          <p>
            {dummyText}
          </p>

          <CardDivider />

          <pre>
            {'<Card \n paddingSize="md" \n bordered={false}\n>\n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider />\n</Card>'}
          </pre>
        </Card>

        <Card
          paddingSize="lg"
          bordered={false}
        >
          <p>
            {dummyText}
          </p>

          <CardDivider />

          <p>
            {dummyTextShort}
          </p>

          <CardDivider />

          <p>
            {dummyText}
          </p>

          <CardDivider />

          <pre>
            {'<Card \n paddingSize="lg" \n bordered={false}\n>\n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider /> \n <p>Lorem ipsum...</p> \n <CardDivider />\n</Card>'}
          </pre>
        </Card>
      </div>
    </div>
  );
};

export default CardDividerShowcase;
