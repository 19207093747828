import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'align',
  description: (
    <span>
      {'can be set to '}
      {renderBadge('left')}{' '}
      {renderBadge('center')}{' '}
      {renderBadge('right')}
    </span>
  ),
  type: 'string',
  default: 'left'
}, {
  property: 'rounded',
  description: 'rounds buttons',
  type: 'boolean',
  default: 'false'
}, {
  property: 'stacked',
  description: 'stacks buttons',
  type: 'boolean',
  default: 'false'
}, {
  property: 'wrapped',
  description: 'adds bottom margin',
  type: 'boolean',
  default: 'true'
}];
