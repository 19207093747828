import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'id',
  description: 'the matching id from your icons sprite',
  type: 'string',
  default: null
}, {
  property: 'rotate',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'rotates the icon in degrees; can be set to '}
      {renderBadge('45')}{' '}
      {renderBadge('90')}{' '}
      {renderBadge('135')}{' '}
      {renderBadge('180')}{' '}
      {renderBadge('225')}{' '}
      {renderBadge('270')}{' '}
      {renderBadge('315')}{' '}
      {renderBadge('360')}
    </span>
  ),
  type: 'number | null',
  default: 'null'
}, {
  property: 'size',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('4')}{' '}
      {renderBadge('8')}{' '}
      {renderBadge('12')}{' '}
      {renderBadge('16')}{' '}
      {renderBadge('20')}{' '}
      {renderBadge('24')}{' '}
      {renderBadge('28')}{' '}
      {renderBadge('32')}{' '}
      {renderBadge('36')}{' '}
      {renderBadge('40')}{' '}
      {renderBadge('44')}{' '}
      {renderBadge('48')}{' '}
      {renderBadge('52')}{' '}
      {renderBadge('56')}{' '}
      {renderBadge('60')}{' '}
      {renderBadge('64')}{'; default value of '}
      {renderBadge('null')}{' equals '}
      {renderBadge('16')}
    </span>
  ),
  type: 'number | null',
  default: 'null'
}];
