import React from 'react';
import { Card, Grid, ButtonGroup, Button, Badge, Icon } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const PopularStories = () => {

  return (
    <Card
      className="popular-stories"
      hovered
      padded={false}
      bordered={false}
    >
      <div className="d-flex justify-content-between align-items-center p-2">
        <span className="h5 m-0">
          {'Popular Stories'}
        </span>

        <ButtonGroup stacked rounded>
          <Button
            size="sm"
            outlined
          >
            <Icon
              id="ion-chevron-left"
              size={16}
            />
          </Button>

          <Button
            size="sm"
            outlined
          >
            <Icon
              id="ion-chevron-right"
              size={16}
            />
          </Button>
        </ButtonGroup>
      </div>

      <Grid>
        <Row className="m-0">
          <Col
            widths={['md-12', 'lg-4']}
            className="p-0"
          >
            <div className="d-flex p-2 p-lg-3 flex-column align-items-center justify-content-between height-100">
              <Badge
                className="bg--info mb-1"
                size="md"
                squared={false}
              >
                {'Travel'}
              </Badge>

              <div className="h4 font-weight text-align--center mb-2">
                {'The Ups and Downs of Friendship with the Beautiful Bowing Deer of Nara, Japan'}
              </div>

              <small className="color--gray-3">
                {'Anthony Whale'}
              </small>
            </div>
          </Col>

          <Col
            widths={['md-12', 'lg-4']}
            className="p-0"
          >
            <div className="d-flex p-2 p-lg-3 flex-column align-items-center justify-content-between height-100">
              <Badge
                className="bg--warning mb-1"
                size="md"
                squared={false}
              >
                {'Life'}
              </Badge>

              <div className="h4 font-weight text-align--center mb-2">
                {'Locol in Watts: A glimpse at what could be South L.A.\'s culinary future'}
              </div>

              <small className="color--gray-3">
                {'Landon Thorndike'}
              </small>
            </div>
          </Col>

          <Col
            widths={['md-12', 'lg-4']}
            className="p-0"
          >
            <div className="d-flex p-2 p-lg-3 flex-column align-items-center justify-content-between height-100">
              <Badge
                className="bg--danger mb-1"
                size="md"
                squared={false}
              >
                {'Design'}
              </Badge>

              <div className="h4 font-weight text-align--center mb-2">
                {'How a designer learned to code and released an app in 4 months'}
              </div>

              <small className="color--gray-3">
                {'Dylan'}
              </small>
            </div>
          </Col>
        </Row>
      </Grid>
    </Card>
  );
};

export default PopularStories;
