import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Layout, Sidebar, SidebarItem, Button, Grid } from 'react-fidelity-ui';
import AppLayout from '../../components/AppLayout';
import BaseDemo from '../../components/Demos/Base';
import FormsDemo from '../../components/Demos/Forms';
import ArticlesDemo from '../../components/Demos/Articles';
import ECommerceDemo from '../../components/Demos/ECommerce';
import { AppContext } from '../../context';
import { setTitle } from '../../context/actions';
import assets from '../../config/assets';
import { isStr } from '../../utils';

const { Content } = Layout;
const { Row, Col } = Grid;
const { scrollTo } = window;

const Assets = ({ match, history }) => {
  const { params } = match;
  const hasPredefinedTab = (tab) => isStr(tab)
    && assets.some(({ name }) => name === tab);
  const initialActiveTab = hasPredefinedTab(params.tab)
    ? assets.find(({ name }) => name === params.tab)
    : assets[0];
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    setTitle(dispatch)('Assets');
    // ReactGA.pageview(window.location.pathname);
    scrollTo(0, 0);
  }, [dispatch]);

  return (
    <AppLayout className="view-assets">
      <Content className="width-100">
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-3']}>
              <Sidebar>
                {assets.map((demo) => {
                  const isActive = demo.name === activeTab.name;

                  return (
                    <SidebarItem
                      key={`demo-${demo.name}`}
                      active={isActive}
                      renderItem={(className) => {
                        const itemClassName = classNames({
                          'bg--transparent': true,
                          'color--secondary': isActive
                        });

                        return (
                          <Button
                            type="text"
                            squared
                            bordered={false}
                            className={`${className} ${itemClassName}`}
                            onClick={() => {
                              setActiveTab(demo);
                              scrollTo(0, 0);
                              history.push(`/assets/${demo.name}`);
                            }}
                          >
                            {demo.label}
                          </Button>
                        );
                      }}
                    />
                  );
                })}
              </Sidebar>
            </Col>

            <Col widths={['sm-12', 'md-9']}>
              {activeTab.name === 'base' && (
                <BaseDemo />
              )}

              {activeTab.name === 'forms' && (
                <FormsDemo />
              )}

              {activeTab.name === 'articles' && (
                <ArticlesDemo />
              )}

              {activeTab.name === 'ecommerce' && (
                <ECommerceDemo />
              )}
            </Col>
          </Row>
        </Grid>
      </Content>
    </AppLayout>
  );
};

Assets.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string
    })
  }).isRequired
};

export default Assets;
