import React from 'react';
import { Grid } from 'react-fidelity-ui';
import HeroA from './HeroA';
import HeroB from './HeroB';
import CardA from './CardA';
import CardB from './CardB';
import SingleProductWomen from './SingleProductWomen';

const { Row, Col } = Grid;

const ECommerceDemo = () => {
  return (
    <div>
      <h2>
        {'E-Commerce'}
      </h2>

      <HeroA />

      <Grid>
        <Row>
          <Col widths={['sm-12', 'md-6']}>
            <CardA />
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <CardB />
          </Col>
        </Row>
      </Grid>

      <HeroB />

      <SingleProductWomen />
    </div>
  );
};

export default ECommerceDemo;
