import React from 'react';
import { Grid } from 'react-fidelity-ui';
import CreateAccount from './CreateAccount';
import SignIn from './SignIn';
import Login from './Login';
import RestorePassword from './RestorePassword';
import CreateNewPassword from './CreateNewPassword';
import SignUp from './SignUp';
import NotificationCenter from './NotificationCenter';
import SignUpForFree from './SignUpForFree';
import DownloadProject from './DownloadProject';
import ContactUs from './ContactUs';
import SendFeedback from './SendFeedback';
import EmailNotifications from './EmailNotifications';
import Skills from './Skills';
import AccountSettings from './AccountSettings';

const { Row, Col } = Grid;

const FormsDemo = () => {
  return (
    <div className="mb-4">
      <Grid>
        <Row>
          <Col widths={['sm-12', 'md-6']}>
            <CreateAccount />
            <Login />
            <SignUp />
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <SignIn />
            <CreateNewPassword />
            <RestorePassword />
            <NotificationCenter />
          </Col>
        </Row>

        <SignUpForFree />
        <DownloadProject />

        <Row>
          <Col widths={['sm-12', 'md-8']}>
            <ContactUs />
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <SendFeedback />
          </Col>
        </Row>

        <EmailNotifications />

        <Row>
          <Col widths={['sm-12', 'md-8']}>
            <AccountSettings />
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <Skills />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default FormsDemo;
