import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'active',
  description: 'sets active state',
  type: 'boolean',
  default: 'false'
}, {
  property: 'bordered',
  description: 'adds border',
  type: 'boolean',
  default: 'true'
}, {
  property: 'dashed',
  description: (
    <span>
      {'displays dashed border, works with '}
      {renderBadge('outlined')}{' and '}
      {renderBadge('bordered')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'expanded',
  description: 'expands button to 100% of its parent width',
  type: 'boolean',
  default: 'false'
}, {
  property: 'fab',
  description: 'rounds button and sets square width/height',
  type: 'boolean',
  default: 'false'
}, {
  property: 'flat',
  description: 'displays button without box shadow',
  type: 'boolean',
  default: 'true'
}, {
  property: 'hovered',
  description: 'adds more prominent box shadow on hover',
  type: 'boolean',
  default: 'false'
}, {
  property: 'loading',
  description: 'adds loading state',
  type: 'boolean',
  default: 'false'
}, {
  property: 'outlined',
  description: 'makes the background transparent and applies border',
  type: 'boolean',
  default: 'false'
}, {
  property: 'rounded',
  description: 'applies large border radius',
  type: 'boolean',
  default: 'false'
}, {
  property: 'size',
  description: (
    <span>
      {'can be set to '}
      {renderBadge('sm')}{' '}
      {renderBadge('lg')}{' '}
      {'or null/omitted for '}
      {renderBadge('md')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'squared',
  description: 'sets border radius to 0',
  type: 'boolean',
  default: 'false'
}, {
  property: 'type',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('primary')}{' '}
      {renderBadge('secondary')}{' '}
      {renderBadge('success')}{' '}
      {renderBadge('info')}{' '}
      {renderBadge('danger')}{' '}
      {renderBadge('warning')}{' '}
      {renderBadge('link')}{' '}
      {renderBadge('text')}{' '}
      {renderBadge('icon')}{' '}
      {renderBadge('facebook')}{' '}
      {renderBadge('google')}{' '}
    </span>
  ),
  type: 'string',
  default: 'secondary'
}];
