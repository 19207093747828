import React, { useState, useEffect } from 'react';
import { Card, Table, Progress, Button } from 'react-fidelity-ui';
import progressApi from '../../../config/components/progressApi';
import apiColumns from '../../../config/apiColumns';

const randomFloat = (min, max) => (Math.random() * (max - min)) + min;
const randomInt = (min, max) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);

  return Math.floor(Math.random() * (maxInt - minInt)) + minInt;
};

const ProgressShowcase = () => {
  const [progressA, setProgressA] = useState(0);
  const [progressB, setProgressB] = useState(0);
  const [progressC, setProgressC] = useState(0);
  const [progressD, setProgressD] = useState(0);
  const [progressE, setProgressE] = useState(0);
  const [progressF, setProgressF] = useState(0);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressA < 100) {
        setProgressA(progressA + randomFloat(1, 20));
      }
    }, delay);
  }, [progressA]);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressB < 100) {
        setProgressB(progressB + randomFloat(10, 20));
      }
    }, delay);
  }, [progressB]);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressC < 100) {
        setProgressC(progressC + randomFloat(10, 20));
      }
    }, delay);
  }, [progressC]);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressD < 100) {
        setProgressD(progressD + randomFloat(10, 20));
      }
    }, delay);
  }, [progressD]);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressE < 100) {
        setProgressE(progressE + randomFloat(10, 20));
      }
    }, delay);
  }, [progressE]);

  // on mount
  useEffect(() => {
    const delay = randomInt(500, 1000);

    setTimeout(() => {
      if (progressF < 100) {
        setProgressF(progressF + randomFloat(10, 20));
      }
    }, delay);
  }, [progressF]);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Progress
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {'import { Progress } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          Standard horizontal progress bar.
        </p>
      </Card>

      <h2>Types</h2>

      <Card
        hovered
        bordered={false}
      >
        <div className="mb-2">
          <Progress
            value={16.66}
            type="primary"
          />

          <pre className="mt-1">
            {`<Progress
  value={16.66}
  type="primary"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={33.32}
            type="secondary"
          />

          <pre className="mt-1">
            {`<Progress
  value={33.32}
  type="secondary"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={49.98}
            type="info"
          />

          <pre className="mt-1">
            {`<Progress
  value={49.98}
  type="info"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={66.64}
            type="warning"
          />

          <pre className="mt-1">
            {`<Progress
  value={66.64}
  type="warning"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={83.3}
            type="success"
          />

          <pre className="mt-1">
            {`<Progress
  value={83.3}
  type="success"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={100}
            type="danger"
          />

          <pre className="mt-1">
            {`<Progress
  value={100}
  type="danger"
/>`}
          </pre>
        </div>
      </Card>

      <h2>Sizes</h2>

      <Card
        hovered
        bordered={false}
      >
        <div className="mb-2">
          <Progress
            value={20}
            size="xs"
          />

          <pre className="mt-1">
            {`<Progress
  value={20}
  size="xs"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={40}
            size="sm"
          />

          <pre className="mt-1">
            {`<Progress
  value={40}
  size="sm"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={60}
            size="md"
          />

          <pre className="mt-1">
            {`<Progress
  value={60}
  size="md"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={80}
            size="lg"
          />

          <pre className="mt-1">
            {`<Progress
  value={80}
  size="lg"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={100}
            size="xl"
          />

          <pre className="mt-1">
            {`<Progress
  value={100}
  size="xl"
/>`}
          </pre>
        </div>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <div className="mb-2">
          <Progress
            value={progressA}
            type="primary"
            size="xs"
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressA > 0 && progressA < 100}
            onClick={() => setProgressA(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  value={${progressA < 100 ? progressA.toFixed(2) : 100}}
  type="primary"
  size="xs"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={progressB}
            type="secondary"
            size="sm"
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressB > 0 && progressB < 100}
            onClick={() => setProgressB(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  value={${progressB < 100 ? progressB.toFixed(2) : 100}}
  type="secondary"
  size="sm"
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={progressC}
            type="success"
            size="md"
            rounded={false}
            labelAlignment="right"
            label={(
              <span className="color--success-dark font-size--sm font-weight--bold">
                {`${progressC < 100 ? progressC.toFixed(2) : 100}%`}
              </span>
            )}
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressC > 0 && progressC < 100}
            onClick={() => setProgressC(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  value={${progressC < 100 ? progressC.toFixed(2) : 100}}
  type="success"
  size="md"
  rounded={false}
  labelAlignment="right"
  label={(
    <span className="color--success-dark font-size--sm font-weight--bold">
      {\`${progressC < 100 ? progressC.toFixed(2) : 100}%\`}
    </span>
  )}
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            value={progressD}
            type="info"
            size="lg"
            label={(
              <span className="font-size--sm">
                {`${progressD < 100 ? progressD.toFixed(2) : 100}%`}
              </span>
            )}
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressD > 0 && progressD < 100}
            onClick={() => setProgressD(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  value={${progressD < 100 ? progressD.toFixed(2) : 100}}
  type="info"
  size="lg"
  label={(
    <span className="font-size--sm">
      {\`${progressD < 100 ? progressD.toFixed(2) : 100}%\`}
    </span>
  )}
/>`}
          </pre>
        </div>

        <div className="mb-2">
          <Progress
            type="warning"
            size="xl"
            labelAlignment="sticky"
            value={progressE}
            elevated
            hovered
            rounded={false}
            label={(
              <span className="color--primary font-size--sm font-weight--bold">
                {`${progressE < 100 ? progressE.toFixed(2) : 100}%`}
              </span>
            )}
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressE > 0 && progressE < 100}
            onClick={() => setProgressE(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  type="warning"
  size="xl"
  labelAlignment="sticky"
  value={${progressE < 100 ? progressE.toFixed(2) : 100}}
  elevated
  hovered
  rounded={false}
  label={(
    <span className="color--primary font-size--sm font-weight--bold">
      {\`${progressE < 100 ? progressE.toFixed(2) : 100}%\`}
    </span>
  )}
/>`}
          </pre>
        </div>

        <div>
          <Progress
            type="success"
            size="lg"
            labelAlignment="center"
            className="bg--success-light"
            value={progressF}
            rounded={false}
            label={progressF < 100 ? progressF.toFixed(2) : 100}
          />

          <Button
            size="sm"
            className="mt-1"
            disabled={progressF > 0 && progressF < 100}
            onClick={() => setProgressF(0)}
          >
            Toggle
          </Button>

          <pre className="mt-1">
            {`<Progress
  type="success"
  size="lg"
  labelAlignment="center"
  className="bg--success-light"
  value={${progressF < 100 ? progressF.toFixed(2) : 100}}
  label={${progressF < 100 ? progressF.toFixed(2) : 100}}
  rounded={false}
/>`}
          </pre>
        </div>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={progressApi}
          columns={apiColumns}
          itemId="property"
          className="mb-0"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ProgressShowcase;
