import React, { useState } from 'react';
import { Card, Button, Table, Dropdown, Grid } from 'react-fidelity-ui';
import classNames from 'classnames';
import dropdownItemApi from '../../../config/components/dropdownItemApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const DropdownItemShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const [activeItemB, setActiveItemB] = useState('Choice A');
  const [activeItemC, setActiveItemC] = useState('Options B');
  const [activeItemD, setActiveItemD] = useState('D');

  const listA = ['Item A', 'Item B'];
  const listB = ['Choice A', 'Choice B', 'Choice C'];
  const listC = ['Option A', 'Options B', 'Option C'];
  const listD = ['A', 'B', 'C', 'D'];

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Item</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Dropdown } from 'react-fidelity-ui';
            
const { Item } = Dropdown;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component is part of the
          {' '}
          <code>Dropdown</code>
          {' '}
          composite component.
          {' '}
          <code>Item</code>
          {' '}
          can be used as an option of a list.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const [activeItem, setActiveItem] = useState('');
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isAOn}
                onClickOutside={() => {
                  if (isAOn) {
                    setIsAOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsAOn(!isAOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  {listA.map((item, index) => (
                    <Dropdown.Item key={`list-a-${index}`}>
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    <Dropdown.Item>
      Item A
    </Dropdown.Item>
    
    <Dropdown.Item>
      Item B
    </Dropdown.Item>
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isBOn}
                onClickOutside={() => {
                  if (isBOn) {
                    setIsBOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsBOn(!isBOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  {listB.map((item, index) => (
                    <Dropdown.Item
                      key={`list-b-${index}`}
                      active={item === activeItemB}
                      onClick={() => setActiveItemB(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    ${listB.map((item, index) => (
      `${index > 0 ? `
    ` : ''}<Dropdown.Item${item === activeItemB ? `
      active` : ''}
      onClick={() => setActiveItem('${item}')}
    >
      ${item}
    </Dropdown.Item>${index - 1 < listB.length ? '    ' : ''}`
    )).join('')}
  </Dropdown.Body> 
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isCOn}
                onClickOutside={() => {
                  if (isCOn) {
                    setIsCOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsCOn(!isCOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  {listC.map((item, index) => (
                    <Dropdown.Item
                      key={`list-c-${index}`}
                      className="p-0"
                      active={item === activeItemC}
                    >
                      <Button
                        type="text"
                        expanded
                        onClick={() => setActiveItemC(item)}
                      >
                        {item}
                      </Button>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
    
  <Dropdown.Body size="md">
    ${listC.map((item, index) => (
      `${index > 0 ? `
    ` : ''}<Dropdown.Item${item === activeItemC ? `
      active` : ''}
      className="p-0"
    >
      <Button
        type="text"
        expanded
        onClick={() => setActive('${item}')}
      >
        ${item}
      </Button>
    </Dropdown.Item>${index - 1 < listC.length ? '    ' : ''}`
    )).join('')}
  </Dropdown.Body> 
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isDOn}
                onClickOutside={() => {
                  if (isDOn) {
                    setIsDOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsDOn(!isDOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  {listD.map((item, index) => {
                    const itemClassName = classNames({
                      'p-0': true,
                      'bg--info': item === activeItemD
                    });

                    return (
                      <Dropdown.Item
                        key={`list-d-${index}`}
                        className={itemClassName}
                        active={item === activeItemD}
                      >
                        <Button
                          type="link"
                          expanded
                          onClick={() => setActiveItemD(item)}
                        >
                          {item}
                        </Button>
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    ${listD.map((item, index) => (
      `${index > 0 ? `
    ` : ''}<Dropdown.Item${item === activeItemD ? `
      active` : ''}
      className="p-0${item === activeItemD ? ' bg--info' : ''}"
    >
      <Button
        type="link"
        expanded
        onClick={() => setActive('${item}')}
      >
        ${item}
      </Button>
    </Dropdown.Item>${index - 1 < listD.length ? '    ' : ''}`
    )).join('')}
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={dropdownItemApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default DropdownItemShowcase;
