import React, { useState } from 'react';
import { InputGroup, Card, Checkbox, Radio, Grid } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const EmailNotifications = () => {
  const [radioVal, setRadioVal] = useState('on');
  const [checkA, setCheckA] = useState(true);
  const [checkB, setCheckB] = useState(true);
  const [checkC, setCheckC] = useState(false);
  const [checkD, setCheckD] = useState(false);
  const [checkE, setCheckE] = useState(true);
  const [checkF, setCheckF] = useState(true);

  return (
    <Card
      paddingSize="lg"
      hovered
      bordered={false}
    >
      <h2 className="text-align--center">
        {'E-mail Notifications'}
      </h2>

      <form>
        <div className="h4">
          {'Notification summary'}
        </div>
        <p>
          {'Receive an email summary of notifications instead of individual emails'}
        </p>

        <InputGroup>
          <Radio
            label="On"
            value="on"
            checked={radioVal === 'on'}
            onChange={({ target }) => {
              setRadioVal(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Radio
            label="Off"
            value="off"
            checked={radioVal === 'off'}
            onChange={({ target }) => {
              setRadioVal(target.value);
            }}
          />
        </InputGroup>

        <div className="h4 mt-4">
          {'Email me immediately when someone'}
        </div>

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <InputGroup>
                <Checkbox
                  checked={checkA}
                  label="Invites me to co-own a project"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckA(target.checked);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Checkbox
                  checked={checkB}
                  label="Sends me a direct message"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckB(target.checked);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Checkbox
                  checked={checkC}
                  label="Publishes a project"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckC(target.checked);
                  }}
                />
              </InputGroup>
            </Col>
            <Col widths={['sm-12', 'md-6']}>
              <InputGroup>
                <Checkbox
                  checked={checkD}
                  label="Requests to join my friends"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckD(target.checked);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Checkbox
                  checked={checkE}
                  label="Requests that I join their team"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckE(target.checked);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Checkbox
                  checked={checkF}
                  label="Comment my project"
                  isSwitch
                  onChange={({ target }) => {
                    setCheckF(target.checked);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Grid>
      </form>
    </Card>
  );
};

export default EmailNotifications;
