import React, { useEffect } from 'react';
import { Card, Icon } from 'react-fidelity-ui';

const { scrollTo } = window;

const Principles = () => {
  useEffect(() => {
    scrollTo(0, 0);
  });

  return (
    <div>
      <h1>Principles</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-aperture"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI</strong>
            {' '}
            utilizes several design principles, patterns and methodologies to produce versatile UI. Some of those include
            {' '}
            <a href="https://fettblog.eu/typescript-react/prop-types/" target="_blank" rel="noopener noreferrer">
              enhanced typing
            </a>
            {', '}
            <a href="https://en.wikipedia.org/wiki/Dependency_inversion_principle" target="_blank" rel="noopener noreferrer">
              dependency inversion
            </a>
            {', '}
            <a href="http://getbem.com/" target="_blank" rel="noopener noreferrer">
              BEM
            </a>
            {', '}
            <a href="https://a11yproject.com/" target="_blank" rel="noopener noreferrer">
              accessibility (a11y)
            </a>
            .
          </p>
        </div>
      </Card>

      <h2>Base Components</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>Fidelity UI exposes a set of base components that combined, can be used to build a wide range of UIs.</strong>
          <br />
          Rather than providing advanced UI components like calendars, color pickets or kanban boards, this library exposes an opinionated set of basic UI components that can be combined to fulfill many UI requirements.
        </p>
      </Card>

      <h2>Just JavaScript and CSS</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            Fidelity UI puts into use latest native JS and CSS features, via
            {' '}
            <a href="https://www.npmjs.com/package/react" target="_blank" rel="noopener noreferrer">
              React
            </a>
            {' '}
            and
            {' '}
            <a href="https://www.npmjs.com/package/postcss" target="_blank" rel="noopener noreferrer">
              PostCSS
            </a>
            , to achieve its functionality.
          </strong>
          <br />
          Those two derivative technologies are all you need to know to start using this library.
        </p>
      </Card>

      <h2>Enhanced Typing</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            Fidelity UI uses both compile and runtime type checking via
            {' '}
            <a href="https://www.npmjs.com/package/typescript" target="_blank" rel="noopener noreferrer">
              TypeScript
            </a>
            {' '}
            and
            {' '}
            <a href="https://www.npmjs.com/package/prop-types" target="_blank" rel="noopener noreferrer">
              PropTypes
            </a>
            {' '}
            combined.
          </strong>
          <br />
          This powerful combo provides a full, end-to-end type checking experience for TypeScript enabled projects.
        </p>
      </Card>

      <h2>Open/Closed Principle</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>Fidelity UI components are composite and atomic - each component represents a single dom node.</strong>
          <br />
          This one-to-one mapping makes components highly customizable and allow them to be open for extension but closed for modification.
        </p>
      </Card>

      <h2>Dependency Inversion</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>Fidelity UI components are decoupled and cohesive.</strong>
          <br />
          {'The library\'s components are unaware of each other, yet they can work well together when combined.'}
        </p>
      </Card>

      <h2>BEM</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            This UI library uses
            {' '}
            <a href="http://getbem.com/" target="_blank" rel="noopener noreferrer">
              BEM
            </a>
            {' '}
            to achieve expressive, extensible and maintainable styles.
          </strong>
          <br />
          BEM maps nicely to the react paradigm, allowing block and elements to be represented by components and modifiers - by props.
        </p>
      </Card>

      <h2>Accessibility</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            All Fidelity UI components are built with
            {' '}
            <a href="https://a11yproject.com/" target="_blank" rel="noopener noreferrer">
              accessibility (a11y)
            </a>
            {' '}
            in mind.
          </strong>
          <br />
          Accessibility support increases discoverability and allow assistive technology to interpret your app for impaired users.
        </p>
      </Card>
    </div>
  );
};

export default Principles;
