import React, { useState, Fragment } from 'react';
import { Card, Button, Tabs, Tab, Empty, Image, Layout, Icon, Badge } from 'react-fidelity-ui';
import forest from '../../../images/forest.png';
import flowers from '../../../images/flowers.png';
import lake from '../../../images/lake.png';

const { Content, Container } = Layout;

const Categories = () => {
  const tabs = [{
    id: 1,
    name: 'latest',
    label: 'Latest',
    articles: [{
      id: 11,
      title: 'UI design - 10 steps for great user interfaces Pt6',
      excerpt: 'As promised in part 5, in this part we are going to dive into the world of colors.',
      duration: '4 min read',
      image: forest
    }, {
      id: 111,
      title: 'Exploring Dynamic Layout in Sketch',
      excerpt: 'If you design interfaces, you probably often work with repeating elements, like rows in a table or a grid of items.',
      duration: '12 min read',
      image: flowers
    }, {
      id: 1111,
      title: 'Building your own custom React hook',
      excerpt: 'With the rise of react hooks, we now have easy ways to modularize reusable component logic.',
      duration: '6 min read',
      image: null
    }]
  }, {
    id: 2,
    name: 'popular',
    label: 'Popular',
    articles: [{
      id: 22,
      title: 'Exploring Dynamic Layout in Sketch',
      excerpt: 'If you design interfaces, you probably often work with repeating elements, like rows in a table or a grid of items.',
      duration: '12 min read',
      image: flowers
    }, {
      id: 222,
      title: 'Building your own custom React hook',
      excerpt: 'With the rise of react hooks, we now have easy ways to modularize reusable component logic.',
      duration: '6 min read',
      image: lake
    }]
  }, {
    id: 3,
    name: 'featured',
    label: 'Featured',
    articles: []
  }];
  const [firstTab] = tabs;
  const [activeTab, setActiveTab] = useState(firstTab);
  const hasArticles = activeTab.articles.length > 0;

  return (
    <Card
      hovered
      bordered={false}
      padded={false}
    >
      <Tabs bordered>
        {tabs.map(tab => (
          <Tab
            key={`category-${tab.id}`}
            active={tab.name === activeTab.name}
          >
            <Button
              type="icon"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                setActiveTab(tab);
              }}
            >
              {tab.label}
            </Button>
          </Tab>
        ))}
      </Tabs>

      <Container>
        <Content>
          {!hasArticles ? (
            <Empty
              text={<h4>{'No articles published, yet.'}</h4>}
              footer={<Button size="sm" rounded>Post an article</Button>}
            />
          ) : (
            <Fragment>
              {activeTab.articles.map(article => (
                <Fragment key={`category-article-${article.id}`}>
                  <div className="d-flex align-items-start mb-2">
                    <div
                      style={{ width: 320, maxWidth: 200 }}
                      className="mr-2"
                    >
                      <Image
                        src={article.image || ''}
                        alt={article.title}
                        width="xl"
                        aspectRatio="16-9"
                        expanded
                        rounded={false}
                        spinner={<Icon id="ion-load-c" spinning />}
                        onError={() => (
                          <Badge
                            size="lg"
                            className="bg--gray-2"
                          >
                            {article.title[0]}
                          </Badge>
                        )}
                      />
                    </div>

                    <div>
                      <span className="h4">
                        {article.title}
                      </span>
                      <div className="mr-2">
                        <small>
                          {article.duration}
                        </small>
                      </div>

                      <p className="mt-1">
                        {article.excerpt}
                      </p>
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          )}
        </Content>
      </Container>
    </Card>
  );
};

export default Categories;
