import React from 'react';
import { Card, Icon } from 'react-fidelity-ui';

const SidebarShowcase = () => (
  <div className="mt-2">
    <Card
      hovered
      bordered={false}
    >
      <h2>
        Sidebar
      </h2>

      <small>
        <span>
          Stateless functional component
        </span>
        <br />
        <code>
          {'import { Sidebar } from \'react-fidelity-ui\';'}
        </code>
      </small>
    </Card>

    <Card
      hovered
      bordered={false}
    >
      <div className="d-flex align-items-center">
        <Icon
          id="ion-alert"
          size={48}
          className="color--secondary"
        />
        <p className="font-size--lg mb-0 ml-2">
          Documentation for this component is being written at the moment. Please come back later :)
        </p>
      </div>
    </Card>
  </div>
);

export default SidebarShowcase;
