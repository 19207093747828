import React from 'react';
import { Card, ButtonGroup, Button, Grid, Icon } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const Buttons = () => {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
  const paginationA = Array.from({ length: 4 });
  const paginationB = Array.from({ length: 8 });
  const paginationIndex = 3;

  return (
    <div className="buttons-asset">
      <Card
        hovered
        bordered={false}
      >
        <Button className="mr-4 mb-4">
          {'Squared'}
        </Button>
        <Button
          type="primary"
          className="mr-4 mb-4"
          fab
          flat={false}
          hovered
        >
          <Icon id="ion-plus" />
        </Button>
        <Button
          type="primary"
          size="sm"
          className="mr-4 mb-4"
          outlined
        >
          {'Small'}
        </Button>
        <Button
          size="lg"
          className="mr-4 mb-4"
        >
          {'Large'}
        </Button>
        <Button
          size="sm"
          className="mr-4 mb-4"
          rounded
          outlined
        >
          {'Rounded'}
        </Button>
        <Button
          className="mr-4 mb-4"
          rounded
        >
          {'Rounded'}
        </Button>
        <Button
          size="sm"
          className="mr-4 mb-4"
          loading
        >
          {'Loading'}
        </Button>
        <Button
          className="mr-4 mb-4"
          loading
          outlined
        >
          {'Loading'}
        </Button>
        <Button
          className="mr-4 mb-4"
          disabled
        >
          {'Disabled'}
        </Button>
        <Button
          type="primary"
          className="mr-4 mb-4"
          active
        >
          {'Active Primary'}
        </Button>
        <Button
          type="info"
          className="mr-4 mb-4"
          expanded
        >
          {'Block'}
        </Button>
        <ButtonGroup>
          <ButtonGroup
            stacked
            rounded
            className="mr-4 mb-4"
          >
            <Button outlined>
              {'Left'}
            </Button>
            <Button outlined>
              {'Center'}
            </Button>
            <Button
              title="Right"
              outlined
            >
              {'Right'}
            </Button>
          </ButtonGroup>
          <ButtonGroup className="mr-4 mb-4">
            <Button>
              {'Left'}
            </Button>
            <Button>
              {'Right'}
            </Button>
          </ButtonGroup>
          <ButtonGroup
            stacked
            className="mr-4 mb-4"
          >
            <Button size="sm">
              {'A'}
            </Button>
            <Button size="sm">
              {'B'}
            </Button>
            <Button type="primary">
              {'C'}
            </Button>
            <Button type="warning">
              {'D'}
            </Button>
          </ButtonGroup>
          <ButtonGroup className="mr-4 mb-4">
            <Button
              type="link"
              size="lg"
            >
              {'Link'}
            </Button>
            <Button type="text">
              {'Text'}
            </Button>
          </ButtonGroup>
          <ButtonGroup className="m-0">
            <Button
              type="icon"
              size="lg"
            >
              <Icon id="ion-android-wifi" />
            </Button>
            <Button
              type="icon"
              size="lg"
            >
              <Icon id="ion-android-time" />
            </Button>
            <Button
              type="icon"
              size="lg"
            >
              <Icon id="ion-aperture" />
            </Button>
            <Button
              type="icon"
              size="lg"
            >
              <Icon id="ion-android-walk" />
            </Button>
            <Button
              type="icon"
              size="lg"
            >
              <Icon id="ion-cloud" />
            </Button>
            <Button
              type="secondary"
              size="sm"
              disabled
            >
              {'Disabled Small'}
            </Button>
            <Button
              type="success"
              size="lg"
              loading
            >
              {'Loading Large'}
            </Button>
          </ButtonGroup>
        </ButtonGroup>
        <Grid>
          <Row className="mt-2 ml-0 mr-0">
            <Col widths={['sm-12', 'md-6']}>
              <Button
                type="warning"
                className="mr-4 mb-4"
                size="lg"
                expanded
              >
                {'Warning Block'}
              </Button>
            </Col>
            <Col widths={['sm-12', 'md-6']}>
              <Button
                type="danger"
                className="mr-4 mb-1"
                size="sm"
                expanded
              >
                {'Danger Block'}
              </Button>
              <Button
                type="info"
                className="mr-4 mb-4"
                size="sm"
                expanded
              >
                {'Info Block'}
              </Button>
            </Col>
          </Row>
        </Grid>
        <ButtonGroup
          className="mr-4 mb-4"
          stacked
          rounded
        >
          <Button
            type="primary"
            size="sm"
            outlined
          >
            {'Back'}
          </Button>
          {letters.map((letter, index) => (
            <Button
              key={`letter-${letter}`}
              size={index === paginationIndex ? 'lg' : null}
              type="primary"
              active={index === paginationIndex}
            >
              {letter}
            </Button>
          ))}
          <Button
            type="primary"
            size="sm"
            outlined
          >
            {'Forth'}
          </Button>
        </ButtonGroup>
        <Grid>
          <Row className="align-items-center">
            <Col className="flex-inline mb-2">
              <ButtonGroup
                className="mr-2"
                stacked
                rounded
              >
                <Button
                  type="primary"
                  size="lg"
                  outlined
                >
                  <Icon id="ion-chevron-left" />
                </Button>
                <Button
                  type="primary"
                  size="lg"
                  outlined
                >
                  <Icon id="ion-chevron-right" />
                </Button>
              </ButtonGroup>
            </Col>
            <Col className="flex-inline mb-2">
              <ButtonGroup
                className="mr-2"
                stacked
                rounded
              >
                <Button
                  type="text"
                  size="sm"
                  outlined
                >
                  <Icon
                    id="ion-chevron-left"
                    size={16}
                  />
                </Button>
                {paginationA.map((item, index) => (
                  <Button
                    key={`pagination-${index}`}
                    type="text"
                    size="sm"
                    outlined
                    active={index === paginationIndex}
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  type="text"
                  size="sm"
                  outlined
                >
                  <Icon
                    id="ion-chevron-right"
                    size={16}
                  />
                </Button>
              </ButtonGroup>
            </Col>
            <Col className="flex-inline mb-2">
              <ButtonGroup
                stacked
                rounded
              >
                <Button
                  type="text"
                  size="sm"
                  className="mr-2"
                  outlined
                >
                  <Icon
                    id="ion-chevron-left"
                    size={16}
                  />
                </Button>
                {paginationB.map((item, index) => (
                  <Button
                    key={`pagination-${index}`}
                    type="text"
                    size="sm"
                    outlined
                    active={index === paginationIndex}
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  type="text"
                  size="sm"
                  className="ml-2"
                  outlined
                >
                  <Icon
                    id="ion-chevron-right"
                    size={16}
                  />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
      </Card>
    </div>
  );
};

export default Buttons;
