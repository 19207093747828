import {
  RESET,
  SET_TITLE,
  SET_DEFAULT_SECONDARY_COLOR
} from './types';

export const reset = (dispatch) => () => {
  dispatch({
    type: RESET
  });
};

export const setTitle = (dispatch) => (title) => {
  dispatch({
    type: SET_TITLE,
    title
  });
};

export const setDefaultSecondaryColor = (dispatch) => (color, colorLight, colorDark) => {
  dispatch({
    type: SET_DEFAULT_SECONDARY_COLOR,
    color,
    colorLight,
    colorDark
  });
};
