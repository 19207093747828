import React, { useEffect } from 'react';
import { Card, Icon } from 'react-fidelity-ui';

const { scrollTo } = window;

const Technologies = () => {
  useEffect(() => {
    scrollTo(0, 0);
  });

  return (
    <div>
      <h1>Technologies</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-bonfire"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI</strong>
            {' '}
            uses several key technologies to achieve performant, predictable and customizable UI.
            <br />
            These include
            {' '}
            <a href="https://www.npmjs.com/package/react" target="_blank" rel="noopener noreferrer">
              React
            </a>
            {', '}
            <a href="https://www.npmjs.com/package/postcss" target="_blank" rel="noopener noreferrer">
              PostCSS
            </a>
            {', '}
            <a href="https://www.npmjs.com/package/typescript" target="_blank" rel="noopener noreferrer">
              TypeScript
            </a>
            {', '}
            <a href="https://www.npmjs.com/package/prop-types" target="_blank" rel="noopener noreferrer">
              PropTypes
            </a>
            .
          </p>
        </div>
      </Card>

      <h2>React</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            <a href="https://www.npmjs.com/package/react" target="_blank" rel="noopener noreferrer">
              React
            </a>
            {' '}
            is the core component library behind Fidelity UI.
          </strong>
          <br />
          Underneath, all Fidelity UI components are stateless or stateful functions. Their internal state is controlled by
          {' '}
          <a href="https://reactjs.org/docs/hooks-intro.html" target="_blank" rel="noopener noreferrer">
            React hooks
          </a>
          {'. '}
          React was selected as the component library for this project due to its state based functional paradigm, wide adoption and strong community support.
        </p>
      </Card>

      <h2>PostCSS</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            Fidelity UI utilizes latest native CSS features via
            {' '}
            <a href="https://www.npmjs.com/package/postcss" target="_blank" rel="noopener noreferrer">
              PostCSS
            </a>
            {' '}
            to achieve expressive styles for its components.
          </strong>
          <br />
          One of those features -
          {' '}
          <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties" target="_blank" rel="noopener noreferrer">
            custom css properties
          </a>
          {' '}
          - unlocks powerful live theming capabilities.
        </p>
      </Card>

      <h2>TypeScript</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            <a href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">
              TypeScript
            </a>
            {' '}
            was selected for this project for its static, compile-time typing capabilities.
          </strong>
          <br />
          {'With TypeScript, components\' resilience and stability is increased. Fidelity UI exposes all of its types and interfaces making them readily available for external use in your TypeScript app.'}
        </p>
      </Card>

      <h2>PropTypes</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>
            <a href="https://www.npmjs.com/package/prop-types" target="_blank" rel="noopener noreferrer">
              PropTypes
            </a>
            {' '}
            was chosen because it offers dynamic, run-time typing validations.
          </strong>
          <br />
          With PropTypes enabled, you will get runtime errors when passing invalid props to Fidelity UI components.
        </p>
      </Card>
    </div>
  );
};

export default Technologies;
