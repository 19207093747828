import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import { Card, Dropdown, Button, Icon, Badge, Search, Tag, Fillable } from 'react-fidelity-ui';

const skills = ['React', 'Vue', 'Angular', 'CSS', 'Graphic Design', 'HTML', 'Javascript', 'UI Design'];
const people = ['John Doe', 'Dan Marsh', 'Jane Doe'];
const fileActions = ['New', 'Open', 'Save'];
const tags = ['UI Kit', 'Landing', 'Light', 'Photoshop'];
const postFilters = [{
  name: 'important',
  label: 'Important',
  color: 'primary'
}, {
  name: 'announcement',
  label: 'Announcement',
  color: 'secondary'
}, {
  name: 'discussion',
  label: 'Discussion',
  color: 'warning'
}];

const Dropdowns = () => {
  const [isDropdownAOn, setIsDropdownAOn] = useState(false);
  const [isDropdownBOn, setIsDropdownBOn] = useState(false);
  const [isDropdownCOn, setIsDropdownCOn] = useState(false);
  const [isDropdownDOn, setIsDropdownDOn] = useState(false);
  const [isDropdownEOn, setIsDropdownEOn] = useState(false);
  const [isDropdownFOn, setIsDropdownFOn] = useState(false);
  const [isDropdownGOn, setIsDropdownGOn] = useState(false);
  const [isDropdownHOn, setIsDropdownHOn] = useState(false);
  const [isDropdownIOn, setIsDropdownIOn] = useState(false);
  const [isDropdownLOn, setIsDropdownLOn] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [postFilterSearch, setPostFilterSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const postFiltersByDate = postFilters.slice(1);

  return (
    <div>
      <Card
        hovered
        bordered={false}
      >
        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownAOn}
          onClickOutside={() => {
            if (isDropdownAOn) {
              setIsDropdownAOn(false);
            }
          }}
        >
          <Button onClick={() => setIsDropdownAOn(!isDropdownAOn)}>
            Select Skills
            <Icon
              id="ion-android-arrow-dropdown"
              className="ml-2"
              rotate={isDropdownAOn ? 180 : null}
            />
          </Button>

          <Dropdown.Body
            size="auto"
            style={{
              top: 'calc(100% + 20px)',
              left: 'auto',
              right: 0
            }}
          >
            {skills.map((skill, index) => (
              <Fragment key={`skill-${skill}`}>
                <Dropdown.Item active={selectedSkills.includes(skill)}>
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();

                      const nextSelectedSkills = selectedSkills.includes(skill)
                        ? selectedSkills.filter(s => s !== skill)
                        : [...selectedSkills, skill];

                      setSelectedSkills(nextSelectedSkills);
                    }}
                  >
                    {index < skills.length - 1 ? (
                      skill
                    ) : (
                      <strong>{skill}</strong>
                    )}
                  </a>
                </Dropdown.Item>

                {index > 0 && index % 3 === 0 && (
                  <Dropdown.Divider />
                )}
              </Fragment>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownBOn}
          onClickOutside={() => {
            if (isDropdownBOn) {
              setIsDropdownBOn(false);
            }
          }}
        >
          <Button
            size="sm"
            type="primary"
            rounded
            onClick={() => setIsDropdownBOn(!isDropdownBOn)}
          >
            Select Skills
            <Icon
              id="ion-android-arrow-dropdown"
              className="ml-2"
            />
          </Button>

          <Dropdown.Body
            style={{
              top: 'calc(100% + 10px)',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            <Dropdown.Text>
              <h4 className="mb-0">Select your skills</h4>
            </Dropdown.Text>

            <Dropdown.Divider />

            {skills.map(skill => (
              <Dropdown.Item
                key={`skill-${skill}`}
                active={selectedSkills.includes(skill)}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    const nextSelectedSkills = selectedSkills.includes(skill)
                      ? selectedSkills.filter(s => s !== skill)
                      : [...selectedSkills, skill];

                    setSelectedSkills(nextSelectedSkills);
                  }}
                >
                  {skill}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownCOn}
          onClickOutside={() => {
            if (isDropdownCOn) {
              setIsDropdownCOn(false);
            }
          }}
        >
          <Button
            type="warning"
            onClick={() => setIsDropdownCOn(!isDropdownCOn)}
          >
            Filter posts
          </Button>

          <Dropdown.Body
            style={{
              top: 'calc(100% + 20px)',
              left: 0,
              right: 'auto'
            }}
          >
            <Dropdown.Text>
              <Search
                value={postFilterSearch}
                placeholder="Search filters"
                icon={<Icon id="ion-android-search" />}
                flat={false}
                onChange={({ target }) => {
                  setPostFilterSearch(target.value);
                }}
              />
            </Dropdown.Text>

            <Dropdown.Divider />

            <Dropdown.Text>Filter by Tag</Dropdown.Text>

            {postFilters.map(filter => {
              const badgeClassName = classNames({
                'mr-2': true,
                [`bg--${filter.color}`]: true
              });

              return (
                <Dropdown.Item
                  key={`filter-${filter.name}`}
                  active={selectedSkills.includes(filter.name)}
                >
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                    }}
                  >
                    <Badge
                      size="xs"
                      className={badgeClassName}
                      squared
                    />
                    {filter.label}
                  </a>
                </Dropdown.Item>
              );
            })}

            <Dropdown.Divider />

            <Dropdown.Text>Filter by Date</Dropdown.Text>

            {postFiltersByDate.map(filter => {
              const badgeClassName = classNames({
                'mr-2': true,
                [`bg--${filter.color}`]: true
              });

              return (
                <Dropdown.Item
                  key={`filter-${filter.name}`}
                  active={selectedSkills.includes(filter.name)}
                >
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                    }}
                  >
                    <Badge
                      size="xs"
                      className={badgeClassName}
                      squared
                    />
                    {filter.label}
                  </a>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownDOn}
          onClickOutside={() => {
            if (isDropdownDOn) {
              setIsDropdownDOn(false);
            }
          }}
        >
          <Search
            value={selectedSkills.join(', ')}
            placeholder="Filter skills"
            icon={<Icon id="ion-android-search" />}
            onMouseEnter={() => {
              setIsDropdownDOn(true);
            }}
            onChange={({ target }) => {
              setPostFilterSearch(target.value);
            }}
          />

          <Dropdown.Body
            style={{
              top: 'calc(100% + 20px)',
              left: 'auto',
              right: 0
            }}
          >
            {skills.map(skill => (
              <Dropdown.Item
                key={`skill-${skill}`}
                active={selectedSkills.includes(skill)}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();

                    const nextSelectedSkills = selectedSkills.includes(skill)
                      ? selectedSkills.filter(s => s !== skill)
                      : [...selectedSkills, skill];

                    setSelectedSkills(nextSelectedSkills);
                    setIsDropdownDOn(!isDropdownDOn);
                  }}
                >
                  {skill}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownEOn}
          onClickOutside={() => {
            if (isDropdownEOn) {
              setIsDropdownEOn(false);
            }
          }}
        >
          <Button
            onClick={() => {
              setIsDropdownEOn(!isDropdownEOn);
            }}
          >
            Select Skills
            <Icon
              id="ion-chevron-down"
              size={16}
              className="ml-2"
              rotate={isDropdownEOn ? 180 : null}
            />
          </Button>

          <Dropdown.Body>
            {skills.map(skill => (
              <Dropdown.Item
                key={`skill-${skill}`}
                active={selectedSkills.includes(skill)}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    const nextSelectedSkills = selectedSkills.includes(skill)
                      ? selectedSkills.filter(s => s !== skill)
                      : [...selectedSkills, skill];

                    setSelectedSkills(nextSelectedSkills);
                  }}
                >
                  {skill}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownFOn}
          onClickOutside={() => {
            if (isDropdownFOn) {
              setIsDropdownFOn(false);
            }
          }}
        >
          <Button
            type="icon"
            onClick={() => {
              setIsDropdownFOn(!isDropdownFOn);
            }}
          >
            <Icon
              id="ion-android-person-add"
              className="ml-2"
            />
          </Button>

          <Dropdown.Body style={{ top: 'calc(100% + 10px)' }}>
            {people.map(person => (
              <Dropdown.Item
                key={`person-${person}`}
                active={selectedPerson === person}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setSelectedPerson(person);
                  }}
                >
                  {person}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownGOn}
          onClickOutside={() => {
            if (isDropdownGOn) {
              setIsDropdownGOn(false);
            }
          }}
        >
          <Button
            type="link"
            size="sm"
            onClick={() => {
              setIsDropdownGOn(!isDropdownGOn);
            }}
          >
            Select Skills
          </Button>

          <Dropdown.Body>
            {skills.map(skill => (
              <Dropdown.Item
                key={`skill-${skill}`}
                active={selectedSkills.includes(skill)}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();

                    const nextSelectedSkills = selectedSkills.includes(skill)
                      ? selectedSkills.filter(s => s !== skill)
                      : [...selectedSkills, skill];

                    setSelectedSkills(nextSelectedSkills);
                  }}
                >
                  {skill}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownHOn}
          onClickOutside={() => {
            if (isDropdownHOn) {
              setIsDropdownHOn(false);
            }
          }}
        >
          <Button
            type="text"
            size="lg"
            onClick={() => {
              setIsDropdownHOn(!isDropdownHOn);
            }}
          >
            Select Skills
            <Icon
              id="ion-android-arrow-dropdown"
              className="ml-2"
              rotate={isDropdownHOn ? 180 : null}
            />
          </Button>

          <Dropdown.Body style={{ top: 'calc(100% + 8px)' }}>
            {skills.map(skill => (
              <Dropdown.Item
                key={`skill-${skill}`}
                active={selectedSkills.includes(skill)}
              >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();

                    const nextSelectedSkills = selectedSkills.includes(skill)
                      ? selectedSkills.filter(s => s !== skill)
                      : [...selectedSkills, skill];

                    setSelectedSkills(nextSelectedSkills);
                    setIsDropdownHOn(!isDropdownHOn);
                  }}
                >
                  {skill}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <Dropdown
          className="mr-2 mb-2"
          active={isDropdownIOn}
          onClickOutside={() => {
            if (isDropdownIOn) {
              setIsDropdownIOn(false);
            }
          }}
        >
          <Button
            type="info"
            onClick={() => setIsDropdownIOn(!isDropdownIOn)}
          >
            File
            <Icon
              id="ion-android-attach"
              className="ml-2"
            />
          </Button>

          <Dropdown.Body style={{ top: 'calc(100% + 8px)' }}>
            {fileActions.map(action => (
              <Dropdown.Item key={`action-${action}`}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                  }}
                >
                  {action}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Body>
        </Dropdown>

        <div>
          <Dropdown
            className="mr-2 mb-2"
            active={isDropdownLOn}
            onClickOutside={() => {
              if (isDropdownLOn) {
                setIsDropdownLOn(false);
              }
            }}
          >
            <Fillable
              placeholder="Select tags..."
              className="fillableClassName"
              onFocus={() => {
                setIsDropdownLOn(!isDropdownLOn);
              }}
            >
              {selectedTags.length > 0 && (
                <>
                  {selectedTags.map(tag => (
                    <Tag
                      key={`selected-tag-${tag}`}
                      label={tag}
                      removeIcon={<Icon id="ion-android-close" size={16} />}
                      onRemove={() => {
                        const nextSelectedTags = selectedTags.filter(t => t !== tag);
                        setSelectedTags(nextSelectedTags);
                      }}
                    />
                  ))}
                </>
              )}
            </Fillable>

            <Dropdown.Body size="auto">
              {tags.map(tag => (
                <Dropdown.Item
                  key={`tag-${tag}`}
                  active={selectedTags.includes(tag)}
                >
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault();

                      const nextSelectedTags = selectedTags.includes(tag)
                        ? selectedTags.filter(t => t !== tag)
                        : [...selectedTags, tag];

                      setSelectedTags(nextSelectedTags);
                    }}
                  >
                    {tag}
                  </a>
                </Dropdown.Item>
              ))}
            </Dropdown.Body>
          </Dropdown>
        </div>
      </Card>
    </div>
  );
};

export default Dropdowns;
