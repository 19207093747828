import React, { useEffect } from 'react';
import { Card, CardDivider, Icon, Notification } from 'react-fidelity-ui';

const { scrollTo } = window;

const Installation = () => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Installation</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-ionic"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI can be installed in several ways depending on your project needs.</strong>
            <br />
            The easiest way to install this library is by using starter kits like
            {' '}
            <a href="https://create-react-app.dev/" target="_blank" rel="noopener noreferrer">
              create-react-app
            </a>
            {' and '}
            <a href="https://www.reactboilerplate.com/" target="_blank" rel="noopener noreferrer">
              react-boilerplate
            </a>
            . More advanced installation options are available when loading the library in
            {' '}
            <strong>custom projects</strong>
            {' '}
            or when you need
            {' '}
            <strong>custom media queries</strong>
            .
          </p>
        </div>
      </Card>

      <h2>Starter Kits</h2>

      <Card
        hovered
        bordered={false}
      >
        <p>
          <strong>1.</strong>
          {' '}
          To install Fidelity UI in your
          {' '}
          <a href="https://create-react-app.dev/" target="_blank" rel="noopener noreferrer">
            create-react-app
          </a>
          {' or '}
          <a href="https://www.reactboilerplate.com/" target="_blank" rel="noopener noreferrer">
            react-boilerplate
          </a>
          {' '}
          project, run:
        </p>

        <pre>
          npm install react-fidelity-ui
        </pre>

        <CardDivider />

        <p>
          <strong>2.</strong>
          {' '}
          {'In your app\'s wrapper component, import Fidelity UI styles:'}
        </p>

        <pre>
          {'import \'react-fidelity-ui/dist/index.css\';'}
        </pre>

        <CardDivider />

        <div className="d-flex align-items-center">
          <Icon
            id="ion-android-done"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            {'That\'s it! You can now start using Fidelity UI components in your app.'}
            <br />
            {'You can customize the library\'s css variables.'}
          </p>
        </div>
      </Card>

      <h2>Custom Projects</h2>
      <Notification
        hovered
        bordered={false}
      >
        <p className="h4 mb-1">This guide is aimed at projects configured with webpack.</p>
        <p className="mb-0">
          Similar approach can be used for projects, configured with other module bundlers like
          {' '}
          <a href="https://www.npmjs.com/package/rollbar" target="_blank" rel="noopener noreferrer">
            rollbar
          </a>
          .
        </p>
      </Notification>

      <Card
        hovered
        bordered={false}
      >
        <p>
          This guide was written using
          {' '}
          <code>{'\'webpack\': \'^4.41.5\''}</code>
          {' and '}
          <code>{'\'webpack-cli\': \'^3.3.10\''}</code>
          .
        </p>
        <p>
          <strong>1.</strong>
          {' '}
          To install Fidelity UI in your custom webpack project, run:
        </p>

        <pre>
          npm install react-fidelity-ui
        </pre>

        <CardDivider />

        <p>
          <strong>2.</strong>
          {' '}
          Install Fidelity UI dev dependencies:
        </p>

        <pre className="mb-2">
          {`"postcss": "^7.0.14",
"postcss-custom-media": "^7.0.8",
"postcss-easy-import": "^3.0.0",
"postcss-import": "^12.0.1",
"postcss-loader": "^3.0.0",
"postcss-nested": "^4.2.1",
"postcss-preset-env": "^6.7.0",`}
        </pre>

        <p>
          You will probably need the following dev dependencies as well:
        </p>

        <pre className="mb-1">
          {`"extract-text-webpack-plugin": "^4.0.0-beta.0",
"file-loader": "^5.0.2",
"url-loader": "^3.0.0"`}
        </pre>

        <small>
          <a href="https://www.npmjs.com/package/extract-text-webpack-plugin" target="_blank" rel="noopener noreferrer">
            <code>extract-text-webpack-plugin</code>
          </a>
          {' '}
          is used for utilizing multiple loaders.
          <br />
          <a href="https://www.npmjs.com/package/file-loader" target="_blank" rel="noopener noreferrer">
            <code>file-loader</code>
          </a>
          {' and '}
          <a href="https://www.npmjs.com/package/url-loader" target="_blank" rel="noopener noreferrer">
            <code>url-loader</code>
          </a>
          {' '}
          are required for importing svg icon sprites.
        </small>

        <CardDivider />

        <p>
          <strong>3.</strong>
          {' '}
          Add to your
          {' '}
          <a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer">
            webpack
          </a>
          {' '}
          config, under
          <code>module.rules</code>
          the following rules:
        </p>

        <pre className="mb-1">
          {`const ExtractTextPlugin = require('extract-text-webpack-plugin');
          
module.exports = {
  ...
  module: {
    ...
    rules: [
      ...
      {
        test: /\\.css$/,
        loader: ExtractTextPlugin.extract(['css-loader', 'postcss-loader'])
      },
      {
        test: /\\.svg(\\?v=\\d+\\.\\d+\\.\\d+)?$/,
        use: 'url-loader?limit=10000&mimetype=image/svg+xml'
      }
    ]
  } 
}`}
        </pre>

        <small>
          In case you are already using a css loader just add
          {' '}
          <a href="https://www.npmjs.com/package/postcss-loader" rel="noopener noreferrer" target="_blank">
            <code>postcss-loader</code>
          </a>
          {' '}
          to your webpack config.
        </small>
        <br />

        <small>
          The
          {' '}
          <a href="https://www.npmjs.com/package/url-loader" rel="noopener noreferrer" target="_blank">
            <code>url-loader</code>
          </a>
          {' '}
          is optional and it is used for loading svg sprites.
        </small>

        <CardDivider />

        <p>
          <strong>4.</strong>
          {' '}
          Add
          {' '}
          <code>postcss.config.js</code>
          for
          {' '}
          <code>postcss-loader</code>
          {' '}
          to your project root:
        </p>

        <pre>
          {`module.exports = {
  plugins: {
    'postcss-easy-import': {
      prefix: '_'
    },
    'postcss-import': {},
    'postcss-custom-media': {},
    'postcss-preset-env': {
      browsers: ['last 2 versions', '> 5%'],
      features: {
        customProperties: {
          preserve: true
        }
      }
    },
    'postcss-nested': {}
  },
};`}
        </pre>

        <small>
          This basic postcss config can be further customized according to your project requirements.
        </small>

        <CardDivider />

        <p>
          <strong>5.</strong>
          {' '}
          Create a stylesheet (example name:
          {' '}
          <code>app.css</code>
          {' '}
          ) in your project and add to it:
        </p>

        <pre className="mb-2">
          {`@import '_breakpoints.css';
@import 'react-fidelity-ui/src/css/main.css';
@import '_config.css';`}
        </pre>

        <p>
          First import your custom media queries, then import Fidelity UI
          {' '}
          <code>main.css</code>
          {' '}
          file. After that you can import everything else like custom css variables.
        </p>

        <small>
          There are more modular ways to import Fidelity UI styles in your project. You can import partials like
          {' '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/blob/master/src/css/_config.css" rel="noopener noreferrer" target="_blank">
            <code>_config.css</code>
          </a>
          {', '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/blob/master/src/css/_base.css" rel="noopener noreferrer" target="_blank">
            <code>_base.css</code>
          </a>
          {', '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/blob/master/src/css/_utils.css" rel="noopener noreferrer" target="_blank">
            <code>_utils.css</code>
          </a>
          {', '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/blob/master/src/css/_components.css" rel="noopener noreferrer" target="_blank">
            <code>_components.css</code>
          </a>
          {' and use only specific parts of the library\'s styles.'}
        </small>

        <CardDivider />

        <p>
          <strong>6.</strong>
          {' '}
          {'In your app\'s wrapper component, import your stylesheet'}
          <code>app.css</code>
          :
        </p>

        <pre>
          {'import \'./app.css\';'}
        </pre>

        <CardDivider />

        <div className="d-flex align-items-center">
          <Icon
            id="ion-android-done"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            {'That\'s it! You can now start using Fidelity UI components in your app.'}
            <br />
            {'You can customize the library\'s css variables and media queries.'}
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Installation;
