import React, { useState } from 'react';
import { Grid, InputGroup, Card, CardDivider, Email, Password, Icon, Button, Divider, ButtonGroup } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Sign In'}
      </h2>

      <form>
        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            rounded
            hovered
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <Grid>
          <Row className="align-items-end mr-0">
            <Col
              widths={['sm-12', 'md-6']}
              className="pr-0"
            >
              <InputGroup>
                <Password
                  value={password}
                  label="Password"
                  placeholder="Enter your password..."
                  icon={<Icon id="ion-android-lock" />}
                  rounded
                  hovered
                  flat={false}
                  onChange={({ target }) => {
                    setPassword(target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="pr-0"
            >
              <Button
                expanded
                rounded
                className="mb-2"
                onClick={onClick}
              >
                {'Sign In'}
              </Button>
            </Col>
          </Row>
        </Grid>

        <p className="mt-3 text-align--center">
          <a href="#">
            <strong>
              {'Forgot Password?'}
            </strong>
          </a>
        </p>

        <Divider>{'OR'}</Divider>

        <ButtonGroup wrapped={false}>
          <Button
            type="facebook"
            rounded
            className="flex-1"
            onClick={onClick}
          >
            {'Facebook'}
          </Button>
          <Button
            type="google"
            rounded
            className="flex-1"
            onClick={onClick}
          >
            {'Google'}
          </Button>
        </ButtonGroup>
      </form>

      <CardDivider />

      <Grid>
        <Row className="mt-2">
          <Col
            widths={['sm-12', 'md-6']}
            className="ml-auto mr-auto text-align--center"
          >
            <p>
              <a href="#">
                <strong>
                  {'Don\'t have an account?'}
                </strong>
              </a>
            </p>

            <Button
              type="info"
              rounded
              expanded
              onClick={onClick}
            >
              {'Sign up for free'}
            </Button>
          </Col>
        </Row>
      </Grid>
    </Card>
  )
};

export default SignIn;
