import React from 'react';
import { Card, Button, Grid, Transition } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const HeroA = () => {
  return (
    <div className="bg-image bicycle-bg mb-3">
      <Card
        paddingSize="lg"
        className="bg--transparent color--white"
        hovered
        bordered={false}
      >
        <Transition type="fade-in-right">
          <Grid>
            <Row>
              <Col widths={['md-12', 'lg-6']}>
                <h1 className="color--white">
                  {'Last Call for Exciting Bikes Under $100'}
                </h1>

                <p className="mb-6">
                  {'At first, for some time, I was not able to answer him one word, but as he had taken me in his aarms I held fast by him, or I should have fallen to the ground.'}
                </p>

                <Button
                  size="lg"
                  rounded
                >
                  {'Shop Now'}
                </Button>
              </Col>
            </Row>
          </Grid>
        </Transition>
      </Card>
    </div>
  );
};

export default HeroA;
