import React from 'react';
import { Card, ButtonGroup, Button, Grid, Badge } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const HeroPost = () => {
  return (
    <Card
      hovered
      padded={false}
      bordered={false}
    >
      <Grid>
        <Row>
          <Col
            widths={['md-12', 'lg-6']}
            className="pr-lg-0"
          >
            <div className="bg-image forest-bg" />
          </Col>

          <Col
            widths={['md-12', 'lg-6']}
            className="p-0"
          >
            <div className="card--padding-lg">
              <span className="h2">
                {'The 37 Best Websites To Learn Something New'}
              </span>

              <div className="d-flex align-items-center mt-2">
                <Badge
                  className="color--secondary mr-1"
                  size="md"
                  outlined
                  fab={false}
                  squared={false}
                >
                  {'Lifestyle'}
                </Badge>

                <small>
                  <strong>
                    {'129 Votes'}
                  </strong>
                </small>
              </div>

              <p className="mt-4">
                {'Forget overpriced schools, long days in crowded classroom, and pitifully poor results. These websites and apps cover myriads of science, art, and technology topics.'}
              </p>

              <p>
                {'They will teach you practically anything, from making hummus to building apps in node.js, most of them for free.'}
              </p>

              <ButtonGroup
                align="left"
                className="mt-4"
              >
                <Button
                  type="info"
                  rounded
                  flat={false}
                >
                  {'Read Now'}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </Grid>
    </Card>
  );
};

export default HeroPost;
