import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'type',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('fade')}{' '}
      {renderBadge('fade-in-down')}{' '}
      {renderBadge('fade-in-left')}{' '}
      {renderBadge('fade-in-right')}{' '}
      {renderBadge('fade-in-up')}{' '}
      {renderBadge('fade-out')}{' '}
      {renderBadge('slide-in-down')}{' '}
      {renderBadge('slide-in-left')}{' '}
      {renderBadge('slide-in-right')}{' '}
      {renderBadge('slide-in-up')}{' '}
      {renderBadge('spin')}
    </span>
  ),
  type: 'string',
  default: 'fade'
}];
