export default [
  {
    name: 'base',
    label: 'Base'
  },
  {
    name: 'forms',
    label: 'Forms'
  },
  {
    name: 'articles',
    label: 'Articles'
  },
  {
    name: 'ecommerce',
    label: 'eCommerce'
  }
];
