import React from 'react';
import { Card, Icon } from 'react-fidelity-ui';

const BrowserSupport = () => (
  <div>
    <h1>Browser Support</h1>

    <Card
      hovered
      bordered={false}
    >
      <div className="d-flex">
        <Icon
          id="ion-cube"
          size={48}
          className="color--secondary"
        />
        <p className="font-size--lg mb-0 ml-2">
          <strong>Fidelity UI is modern UI library and as such it aims to use modern browser features.</strong>
          <br />
          This means that you will have a great time with it on latest versions of
          {' '}
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
            Chrome
          </a>
          {', '}
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            Firefox
          </a>
          {', '}
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">
            Edge
          </a>
          {' and '}
          <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">
            Safari
          </a>
          {' '}
          browsers.
          {' '}
          <a href="https://www.microsoft.com/en-us/download/details.aspx?id=41628" target="_blank" rel="noopener noreferrer">
            IE 11
          </a>
          {' '}
          is not a supported browser.
        </p>
      </div>
    </Card>
  </div>
);

export default BrowserSupport;
