import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'active',
  description: (
    <span>
      {'displays the collapse content when set to '}
      {renderBadge('true')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}];
