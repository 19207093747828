import React, { useState } from 'react';
import { InputGroup, Email, Icon, Button, Card, Grid } from 'react-fidelity-ui';
import { validateEmail } from '../../../utils/validators';

const { Row, Col } = Grid;

const DownloadProject = () => {
  const [email, setEmail] = useState('');
  const isEmailFilled = email !== '';
  const isValidEmail = validateEmail(email);
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      paddingSize="lg"
      bordered={false}
    >
      <h2 className="text-align--center">
        {'Download Project'}
      </h2>

      <form>
        <Grid>
          <Row>
            <Col
              widths={['sm-12', 'md-6']}
              className="ml-auto mr-auto"
            >
              <InputGroup isRow>
                <Email
                  value={email}
                  placeholder="E-mail"
                  icon={<Icon id="ion-email" />}
                  rounded
                  hovered={!isEmailFilled || !isValidEmail}
                  validated={isEmailFilled}
                  valid={!isEmailFilled || isValidEmail}
                  onChange={({ target }) => {
                    setEmail(target.value);
                  }}
                />

                <Button
                  type="success"
                  rounded
                  disabled={!isValidEmail}
                  onClick={onClick}
                >
                  {'Download'}
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Grid>
      </form>
    </Card>
  )
};

export default DownloadProject;
