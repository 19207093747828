import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Button, Layout, Sidebar, SidebarItem, Grid } from 'react-fidelity-ui';
import AppLayout from '../../components/AppLayout';
import Installation from '../../components/Docs/Installation';
import BrowserSupport from '../../components/Docs/BrowserSupport';
import Customizations from '../../components/Docs/Customizations';
import LiveTheming from '../../components/Docs/LiveTheming';
import Icons from '../../components/Docs/Icons';
import { AppContext } from '../../context';
import { setTitle } from '../../context/actions';
import docs from '../../config/docs';
import { isStr } from '../../utils';

const { Content } = Layout;
const { Row, Col } = Grid;

const Docs = ({ match, history }) => {
  const { params } = match;
  const hasPredefinedTab = (tab) => isStr(tab)
    && docs.some(({ name }) => name === tab);
  const initialActiveTab = hasPredefinedTab(params.tab)
    ? docs.find(({ name }) => name === params.tab)
    : docs[0];
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    setTitle(dispatch)('Docs');
    // ReactGA.pageview(window.location.pathname);
  }, [dispatch]);

  return (
    <AppLayout>
      <Content className="width-100">
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-3']}>
              <Sidebar>
                {docs.map((demo) => {
                  const isActive = demo.name === activeTab.name;

                  return (
                    <SidebarItem
                      key={`demo-${demo.name}`}
                      active={isActive}
                      renderItem={(className) => {
                        const itemClassName = classNames({
                          'bg--transparent': true,
                          'color--secondary': isActive
                        });

                        return (
                          <Button
                            type="text"
                            squared
                            bordered={false}
                            className={`${className} ${itemClassName}`}
                            onClick={() => {
                              setActiveTab(demo);
                              history.push(`/docs/${demo.name}`);
                            }}
                          >
                            {demo.label}
                          </Button>
                        );
                      }}
                    />
                  );
                })}
              </Sidebar>
            </Col>

            <Col widths={['sm-12', 'md-9']}>
              {activeTab.name === 'installation' && (
                <Installation />
              )}

              {activeTab.name === 'browser-support' && (
                <BrowserSupport />
              )}

              {activeTab.name === 'customizations' && (
                <Customizations />
              )}

              {activeTab.name === 'live-theming' && (
                <LiveTheming />
              )}

              {activeTab.name === 'icons' && (
                <Icons />
              )}
            </Col>
          </Row>
        </Grid>
      </Content>
    </AppLayout>
  );
};

Docs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string
    })
  }).isRequired
};

export default Docs;
