import React, { useState, useEffect } from 'react';
import { InputGroup, Input, Email, Icon, Button, Divider, Select, Card, File, Tel, Image, Grid, Badge } from 'react-fidelity-ui';
import { validateEmail } from '../../../utils/validators';
import { isObj, loadImageFromFile } from '../../../utils';
import countries from '../../../config/countries';

const { Row, Col } = Grid;

const AccountSettings = () => {
  const [avatar, setAvatar] = useState('');
  const [avatarFile, setAvatarFile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('United Kingdom');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');
  const [isFirstNameTouched, setIsFirstNameTouched] = useState(false);
  const [isLastNameTouched, setIsLastNameTouched] = useState(false);
  const [isCityTouched, setIsCityTouched] = useState(false);
  const [isZipCodeTouched, setIsZipCodeTouched] = useState(false);

  const isFirstNameFilled = firstName !== '';
  const isLastNameFilled = lastName !== '';
  const isEmailFilled = email !== '';
  const isValidEmail = validateEmail(email);
  const isCityFilled = city !== '';
  const isZipCodeFilled = zipCode !== '';

  const firstNameTitle = isFirstNameTouched && !isFirstNameFilled
    ? 'First name is required.'
    : null;
  const lastNameTitle = isLastNameTouched && !isLastNameFilled
    ? 'Last name is required.'
    : null;
  const cityTitle = isCityTouched && !isCityFilled
    ? 'City is required.'
    : null;
  const zipCodeTitle = isZipCodeTouched && !isZipCodeFilled
    ? 'Zip code is required.'
    : null;

  const isDisabled = !isFirstNameFilled
    || !isLastNameFilled
    || !isEmailFilled
    || !isValidEmail
    || !isCityFilled
    || !isZipCodeFilled;

  const countryOptions = countries.map(country => ({
    value: country,
    label: country
  }));

  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (isObj(avatarFile)) {
      loadImageFromFile(avatarFile, nextAvatar => {
        setAvatar(nextAvatar);
      });
    }
  }, [avatarFile]);

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Account Settings'}
      </h2>

      <form>
        <Grid>
          <Row className="align-items-center">
            <Col widths={['sm-12', 'md-4']}>
              <Image
                src={avatar}
                alt="avatar"
                size="lg"
                onError={() => (
                  <Badge
                    size="lg"
                    className="color--gray-1 bg--gray-2"
                  >
                    N/A
                  </Badge>
                )}
              />
            </Col>

            <Col widths={['sm-12', 'md-8']}>
              <InputGroup>
                <File
                  files={avatarFile}
                  accept="image/png, image/jpeg"
                  label="Avatar"
                  multiple={false}
                  rounded
                  onChange={({ target }) => {
                    const [firstFile] = target.files;
                    setAvatarFile(firstFile);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Grid>

        <Divider />

        <InputGroup isRow>
          <InputGroup>
            <Input
              value={firstName}
              label="First name"
              placeholder="Enter your first name..."
              title={firstNameTitle}
              rounded
              validated={isFirstNameTouched}
              valid={isFirstNameFilled}
              onChange={({ target }) => {
                setIsFirstNameTouched(true);
                setFirstName(target.value);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Input
              value={lastName}
              label="Last name"
              placeholder="Enter your last name..."
              title={lastNameTitle}
              validated={isLastNameTouched}
              rounded
              valid={isLastNameFilled}
              onChange={({ target }) => {
                setIsLastNameTouched(true);
                setLastName(target.value);
              }}
            />
          </InputGroup>
        </InputGroup>

        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            rounded
            validated={isEmailFilled}
            valid={!isEmailFilled || isValidEmail}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup isRow>
          <InputGroup>
            <Select
              value={country}
              options={countryOptions}
              label="Country"
              placeholder="Select country"
              rounded
              validated
              valid
              onChange={({ target }) => {
                setCountry(target.value)
              }}
            />
          </InputGroup>

          <InputGroup>
            <Input
              value={city}
              label="City"
              placeholder="Enter your city..."
              title={cityTitle}
              rounded
              validated={isCityTouched}
              valid={isCityFilled}
              onChange={({ target }) => {
                setIsCityTouched(true);
                setCity(target.value);
              }}
            />
          </InputGroup>
        </InputGroup>

        <InputGroup isRow>
          <InputGroup>
            <Input
              value={zipCode}
              label="Zip Code"
              placeholder="Enter your zip code..."
              title={zipCodeTitle}
              rounded
              validated={isZipCodeTouched}
              valid={isZipCodeTouched}
              onChange={({ target }) => {
                setIsZipCodeTouched(true);
                setZipCode(target.value);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Tel
              value={phone}
              label="Phone"
              placeholder="Enter your phone number..."
              rounded
              onChange={({ target }) => {
                setPhone(target.value);
              }}
            />
          </InputGroup>
        </InputGroup>

        <Button
          type="primary"
          size="lg"
          className="mt-4"
          expanded
          rounded
          disabled={isDisabled}
          onClick={onClick}
        >
          {'Save'}
        </Button>
      </form>
    </Card>
  )
};

export default AccountSettings;
