export default [
  {
    name: 'app',
    label: 'App',
    components: []
  },
  {
    name: 'badge',
    label: 'Badge',
    components: []
  },
  {
    name: 'button',
    label: 'Button',
    components: [
      {
        name: 'button',
        label: 'Button',
      },
      {
        name: 'button_group',
        label: 'Group',
      }
    ]
  },
  {
    name: 'card',
    label: 'Card',
    hideTabs: true,
    components: [
      {
        name: 'card',
        label: 'Card',
      },
      {
        name: 'card_divider',
        label: 'Divider'
      }
    ]
  },
  {
    name: 'collapse',
    label: 'Collapse',
    components: []
  },
  {
    name: 'divider',
    label: 'Divider',
    components: []
  },
  {
    name: 'empty',
    label: 'Empty',
    components: []
  },
  {
    name: 'dropdown',
    label: 'Dropdown',
    components: [
      {
        name: 'dropdown',
        label: 'Dropdown'
      },
      {
        name: 'dropdown_body',
        label: 'Body'
      },
      {
        name: 'dropdown_text',
        label: 'Text'
      },
      {
        name: 'dropdown_item',
        label: 'Item'
      },
      {
        name: 'dropdown_divider',
        label: 'Divider'
      }
    ]
  },
  {
    name: 'fillable',
    label: 'Fillable',
    components: []
  },
  {
    name: 'grid',
    label: 'Grid',
    components: [
      {
        name: 'grid',
        label: 'Grid'
      },
      {
        name: 'grid_row',
        label: 'Row'
      },
      {
        name: 'grid_col',
        label: 'Col'
      }
    ]
  },
  {
    name: 'icon',
    label: 'Icon',
    components: []
  },
  {
    name: 'image',
    label: 'Image',
    components: []
  },
  {
    name: 'input',
    label: 'Input',
    hideTabs: true,
    components: [
      {
        name: 'checkbox',
        label: 'Checkbox'
      },
      {
        name: 'email',
        label: 'Email'
      },
      {
        name: 'file',
        label: 'File'
      },
      {
        name: 'input',
        label: 'Input'
      },
      {
        name: 'input_group',
        label: 'InputGroup'
      },
      {
        name: 'number',
        label: 'Number'
      },
      {
        name: 'password',
        label: 'Password'
      },
      {
        name: 'radio',
        label: 'Radio'
      },
      {
        name: 'search',
        label: 'Search'
      },
      {
        name: 'select',
        label: 'Select'
      },
      {
        name: 'tel',
        label: 'Tel'
      },
      {
        name: 'textarea',
        label: 'Textarea'
      }
    ]
  },
  {
    name: 'layout',
    label: 'Layout',
    hideTabs: true,
    components: [
      {
        name: 'layout',
        label: 'Layout'
      },
      {
        name: 'footer',
        label: 'Footer',
        components: []
      },
      {
        name: 'header',
        label: 'Header'
      },
      {
        name: 'container',
        label: 'Container'
      },
      {
        name: 'content',
        label: 'Content'
      },
    ]
  },
  {
    name: 'modal',
    label: 'Modal',
    components: [
      {
        name: 'modal',
        label: 'Modal'
      },
      {
        name: 'modal_backdrop',
        label: 'Backdrop'
      },
      {
        name: 'modal_content',
        label: 'Content'
      },
      {
        name: 'modal_header',
        label: 'Header'
      },
      {
        name: 'modal_body',
        label: 'Body'
      },
      {
        name: 'modal_footer',
        label: 'Footer'
      }
    ]
  },
  {
    name: 'nav',
    label: 'Nav',
    hideTabs: true,
    components: [
      {
        name: 'nav',
        label: 'Nav'
      },
      {
        name: 'nav_menu',
        label: 'Menu'
      },
      {
        name: 'nav_menu_item',
        label: 'MenuItem'
      },
      {
        name: 'nav_trigger',
        label: 'Trigger'
      }
    ]
  },
  {
    name: 'notification',
    label: 'Notification',
    components: []
  },
  {
    name: 'progress',
    label: 'Progress',
    components: []
  },
  {
    name: 'sidebar',
    label: 'Sidebar',
    hideTabs: true,
    components: [
      {
        name: 'sidebar',
        label: 'Sidebar'
      },
      {
        name: 'sidebar_item',
        label: 'Item'
      }
    ]
  },
  {
    name: 'tab',
    label: 'Tabs',
    hideTabs: true,
    components: [
      {
        name: 'tab',
        label: 'Tabs'
      }
    ]
  },
  {
    name: 'table',
    label: 'Table',
    components: []
  },
  {
    name: 'transition',
    label: 'Transition',
    components: []
  }
];
