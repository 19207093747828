import React, { useState } from 'react';
import { Grid, InputGroup, Input, Email, Password, Icon, Button, Divider, ButtonGroup, Card } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const CreateAccount = () => {
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Create Account'}
      </h2>

      <form>
        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            rounded
            hovered
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup isRow>
          <InputGroup>
            <Input
              value={nickname}
              label="Nickname"
              placeholder="Enter your nickname..."
              icon={<Icon id="ion-person" />}
              rounded
              hovered
              onChange={({ target }) => {
                setNickname(target.value);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Password
              value={password}
              label="Password"
              placeholder="Enter your password..."
              icon={<Icon id="ion-android-lock" />}
              rounded
              hovered
              flat={false}
              onChange={({ target }) => {
                setPassword(target.value);
              }}
            />
          </InputGroup>
        </InputGroup>

        <Grid>
          <Row className="mt-4 mb-2">
            <Col className="ml-auto mr-auto text-align--center">
              <p>
                {'By creating an account you agree to the'}
                <br />
                <a href="#">{'Terms of Service'}</a>{' and '}
                <a href="#">{'Privacy Policy'}</a>{'.'}
              </p>
            </Col>
          </Row>
        </Grid>

        <Button
          expanded
          rounded
          onClick={onClick}
        >
          {'Sign Up'}
        </Button>

        <Divider>{'OR'}</Divider>

        <ButtonGroup wrapped={false}>
          <Button
            type="facebook"
            rounded
            className="flex-1"
            onClick={onClick}
          >
            {'Facebook'}
          </Button>
          <Button
            type="google"
            rounded
            className="flex-1"
            onClick={onClick}
          >
            {'Google'}
          </Button>
        </ButtonGroup>
      </form>
    </Card>
  );
};

export default CreateAccount;
