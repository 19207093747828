import React from 'react';
import Colors from './Colors';
import Typography from './Typography';
import Buttons from './Buttons';
import Inputs from './Inputs';
import Dropdowns from './Dropdowns';
import Modals from './Modals';
import Notifications from './Notifications';

const BaseDemo = () => {
  return (
    <>
      <h2>Colors</h2>
      <Colors />

      <h2>Typography</h2>
      <Typography />

      <h2>Buttons</h2>
      <Buttons />

      <h2>Inputs</h2>
      <Inputs />

      <h2>Dropdowns</h2>
      <Dropdowns />

      <h2>Modals</h2>
      <Modals />

      <h2>Notifications</h2>
      <Notifications />
    </>
  );
};

export default BaseDemo;
