import React, { useEffect } from 'react';
import { Card, CardDivider, Icon, Button, ButtonGroup } from 'react-fidelity-ui';

const { scrollTo } = window;

const Customizations = () => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Customizations</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-levels"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI components can be customized in several ways using javascript and css.</strong>
            <br />
            You can customize them with
            {' '}
            <strong>component props</strong>
            {' '}
            or by using
            {' '}
            <strong>css helper classes</strong>
            {'. More powerful customization options like overwriting the library\'s'}
            {' '}
            <strong>css variables</strong>
            {' and '}
            <strong>media queries</strong>
            {' '}
            are also available.
          </p>
        </div>
      </Card>

      <h2>Component Props</h2>
      <Card
        hovered
        bordered={false}
      >
        <p>
          <strong>The easiest way to customize a Fidelity UI component is via its props.</strong>
          <br />
          Many components expose style-related props like
          {' '}
          <code>type</code>
          {', '}
          <code>size</code>
          {', '}
          <code>flat</code>
          . Assigning different values to those props will result in different visuals.
        </p>

        <CardDivider />

        <p>The following two buttons are visually different because their prop values differ:</p>

        <ButtonGroup>
          <Button
            type="primary"
            size="sm"
            outlined
            dashed
          >
            Submit
          </Button>
          <Button
            type="text"
            size="lg"
            flat={false}
          >
            Submit
          </Button>
        </ButtonGroup>

        <pre>
          {`import { Button, ButtonGroup } from 'react-fidelity-ui';
...

<ButtonGroup>
  <Button
    type="primary"
    size="sm"
    outlined
    dashed
  >
    Submit
  </Button>
  
  <Button
    type="text"
    size="lg"
    flat={false}
  >
    Submit
  </Button>
</ButtonGroup>
`}
        </pre>
      </Card>

      <h2>CSS Helper Classes</h2>
      <Card
        hovered
        bordered={false}
      >
        <p>
          <strong>Fidelity UI components can also be customized using helper classes.</strong>
          <br />
          This library exposes many css helper classes that aim to reduce the need to write custom css in your app. Examples of such helper classes are
          {' '}
          <code>color--warning</code>
          {', '}
          <code>bg--warning</code>
          .
        </p>

        <CardDivider />

        <p>The same two buttons from above can be further customized with helper classes:</p>

        <ButtonGroup>
          <Button
            type="primary"
            size="sm"
            outlined
            dashed
            className="color--warning"
          >
            Submit
          </Button>
          <Button
            type="text"
            size="lg"
            flat={false}
            className="bg--warning"
          >
            Submit
          </Button>
        </ButtonGroup>

        <pre className="mb-1">
          {`import { Button } from 'react-fidelity-ui';
...

<ButtonGroup>
  <Button
    type="primary"
    size="sm"
    outlined
    dashed
    className="color--warning"
  >
    Submit
  </Button>
  
  <Button
    type="text"
    size="lg"
    flat={false}
    className="bg--warning"
  >
    Submit
  </Button>
</ButtonGroup>
`}
        </pre>

        <small>
          There are many css helper classes available in Fidelity UI. Those can be found in the
          {' '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/tree/master/src/css/utils" rel="noopener noreferrer" target="_blank">
            <code>css utils space</code>
          </a>
          .
        </small>
      </Card>

      <h2>CSS Variables</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>{'More advanced customizations can be achieved by overwriting the library\'s css variables.'}</strong>
          <br />
          To satisfy specific UI requirements, you can overwrite any css variable exposed by Fidelity UI. Examples of such css variables are
          {' '}
          <code>--color-warning</code>
          {' '}
          (global variable)
          {' and '}
          <code>--btn-color-bg</code>
          {' '}
          (component/local variable).
        </p>

        <CardDivider />

        <p>
          <strong>1.</strong>
          {' '}
          To overwrite Fidelity UI css variables with your own values, you need to create a css file (example name
          {' '}
          <code>_config.css</code>
          ) and then add the modified variables to it:
        </p>

        <pre className="mb-1">
          {`:root {
  --color-warning: tomato;
}

.fidelity-ui-app .btn {
  --btn-color-bg: var(--color-warning);
}
`}
        </pre>

        <p>
          Global css variables are defined in the
          {' '}
          <code>:root</code>
          {' '}
          html element. Component (local) variables are defined in their respective component selector like
          {' '}
          <code>.btn</code>
          . All component selectors must be additionally wrapped by the
          {' '}
          <code>.fidelity-ui-app</code>
          {' '}
          selector. This is because Fidelity UI styles are scoped to prevent pollution of the global css namespace.
        </p>

        <small>
          There are about
          {' '}
          <a href="https://github.com/nevendyulgerov/react-fidelity-ui/blob/master/src/css/_config.css" rel="noopener noreferrer" target="_blank">
            150 global css variables
          </a>
          {' '}
          and about 300 component (local) variables available in Fidelity UI.
        </small>
        <br />

        <small>
          To reference a css variable, like
          {' '}
          <code>--color-warning</code>
          {' '}
          in your stylesheets, use
          {' '}
          <code>var(--color-warning)</code>
          .
        </small>

        <CardDivider />

        <p>
          <strong>2.</strong>
          {' '}
          {'In your app\'s wrapper component, import'}
          {' '}
          <code>_config.css</code>
          {' '}
          after the core library styles:
        </p>

        <pre className="mb-1">
          {`import 'react-fidelity-ui/dist/index.css';
import './_config.css`}
        </pre>

        <small>
          <code>_config.css</code>
          {' '}
          is just an example name so you may want to replace it with a different name if it makes sense for your app.
        </small>
      </Card>

      <h2>Custom Media Queries</h2>
      <Card
        hovered
        bordered={false}
      >
        <p className="mb-0">
          <strong>Oftentimes you may need to customize the media queries exposed by Fidelity UI.</strong>
          <br />
          This is available for postcss-compatible projects with
          {' '}
          <code>postcss-loader</code>
          {' '}
          or similar loader enabled.
        </p>

        <CardDivider />

        <p>
          <strong>Fidelity UI</strong>
          {' '}
          uses the following
          {' '}
          <a href="https://github.com/postcss/postcss-custom-media" target="_blank" rel="noopener noreferrer">
            custom media queries
          </a>
          :
        </p>

        <pre className="mb-2">
          {`@custom-media --viewport-sm-min (min-width: 0);
@custom-media --viewport-sm-max (max-width: 600px);
@custom-media --viewport-md-min (min-width: 601px);
@custom-media --viewport-md-max (max-width: 991px);
@custom-media --viewport-lg-min (min-width: 992px);
@custom-media --viewport-lg-max (max-width: 1200px);
@custom-media --viewport-xl-min (min-width: 1201px);`}
        </pre>

        <p>
          There is one more custom media query that is used (if provided) by Fidelity UI. By default it is not set:
        </p>

        <pre>
          {'@custom-media --viewport-xl-max (max-width: none);'}
        </pre>

        <CardDivider />

        <p>
          To customize those media queries, create a stylesheet (example name:
          {' '}
          <code>app.css</code>
          ) and add to it:
        </p>

        <pre className="mb-2">
          {`@import '_breakpoints.css';
@import 'react-fidelity-ui/src/css/main.css';
@import '_config.css';`}
        </pre>

        <CardDivider />

        <p>
          <strong>1.</strong>
          {' '}
          In the above code
          {' '}
          <code>_breakpoints.css</code>
          {' '}
          should contain your custom media queries. For example:
        </p>

        <pre className="mb-1">
          {`@custom-media --viewport-md-min (min-width: 620px);
@custom-media --viewport-md-max (max-width: 780px);
@custom-media --viewport-lg-min (min-width: 781px);
@custom-media --viewport-lg-max (max-width: 1200px);`}
        </pre>
        <small>
          <p className="mb-0">
            You can use a custom media query in your stylesheets like this:
          </p>

          <pre>
            {`@media (--viewport-md-min) {
  padding: var(--spacer);
}`}
          </pre>
        </small>

        <CardDivider />

        <p>
          <strong>
            2. Fidelity UI
            <code>main.css</code>
            {' '}
            file should be imported
            {' '}
            <u>AFTER</u>
            {' '}
            your custom media queries and
            {' '}
            <u>BEFORE</u>
            {' '}
            your css variables.
          </strong>
          <br />
          This way the library styles will be built using your custom media queries. Also, the library css variables will be overwritten by your css variables.
        </p>

        <CardDivider />

        <p>
          <strong>3.</strong>
          {' '}
          In the
          {' '}
          <code>app.css</code>
          {' '}
          file from above,
          {' '}
          <code>_config.css</code>
          {' '}
          should contain your custom css variables:
        </p>

        <pre>
          {`:root {
  --color-warning: purple;
  ...
  
  .fidelity-ui-app .btn {
    --btn-color-bg: var(--color-warning);
    ...
  }
}`}
        </pre>
      </Card>
    </div>
  );
};

export default Customizations;
