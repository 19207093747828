import React, { useState } from 'react';
import { Card, Divider, ButtonGroup, Button, Grid, Badge, Icon, Number } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const SingleProductWomen = () => {
  const sizes = ['xs', 's', 'm', 'l', 'xl'];
  const colors = ['#C2C1BF', '#5D5D5D', '#1B435D'];
  const [quantity, setQuantity] = useState(1);
  const [activeSize, setActiveSize] = useState('s');
  const [activeColor, setActiveColor] = useState(colors[0]);

  return (
    <Card
      hovered
      padded={false}
      bordered={false}
    >
      <Grid>
        <Row>
          <Col
            widths={['md-12', 'lg-7']}
            className="pr-0"
          >
            <div className="card--padding-lg">
              <h2 className="font-weight--bolder">
                {'Fleecy Coat With Hood'}
              </h2>

              <span className="h4 font-weight--bolder">
                {'£29.99'}
              </span>

              <ButtonGroup
                className="mt-4 mb-2"
                stacked
                rounded
              >
                <Button
                  type="text"
                  active
                  outlined
                >
                  {'Details'}
                </Button>
                <Button
                  type="text"
                  outlined
                >
                  {'Payment'}
                </Button>
                <Button
                  type="text"
                  outlined
                >
                  {'Delivery'}
                </Button>
              </ButtonGroup>

              <p>
                {'This sounded nonsense to Alice, so she said nothing, but set off at once toward the Red Queen.'}
              </p>

              <Divider />

              <div className="d-flex justify-content-between mb-3">
                <div>
                  <span>{'Color'}</span>
                  <ButtonGroup className="mt-1">
                    {colors.map(color => (
                      <Button
                        key={`color-${color}`}
                        type="icon"
                        size="sm"
                        className="mb-0"
                        onClick={() => setActiveColor(color)}
                      >
                        <Badge
                          size="sm"
                          className="p-0"
                          style={{ backgroundColor: color }}
                          hovered
                          fab
                        >
                          {color === activeColor && (
                            <Icon id="ion-checkmark" />
                          )}
                        </Badge>
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>

                <div>
                  <span>{'Size'}</span>
                  <ButtonGroup className="mt-1">
                    {sizes.map(size => (
                      <Button
                        key={`size-${size}`}
                        type={size === activeSize ? 'secondary' : 'text'}
                        size="sm"
                        hovered
                        fab
                        outlined
                        onClick={() => setActiveSize(size)}
                      >
                        {size.toUpperCase()}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </div>

              <div className="mt-2">
                <span>{'Quantity'}</span>
                <ButtonGroup
                  className="mt-1"
                  stacked
                  rounded
                >
                  <Button
                    type="text"
                    size="sm"
                    className="mb-0"
                    outlined
                    flat
                    onClick={() => {
                      if (quantity === 0) {
                        return false;
                      }

                      setQuantity(quantity - 1);
                    }}
                  >
                    <Icon id="ion-minus" />
                  </Button>

                  <Number
                    value={quantity}
                    style={{ width: 50 }}
                    size="sm"
                    className="text-align--center"
                    squared
                    onChange={({ target }) => {
                      setQuantity(parseInt(target.value, 10));
                    }}
                  />

                  <Button
                    type="text"
                    size="sm"
                    className="mb-0"
                    outlined
                    flat
                    onClick={() => {
                      setQuantity(quantity + 1);
                    }}
                  >
                    <Icon id="ion-plus" />
                  </Button>
                </ButtonGroup>
              </div>

              <Divider />

              <ButtonGroup
                align="left"
                className="mt-4"
              >
                <Button
                  type="info"
                  size="lg"
                  rounded
                  hovered
                  flat={false}
                >
                  <Icon
                    id="ion-ios-cart"
                    className="color--white mr-1"
                  />
                  {'Add to Cart'}
                </Button>

                <Button
                  type="text"
                  size="lg"
                  rounded
                  outlined
                  hovered
                  flat={false}
                >
                  <Icon
                    id="ion-heart"
                    className="mr-1"
                  />
                  {'Add to Wishlist'}
                </Button>
              </ButtonGroup>
            </div>
          </Col>

          <Col
            widths={['md-12', 'lg-5']}
            className="pl-lg-0"
          >
            <div className="bg-image women-with-hoodie-bg" />
          </Col>
        </Row>
      </Grid>
    </Card>
  );
};

export default SingleProductWomen;
