import React, { useState } from 'react';
import { InputGroup, Card, Button, Password } from 'react-fidelity-ui';

const RestorePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Restore Password'}
      </h2>

      <form>
        <InputGroup>
          <Password
            value={password}
            placeholder="New Password"
            rounded
            onChange={({ target }) => {
              setPassword(target.value);
            }}
          />
        </InputGroup>
        <InputGroup>
          <Password
            value={confirmPassword}
            placeholder="Repeat Password"
            rounded
            onChange={({ target }) => {
              setConfirmPassword(target.value);
            }}
          />
        </InputGroup>

        <Button
          className="mb-2"
          expanded
          rounded
          onClick={onClick}
        >
          {'Create New Password'}
        </Button>
      </form>
    </Card>
  )
};

export default RestorePassword;
