import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'active',
  description: (
    <span>
      {'displays the modal when set to '}
      {renderBadge('true')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'children',
  description: 'content',
  type: 'node | string | number',
  default: null
}, {
  property: 'disableScroll',
  description: (
    <span>
      {'disables the body scroll when '}
      {renderBadge('active')}{' is set to '}
      {renderBadge('true')}
    </span>
  ),
  type: 'boolean',
  default: 'true'
}];
