import React, { useState } from 'react';
import { InputGroup, Card, Email, Password, Icon, Button, Divider, ButtonGroup } from 'react-fidelity-ui';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Login'}
      </h2>

      <form>
        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            hovered
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Password
            value={password}
            label="Password"
            placeholder="Enter your password..."
            icon={<Icon id="ion-android-lock" />}
            hovered
            flat={false}
            onChange={({ target }) => {
              setPassword(target.value);
            }}
          />
        </InputGroup>

        <p className="mt-3 text-align--center">
          <a href="#">
            <strong>
              {'Forgot Password?'}
            </strong>
          </a>
        </p>

        <Button
          type="success"
          className="mb-2"
          expanded
          rounded
          onClick={onClick}
        >
          {'Sign In'}
        </Button>

        <Divider>{'OR'}</Divider>

        <ButtonGroup
          align="center"
          wrapped={false}
        >
          <Button
            type="facebook"
            size="sm"
            fab
            className="btn-icon"
            onClick={onClick}
          >
            <Icon id="ion-social-facebook" />
          </Button>
          <Button
            type="google"
            size="sm"
            fab
            className="btn-icon"
            onClick={onClick}
          >
            <Icon id="ion-social-google" />
          </Button>
        </ButtonGroup>
      </form>
    </Card>
  )
};

export default Login;
