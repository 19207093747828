import React, { useState } from 'react';
import { Card, Button, Table, Dropdown, Search, Grid } from 'react-fidelity-ui';
import dropdownApi from '../../../config/components/dropdownApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const DropdownShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const [search, setSearch] = useState('');

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Dropdown</h2>

        <small>
          <span>Composite stateless functional component</span>
          <br />
          <pre>
            {`import { Dropdown } from 'react-fidelity-ui';
            
const { Body, Text, Item, Divider } = Dropdown;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          Customizable dropdown with four derivative components -
          {' '}
          <code>Body</code>
          ,
          {' '}
          <code>Text</code>
          ,
          {' '}
          <code>Item</code>
          {' and '}
          <code>Divider</code>
          . To create a usable dropdown you will need at least
          {' '}
          <code>Dropdown</code>
          {' and '}
          <code>Body</code>
          .
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown active={isAOn}>
                <Button
                  onMouseEnter={() => setIsAOn(true)}
                  onMouseLeave={() => setIsAOn(false)}
                >
                  Triggers on hover
                </Button>

                <Dropdown.Body
                  size="md"
                  style={{
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                >
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown active={active}>
  <Button
    onMouseEnter={activate}
    onMouseLeave={deactivate}
  >
    Triggers on hover
  </Button>
  
  <Dropdown.Body
    size="md"
    style={{
      left: '50%',
      transform: 'translateX(-50%)'
    }}
  >
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown active={isBOn}>
                <Search
                  value={search}
                  placeholder="Triggers on focus"
                  onFocus={() => setIsBOn(true)}
                  onBlur={() => setIsBOn(false)}
                  onChange={({ target }) => setSearch(target.value)}
                />

                <Dropdown.Body
                  size="lg"
                  style={{
                    top: 'calc(100% + 10px)',
                    left: 0,
                    right: 'auto'
                  }}
                >
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`const [search, setSearch] = useState('');

<Dropdown active={active}>
  <Search
    value={search}
    placeholder="Triggers on focus"
    onFocus={activate}
    onBlur={deactivate}
    onChange={({ target }) => setSearch(target.value)}
  />
  
  <Dropdown.Body
    size="lg"
    style={{
      top: 'calc(100% + 10px)',
      left: 0,
      right: 'auto'
    }}
  >
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>
          </Row>

          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isCOn}
                onClickOutside={() => {
                  if (isCOn) {
                    setIsCOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsCOn(!isCOn)}>
                  Align right
                </Button>

                <Dropdown.Body
                  size="lg"
                  style={{
                    top: 'calc(100% + 20px)',
                    left: 'auto',
                    right: 0
                  }}
                >
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  onClickOutside={deactivate}
  active={active}
>
  <Button onClick={toggle}>
    Align right
  </Button>
  
  <Dropdown.Body
    size="lg"
    style={{
      top: 'calc(100% + 20px)',
      left: 'auto',
      right: 0
    }}
  >
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isDOn}
                onClickOutside={() => {
                  if (isDOn) {
                    setIsDOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsDOn(!isDOn)}>
                  Nudge Bottom with 5px
                </Button>

                <Dropdown.Body
                  size="xl"
                  style={{ top: 'calc(100% + 5px)' }}
                >
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Nudge Bottom with 5px
  </Button>
  
  <Dropdown.Body
    size="xl"
    style={{ top: 'calc(100% + 5px)' }}
  >
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={dropdownApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default DropdownShowcase;
