export default [
  {
    name: 'installation',
    label: 'Installation'
  },
  {
    name: 'browser-support',
    label: 'Browser Support'
  },
  {
    name: 'customizations',
    label: 'Customizations'
  },
  {
    name: 'live-theming',
    label: 'Live Theming'
  },
  {
    name: 'icons',
    label: 'Icons'
  }
];
