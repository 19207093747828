import React, { useState } from 'react';
import { Card, Table, Grid, Transition, CardDivider, Button } from 'react-fidelity-ui';
import transitionApi from '../../../config/components/transitionApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const TransitionShowcase = () => {
  const [isFadeVisible, setIsFadeVisible] = useState(true);
  const [isFadeOutVisible, setIsFadeOutVisible] = useState(true);
  const [isSpinVisible, setIsSpinVisible] = useState(true);
  const [isFadeInUpVisible, setIsFadeInUpVisible] = useState(true);
  const [isFadeInRightVisible, setIsFadeInRightVisible] = useState(true);
  const [isFadeInDownVisible, setIsFadeInDownVisible] = useState(true);
  const [isFadeInLeftVisible, setIsFadeInLeftVisible] = useState(true);
  const [isSlideInUpVisible, setIsSlideInUpVisible] = useState(true);
  const [isSlideInRightVisible, setIsSlideInRightVisible] = useState(true);
  const [isSlideInDownVisible, setIsSlideInDownVisible] = useState(true);
  const [isSlideInLeftVisible, setIsSlideInLeftVisible] = useState(true);
  const toggle = (callback, timeout = 300) => {
    callback(false);

    setTimeout(() => {
      callback(true);
    }, timeout);
  };

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'Transition'}
        </h2>

        <small>
          <span>
            {'Stateless functional component'}
          </span>
          <br />
          <code>
            {`import { Transition } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Animates the appearance of child components.'}
        </p>
      </Card>

      <div className="d-flex align-items-center">
        <h2 className="mr-2">
          {'Types'}
        </h2>
      </div>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-4']}>
              <div className="animation-card">
                {isFadeVisible && (
                  <Transition
                    onComplete={() => {
                      console.warn('onComplete::');
                      console.log();
                    }}
                    onAnimationEnd={() => {
                      console.warn('onAnimationEnd::');
                      console.log();
                    }}
                  >
                    <Card className="mb-0">Fade</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition>
<Card>Fade</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <div className="animation-card">
                {isFadeOutVisible && (
                  <Transition type="fade-out">
                    <Card className="mb-0">Fade out</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeOutVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="fade-out">
<Card>Fade out</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <div className="animation-card">
                {isSpinVisible && (
                  <Transition type="spin">
                    <Card className="mb-0">Spin</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsSpinVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="spin">
<Card>Spin</Card>
</Transition>`}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isFadeInUpVisible && (
                  <Transition type="fade-in-up">
                    <Card className="mb-0">Fade in up</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeInUpVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="fade-in-up">
<Card>Fade in up</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isFadeInRightVisible && (
                  <Transition type="fade-in-right">
                    <Card className="mb-0">Fade in right</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeInRightVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="fade-in-right">
<Card>Fade in right</Card>
</Transition>`}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isFadeInDownVisible && (
                  <Transition type="fade-in-down">
                    <Card className="mb-0">Fade in down</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeInDownVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="fade-in-down">
<Card>Fade in down</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isFadeInLeftVisible && (
                  <Transition type="fade-in-left">
                    <Card className="mb-0">Fade in left</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsFadeInLeftVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="fade-in-left">
<Card>Fade in left</Card>
</Transition>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isSlideInUpVisible && (
                  <Transition type="slide-in-up">
                    <Card className="mb-0">Slide in up</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsSlideInUpVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="slide-in-up">
<Card>Slide in up</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isSlideInRightVisible && (
                  <Transition type="slide-in-right">
                    <Card className="mb-0">Slide in right</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsSlideInRightVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="slide-in-right">
<Card>Slide in right</Card>
</Transition>`}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isSlideInLeftVisible && (
                  <Transition type="slide-in-left">
                    <Card className="mb-0">Slide in left</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsSlideInLeftVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="slide-in-left">
<Card>Slide in left</Card>
</Transition>`}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <div className="animation-card">
                {isSlideInDownVisible && (
                  <Transition type="slide-in-down">
                    <Card className="mb-0">Slide in down</Card>
                  </Transition>
                )}
              </div>

              <Button
                size="sm"
                className="mt-1"
                onClick={() => toggle(setIsSlideInDownVisible)}
              >
                Toggle
              </Button>

              <pre className="mt-1">
                {`<Transition type="slide-in-down">
<Card>Slide in down</Card>
</Transition>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={transitionApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default TransitionShowcase;
