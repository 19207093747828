import React from 'react';
import { Card, Table } from 'react-fidelity-ui';
import { Link } from 'react-router-dom';
import appApi from '../../../config/components/appApi';
import apiColumns from '../../../config/apiColumns';

const AppShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          App
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {'import { App } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          <strong><code>App</code> is a required component. It wraps all other Fidelity UI components.</strong>
        </p>

        <p>
          <code>App</code> has a class name <code>fidelity-ui-app</code> that is required and used by all other components for their scoped styles. This is because Fidelity UI keeps the global css scope clean by exposing a single global css class - <code>fidelity-ui-app</code>.
        </p>

        <p className="mb-0">
          This component is also used to configure global Fidelity UI settings, like <code>iconsSprite</code>. Those settings can then be utilized by all child components like <Link to="/components/icon"><code>Icon</code></Link>. Underneath, <code>App</code> uses the <a href="https://reactjs.org/docs/context.html" target="_blank" rel="noopener noreferrer">React Context API</a> to expose settings to its child components.
        </p>
      </Card>

      <h2>
        Examples
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <pre>
          {`import iconsSprite from './icons-sprite.svg';
...

<App iconsSprite={iconsSprite}>\n ...\n</App>
          `}
        </pre>
      </Card>

      <h2>
        API
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={appApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default AppShowcase;
