import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Input, Number, Search, Email, Password, Checkbox, InputGroup, Radio, Icon, Card, Grid, Textarea, Transition, hooks } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const Inputs = () => {
  const refTextarea = useRef(null);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('email@email.com');
  const [address, setAddress] = useState('St John Str 182');
  const [count, setCount] = useState(0);
  const [password, setPassword] = useState('');
  const [search, setSearch] = useState('');
  const [checkedA, setCheckedA] = useState(false);
  const [checkedB, setCheckedB] = useState(true);
  const [radioValue, setRadioValue] = useState('a');
  const [textarea, setTextarea] = useState('Lorem ipsum dolor sit amet.');
  const textareaClassName = classNames({
    autosize: true
  });

  return (
    <div>
      <Card
        hovered
        bordered={false}
      >
        <form>
          <InputGroup isRow>
            <InputGroup>
              <Input
                value={firstName}
                label="First Name"
                placeholder="Enter first name"
                rounded
                onChange={({ target }) => {
                  setFirstName(target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Email
                value={email}
                label="Email"
                placeholder="Enter email"
                icon={<Icon id="ion-email" />}
                onChange={({ target }) => {
                  setEmail(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <InputGroup
            className="mb-6"
            isRow
          >
            <InputGroup>
              <Password
                value={password}
                label="Password"
                placeholder="Enter password"
                rounded
                onChange={({ target }) => {
                  setPassword(target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Input
                value={address}
                label="Address"
                placeholder="Enter address"
                icon={<Icon id="ion-android-map" />}
                onChange={({ target }) => {
                  setAddress(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <InputGroup isRow>
            <InputGroup isRow>
              <Input
                value={firstName}
                label="First name"
                placeholder="Enter first name"
                rounded
                validated
                valid
                onChange={({ target }) => {
                  setFirstName(target.value);
                }}
              />
            </InputGroup>
            <InputGroup isRow>
              <Email
                value={email}
                label="E-mail"
                placeholder="Enter email"
                icon={<Icon id="ion-email" />}
                onChange={({ target }) => {
                  setEmail(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <InputGroup isRow>
            <InputGroup isRow>
              <Password
                value={password}
                label="Your Pass"
                placeholder="Enter password"
                rounded
                onChange={({ target }) => {
                  setPassword(target.value);
                }}
              />
            </InputGroup>
            <InputGroup isRow>
              <Input
                value={firstName}
                label="Name"
                placeholder="Enter name"
                icon={<Icon id="ion-android-person" />}
                onChange={({ target }) => {
                  setFirstName(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <InputGroup
            className="mt-6 mb-6"
            isRow
          >
            <InputGroup>
              <Input
                value={firstName}
                label="First Name"
                placeholder="Enter first name"
                rounded
                loading
                icon={(
                  <Transition
                    type="spin"
                    style={{
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear'
                    }}
                  >
                    <Icon id="ion-load-a" />
                  </Transition>
                )}
                onChange={({ target }) => {
                  setFirstName(target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Input
                value={address}
                label="Address"
                placeholder="Enter address"
                icon={<Icon id="ion-android-map" />}
                prependIcon={false}
                readOnly
                onChange={({ target }) => {
                  setAddress(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <Grid>
            <Row className="mt-3 mb-3">
              <Col widths={['sm-12', 'md-6']}>
                <InputGroup>
                  <Radio
                    label="Option A"
                    value="a"
                    checked={radioValue === 'a'}
                    onChange={({ target }) => {
                      setRadioValue(target.value);
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Radio
                    label="Option B"
                    value="b"
                    checked={radioValue === 'b'}
                    onChange={({ target }) => {
                      setRadioValue(target.value);
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Radio
                    label="Option C"
                    value="c"
                    checked={radioValue === 'c'}
                    onChange={({ target }) => {
                      setRadioValue(target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col widths={['sm-12', 'md-6']}>
                <InputGroup isRow>
                  <InputGroup>
                    <Radio
                      label="Option A"
                      value="a"
                      checked={radioValue === 'a'}
                      onChange={({ target }) => {
                        setRadioValue(target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Radio
                      label="Option B"
                      value="b"
                      checked={radioValue === 'b'}
                      onChange={({ target }) => {
                        setRadioValue(target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Radio
                      label="Option C"
                      value="c"
                      checked={radioValue === 'c'}
                      onChange={({ target }) => {
                        setRadioValue(target.value);
                      }}
                    />
                  </InputGroup>
                </InputGroup>
              </Col>
            </Row>
          </Grid>

          <InputGroup
            isRow
            className="mb-4"
          >
            <InputGroup>
              <Input
                value={firstName}
                label="First Name"
                placeholder="Enter first name"
                rounded
                validated
                valid={false}
                onChange={({ target }) => {
                  setFirstName(target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Email
                value={email}
                label="Email"
                placeholder="Enter email"
                icon={<Icon id="ion-email" />}
                prependIcon={false}
                validated
                disabled
                valid={false}
                onChange={({ target }) => {
                  setEmail(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
          <InputGroup>
            <Checkbox
              label="Toggle setting"
              checked={checkedA}
              onChange={({ target }) => {
                setCheckedA(target.checked);
              }}
            />
          </InputGroup>
          <InputGroup>
            <Checkbox
              label="Toggle another setting"
              checked={checkedB}
              onChange={({ target }) => {
                setCheckedB(target.checked);
              }}
            />
          </InputGroup>
          <InputGroup className="mb-4">
            <span ref={refTextarea}>
              <Textarea
                value={textarea}
                rows={4}
                className={textareaClassName}
                onChange={({ target }) => {
                  setTextarea(target.value);
                }}
              />
            </span>
          </InputGroup>
          <InputGroup isRow>
            <InputGroup>
              <Number
                value={count}
                label="Count"
                placeholder="Enter count"
                validated
                valid={count > 0}
                icon={<Icon id="ion-stats-bars" />}
                onChange={({ target }) => {
                  setCount(target.value);
                }}
              />
            </InputGroup>
            <InputGroup>
              <Search
                value={search}
                label="Search"
                placeholder="Search..."
                icon={<Icon id="ion-ios-search-strong" />}
                onChange={({ target }) => {
                  setSearch(target.value);
                }}
              />
            </InputGroup>
          </InputGroup>
        </form>
      </Card>
    </div>
  );
};

export default Inputs;
