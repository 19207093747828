import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'elevated',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies box shadow equal to '}
      {renderBadge('var(--progress-shadow)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'hovered',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'adds more prominent box shadow on hover; equal to '}
      {renderBadge('var(--progress-shadow-hover)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'label',
  description: 'label slot',
  type: 'node | string | number | null',
  default: 'null'
}, {
  property: 'labelAlignment',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'determines the label alignment; can be set to '}
      {renderBadge('left')}{' '}
      {renderBadge('center')}{' '}
      {renderBadge('sticky')}{' '}
      {renderBadge('right')}{'; default value of '}
      {renderBadge('null')}{' equals '}
      {renderBadge('left')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'rounded',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies border radius equal to '}
      {renderBadge('var(--progress-border-radius)')}
    </span>
  ),
  type: 'boolean',
  default: 'true'
}, {
  property: 'size',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('xs')}{' '}
      {renderBadge('sm')}{' '}
      {renderBadge('md')}{' '}
      {renderBadge('lg')}{' '}
      {renderBadge('xl')}{'; default value of '}
      {renderBadge('null')}{' equals '}
      {renderBadge('md')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'type',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('primary')}{' '}
      {renderBadge('secondary')}{' '}
      {renderBadge('success')}{' '}
      {renderBadge('info')}{' '}
      {renderBadge('danger')}{' '}
      {renderBadge('warning')}{' '}
    </span>
  ),
  type: 'string',
  default: 'secondary'
}, {
  property: 'value',
  description: 'progress value in percentages',
  type: 'number',
  default: '0'
}];
