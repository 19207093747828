import React from 'react';
import { Card, CardDivider, Badge, Table, Grid } from 'react-fidelity-ui';
import badgeApi from '../../../config/components/badgeApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const BadgeShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'Badge'}
        </h2>

        <small>
          <span>
            {'Stateless functional component'}
          </span>
          <br />
          <code>
            {`import { Badge } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Highlights important notifications.'}
        </p>
      </Card>

      <h2>
        {'Types'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-6']}>
              <Badge>
                {'Default'}
              </Badge>

              <pre>
                {'<Badge> \n Default\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Badge type="primary">
                {'Primary'}
              </Badge>

              <pre>
                {'<Badge type="primary"> \n Primary\n</Badge>'}
              </pre>
            </Col>
          </Row>

          <CardDivider />

          <Row className="mb-2">
            <Col widths={['sm-12', 'md-6']}>
              <Badge type="secondary">
                {'Secondary'}
              </Badge>

              <pre>
                {'<Badge type="secondary"> \n Secondary\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Badge type="info">
                {'Info'}
              </Badge>

              <pre>
                {'<Badge type="info"> \n Info\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-4']}>
              <Badge type="success">
                {'Success'}
              </Badge>

              <pre>
                {'<Badge type="success"> \n Success\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge type="warning">
                {'Warning'}
              </Badge>

              <pre>
                {'<Badge type="warning"> \n Warning\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge type="danger">
                {'Danger'}
              </Badge>

              <pre>
                {'<Badge type="danger"> \n Danger\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Sizes'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4']}>
              <Badge size="sm">
                {'Small'}
              </Badge>

              <pre>
                {'<Badge size="sm"> \n Small\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge size="md">
                {'Medium'}
              </Badge>

              <pre>
                {'<Badge size="md"> \n Medium\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge size="lg">
                {'Large'}
              </Badge>

              <pre>
                {'<Badge size="lg"> \n Large\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'States'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4']}>
              <Badge loading>
                {'Loading'}
              </Badge>

              <pre>
                {'<Badge loading> \n Loading\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge squared>
                {'SQ'}
              </Badge>

              <pre>
                {'<Badge squared> \n SQ\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge rounded>
                {'Rounded'}
              </Badge>

              <pre>
                {'<Badge rounded> \n Rounded\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-6']}>
              <Badge fab>
                {'Fab'}
              </Badge>

              <pre>
                {'<Badge fab> \n Fab\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <Badge outlined>
                {'Outlined'}
              </Badge>

              <pre>
                {'<Badge outlined> \n Outlined\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="success"
                size="md"
                loading
                squared
              />

              <pre>
                {'<Badge \n type="success" \n size="md" \n loading \n squared\n/>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="info"
                size="lg"
                fab
                outlined
              >
                {'Secondary'}
              </Badge>

              <pre>
                {'<Badge \n type="info" \n size="lg" \n fab \n outlined\n> \n Secondary\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="warning"
                size="md"
                rounded={false}
                squared
              >
                {'Warning'}
              </Badge>

              <pre>
                {'<Badge \n type="warning" \n size="md" \n rounded={false} \n squared\n> \n Warning\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="danger"
                size="md"
                rounded={false}
                outlined
              >
                {'Error'}
              </Badge>

              <pre>
                {'<Badge \n type="danger" \n size="sm" \n rounded={false} \n outlined\n> \n Error\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="info"
                size="md"
                rounded={false}
              >
                {'Info'}
              </Badge>

              <pre>
                {'<Badge \n type="info" \n size="md" \n rounded={false} \n outlined\n> \n Info\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="text"
                size="md"
              >
                {'Text'}
              </Badge>

              <pre>
                {'<Badge \n type="text" \n size="md" \n rounded={false} \n outlined\n> \n Text\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="success"
                size="lg"
                rounded={false}
              >
                {'Success'}
              </Badge>

              <pre>
                {'<Badge \n type="success" \n size="lg" \n rounded={false} \n> \n Success\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="danger"
                size="lg"
              >
                {'Danger'}
              </Badge>

              <pre>
                {'<Badge \n type="danger" \n size="lg" \n> \n Danger\n</Badge>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4']}>
              <Badge
                type="info"
                size="md"
                fab
              >
                {'Fab'}
              </Badge>

              <pre>
                {'<Badge \n type="info" \n size="md" \n fab\n> \n Fab\n</Badge>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={badgeApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default BadgeShowcase;
