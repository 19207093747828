import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Card, ButtonGroup, Button, Grid, Divider, Icon, Image } from 'react-fidelity-ui';
import avatar from '../../../images/avatar-1.png';

const { Row, Col } = Grid;

const Feed = () => {
  const articles = [{
    id: 1,
    title: 'How to Become 10x Developer',
    updatedAt: '24 minutes ago',
    image: false
  }, {
    id: 2,
    title: '8 Ways Elite Athletes Perform At The Highest Level',
    updatedAt: '43 minutes ago',
    image: false
  }, {
    id: 3,
    title: 'How we Built a Tech Startup Without Tech',
    updatedAt: '43 minutes ago',
    image: true
  }, {
    id: 4,
    title: 'Your Life is Tetris. Stop Playing it Like Chess',
    updatedAt: '24 minutes ago',
    image: false
  }];

  return (
    <div className="feed grid-box">
      <div className="bg-image mystique-bg height-100">
        <Card
          paddingSize="lg"
          className="grid-cell bg--transparent"
          hovered
          bordered={false}
        >
          <Grid>
            <Row>
              <Col widths={['sm-12', 'md-8']}>
                <span className="h5 color--white">
                  {'X-men Heroes'}
                </span>

                <h2 className="mt-2 color--white">
                  {'Mystique Raven Darkholme'}
                </h2>

                <p className="mb-4 color--white">
                  {'Mystique is a member of a subspecies of humanity known as mutants, who are born with superhuman abilities.'}
                </p>

                <Button
                  type="info"
                  className="color--white"
                  outlined
                  rounded
                >
                  {'View All Heroes'}
                </Button>
              </Col>
            </Row>
          </Grid>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="grid-cell"
          hovered
          padded={false}
          bordered={false}
        >
          <div className="h5 pt-2 pr-2 pb-1 pl-2 mb-0">
            {'Articles'}
          </div>
          <Divider className="mt-0" />

          {articles.map((article, index) => {
            const dividerClassName = classNames({
              'mb-0': index === articles.length - 1
            });

            const articleImageClassName = classNames({
              'article-item-image': true,
              'bg-image': article.image,
              'typewriter-bg': article.image,
              'mb-2': article.image
            });

            return (
              <Fragment key={`article-${article.id}`}>
                <div className="article-item">
                  {article.image && (
                    <div className={articleImageClassName} />
                  )}

                  <span className="h4">
                    {article.title}
                  </span>

                  <dt>
                    <small>{article.updatedAt}</small>
                  </dt>
                </div>

                <Divider
                  size="sm"
                  className={dividerClassName}
                />
              </Fragment>
            );
          })}

          <ButtonGroup
            align="center"
            className="mt-0"
          >
            <Button type="link">
              {'Load more'}
            </Button>
          </ButtonGroup>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="d-flex flex-column grid-cell align-items-stretch bg-image flowers-bg color--white overlay"
          hovered
          bordered={false}
        >
          <div className="mb-2 h5 color--gray-2">
            {'User Experience Design'}
          </div>

          <div className="mt-1">
            <span className="h3 color--white">
              {'I\'m not smart, I just sat there for longer than you'}
            </span>

            <p className="mt-2">
              {'If anyone is struggling with coding, or just learning something difficult, this post might give you some sort of...'}
            </p>
          </div>

          <div className="d-flex justify-content-between mt-8">
            <div>
              <Icon id="ion-heart" />{' '}
              <small>
                <strong>{126}</strong>
              </small>
            </div>

            <div>
              <Icon id="ion-chatbubble-working" />{' '}
              <small>
                <strong>{31}</strong>
              </small>
            </div>

            <div>
              <Icon id="ion-clock" />{' '}
              <small>
                <strong>{'15 minutes ago'}</strong>
              </small>
            </div>
          </div>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="d-flex flex-column grid-cell align-items-stretch bg--warning color--white"
          hovered
          bordered={false}
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <Icon id="ion-android-time" />{' '}
              <small>{'31 Jan, 2019'}</small>
            </div>

            <div>
              <Icon id="ion-chatbubble-working" />{' '}
              <small>{12}</small>
            </div>
          </div>

          <span className="h3 color--white">
            {'These 39 Sites Have Amazing Stock Photos You Can Use For Free'}
          </span>

          <div className="d-flex align-items-center mt-8">
            <Image
              src={avatar}
              size="sm"
              className="mr-1"
            />
            <span>
              {'John Doe'}
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Feed;
