import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'fab',
  description: 'rounds badge and sets square width/height',
  type: 'boolean',
  default: 'false'
}, {
  property: 'rounded',
  description: 'rounds buttons',
  type: 'boolean',
  default: 'false'
}, {
  property: 'loading',
  description: 'adds loading state',
  type: 'boolean',
  default: 'false'
}, {
  property: 'outlined',
  description: 'makes the background transparent and applies border',
  type: 'boolean',
  default: 'false'
}, {
  property: 'squared',
  description: 'sets border radius to 0',
  type: 'boolean',
  default: 'false'
}, {
  property: 'size',
  description: (
    <span>
      {'can be set to '}
      {renderBadge('sm')}{' '}
      {renderBadge('md')}{' '}
      {renderBadge('lg')}
    </span>
  ),
  type: 'string',
  default: 'sm'
}, {
  property: 'type',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('primary')}{' '}
      {renderBadge('secondary')}{' '}
      {renderBadge('success')}{' '}
      {renderBadge('info')}{' '}
      {renderBadge('danger')}{' '}
      {renderBadge('warning')}{' '}
      {renderBadge('text')}{' '}
    </span>
  ),
  type: 'string',
  default: 'secondary'
}];
