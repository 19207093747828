import React, { useState, useRef } from 'react';
import { Card, Button, Table, Modal, Grid } from 'react-fidelity-ui';
import modalBackdropApi from '../../../config/components/modalBackdropApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const ModalBackdropShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const refModal = useRef(null);
  const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';
  const darkerBackground = 'rgba(0, 0, 0, 0.65)';

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Backdrop</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Modal } from 'react-fidelity-ui';
            
const { Backdrop } = Modal;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component represents a box the size of the viewport beneath the modal.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsAOn(!isAOn)}>
                Default Backdrop
              </Button>

              <Modal active={isAOn}>
                <Modal.Backdrop>
                  <Modal.Content size="md">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsAOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Toggle Modal
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="md">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsBOn(!isBOn)}>
                Smooth Backdrop
              </Button>

              <Modal active={isBOn}>
                <Modal.Backdrop style={{ transition: smoothTransition }}>
                  <Modal.Content size="lg">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsBOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';
...

<Button onClick={toggle}>
  Smooth Backdrop
</Button>

<Modal active={active}>
  <Modal.Backdrop style={{ transition: smoothTransition }}>
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>

          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsCOn(!isCOn)}>
                Darker backdrop
              </Button>

              <Modal active={isCOn}>
                <Modal.Backdrop style={{ background: darkerBackground }}>
                  <Modal.Content size="lg">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsCOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`const darkerBackground = 'rgba(0, 0, 0, 0.65)';
...

<Button onClick={toggle}>
  Darker backdrop
</Button>
            
<Modal active={active}>
  <Modal.Backdrop style={{ background: darkerBackground }}>
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button
                onClick={() => setIsDOn(!isDOn)}
              >
                Decentralized content
              </Button>

              <div ref={refModal}>
                <Modal active={isDOn}>
                  <Modal.Backdrop
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      padding: '3rem'
                    }}
                  >
                    <Modal.Content size="lg">
                      <Modal.Body>
                        <p>{dummyTextShort}</p>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button onClick={() => setIsDOn(false)}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal.Content>
                  </Modal.Backdrop>
                </Modal>
              </div>

              <pre>
                {`<Button onClick={toggle}>
  Decentralized content
</Button>

<Modalactive={active}>
  <Modal.Backdrop
    style={{
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '3rem'
    }}
  >
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={modalBackdropApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ModalBackdropShowcase;
