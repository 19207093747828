import React from 'react';
import { Card, Button, Grid, Badge, Transition } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const HeroB = () => {
  return (
    <div className="bg-image suit-bg mb-3">
      <Card
        paddingSize="lg"
        className="bg--transparent color--white"
        hovered
        bordered={false}
      >
        <Transition type="fade-in-down">
          <Grid>
            <Row>
              <Col
                widths={['md-12', 'lg-7']}
                className="text-align--center m-auto"
              >
                <Badge
                  type="primary"
                  size="md"
                  className="mb-2"
                  rounded
                >
                  {'Free shipping'}
                </Badge>

                <h1 className="mb-4 font-weight--bolder">
                  {'Last Minute Additions to Complete a Look'}
                </h1>

                <Button
                  rounded
                  outlined
                >
                  {'Shop Men\'s'}
                </Button>
              </Col>
            </Row>
          </Grid>
        </Transition>
      </Card>
    </div>
  );
};

export default HeroB;
