export default [
  {
    name: 'philosophy',
    path: '/philosophy',
    label: 'Philosophy'
  },
  {
    name: 'docs',
    path: '/docs',
    label: 'Docs'
  },
  {
    name: 'assets',
    path: '/assets',
    label: 'Assets'
  },
  {
    name: 'components',
    path: '/components',
    label: 'Components'
  }
];
