import React, { useState, useRef } from 'react';
import { Card, Modal, ButtonGroup, Button, Icon } from 'react-fidelity-ui';
import dummyText from '../../../mock/dummyText';

const Modals = () => {
  const [isModalAOn, setIsModalAOn] = useState(false);
  const [isModalBOn, setIsModalBOn] = useState(false);
  const [isModalCOn, setIsModalCOn] = useState(false);
  const [isModalDOn, setIsModalDOn] = useState(false);
  const [isModalEOn, setIsModalEOn] = useState(false);
  const [isModalFOn, setIsModalFOn] = useState(false);
  const [isModalHOn, setIsModalHOn] = useState(false);
  const [isModalLOn, setIsModalLOn] = useState(false);
  const [isModalMOn, setIsModalMOn] = useState(false);
  const [isModalNOn, setIsModalNOn] = useState(false);
  const refModalA = useRef(null);
  const refModalE = useRef(null);
  const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';

  return (
    <div>
      <Card
        hovered
        bordered={false}
      >
        <Button
          size="sm"
          className="mr-2 mb-2"
          onClick={() => {
            const nextIsModalAOn = !isModalAOn;
            setIsModalAOn(nextIsModalAOn);

            if (nextIsModalAOn) {
              refModalA.current.firstChild.focus();
            }
          }}
        >
          Small Modal
        </Button>

        <span ref={refModalA}>
          <Modal
            active={isModalAOn}
            onKeyDown={(event) => {
              if (event.key === 'Escape' && isModalAOn) {
                setIsModalAOn(false);
              }
            }}
          >
            <Modal.Backdrop>
              <Modal.Content size="sm">
                <Modal.Header>
                  <h3 className="m-0">Small Modal</h3>
                </Modal.Header>

                <Modal.Body>
                  {dummyText}
                </Modal.Body>

                <Modal.Footer>
                  <ButtonGroup align="right">
                    <Button
                      type="text"
                      onClick={() => setIsModalAOn(!isModalAOn)}
                    >
                      Cancel
                    </Button>

                    <Button onClick={() => setIsModalAOn(!isModalAOn)}>
                      OK
                    </Button>
                  </ButtonGroup>
                </Modal.Footer>
              </Modal.Content>
            </Modal.Backdrop>
          </Modal>
        </span>

        <Button
          type="primary"
          className="mr-2 mb-2"
          onClick={() => setIsModalBOn(!isModalBOn)}
        >
          Medium Modal
        </Button>

        <Modal active={isModalBOn}>
          <Modal.Backdrop>
            <Modal.Content size="md">
              <Modal.Header>
                <h3 className="m-0">Medium Modal</h3>

                <Button
                  type="icon"
                  onClick={() => setIsModalBOn(!isModalBOn)}
                >
                  <Icon id="ion-close" />
                </Button>
              </Modal.Header>

              <Modal.Body>
                {dummyText}
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalBOn(!isModalBOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalBOn(!isModalBOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          type="warning"
          className="mr-2 mb-2"
          rounded
          onClick={() => setIsModalCOn(!isModalCOn)}
        >
          Large Modal
        </Button>

        <Modal active={isModalCOn}>
          <Modal.Backdrop>
            <Modal.Content size="lg">
              <Modal.Header>
                <h3 className="m-0">Large Modal</h3>

                <Button
                  type="icon"
                  onClick={() => setIsModalCOn(!isModalCOn)}
                >
                  <Icon id="ion-close" />
                </Button>
              </Modal.Header>

              <Modal.Body>
                {dummyText}
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalCOn(!isModalCOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalCOn(!isModalCOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          type="success"
          className="mr-2 mb-2"
          outlined
          onClick={() => setIsModalDOn(!isModalDOn)}
        >
          Extra Large Modal
        </Button>

        <Modal active={isModalDOn}>
          <Modal.Backdrop>
            <Modal.Content size="xl">
              <Modal.Header>
                <h3 className="m-0">Extra Large Modal</h3>

                <Button
                  type="icon"
                  onClick={() => setIsModalDOn(!isModalDOn)}
                >
                  <Icon id="ion-close" />
                </Button>
              </Modal.Header>

              <Modal.Body>
                {dummyText}
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalDOn(!isModalDOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalDOn(!isModalDOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          type="danger"
          size="lg"
          className="mr-2 mb-2"
          outlined
          onClick={() => {
            const nextIsModalEOn = !isModalEOn;
            setIsModalEOn(nextIsModalEOn);

            if (nextIsModalEOn) {
              refModalE.current.firstChild.focus();
            }
          }}
        >
          Fullscreen Modal
        </Button>

        <span ref={refModalE}>
          <Modal
            active={isModalEOn}
            onKeyDown={(event) => {
              if (event.key === 'Escape' && isModalEOn) {
                setIsModalEOn(false);
              }
            }}
          >
            <Modal.Backdrop>
              <Modal.Content size="fullscreen">
                <Modal.Header>
                  <h3 className="m-0">Fullscreen Modal</h3>

                  <Button
                    type="icon"
                    onClick={() => setIsModalEOn(!isModalEOn)}
                  >
                    <Icon id="ion-close" />
                  </Button>
                </Modal.Header>

                <Modal.Body className="flex-grow-1">
                  Hey there
                </Modal.Body>

                <Modal.Footer>
                  <ButtonGroup align="right">
                    <Button
                      type="text"
                      onClick={() => setIsModalEOn(!isModalEOn)}
                    >
                      Cancel
                    </Button>

                    <Button onClick={() => setIsModalEOn(!isModalEOn)}>
                      OK
                    </Button>
                  </ButtonGroup>
                </Modal.Footer>
              </Modal.Content>
            </Modal.Backdrop>
          </Modal>
        </span>

        <Button
          type="text"
          className="mr-2 mb-2"
          onClick={() => setIsModalFOn(!isModalFOn)}
        >
          Auto width
          <Icon
            id="ion-chatbubble"
            className="ml-2"
          />
        </Button>

        <Modal active={isModalFOn}>
          <Modal.Backdrop>
            <Modal.Content>
              <Modal.Header>
                <h3 className="m-0">Modal with auto width</h3>
              </Modal.Header>

              <Modal.Body>
                Lorem ipsum dolor sit amet.
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalFOn(!isModalFOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalFOn(!isModalFOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          size="lg"
          className="mr-2 mb-2"
          rounded
          outlined
          onClick={() => setIsModalHOn(!isModalHOn)}
        >
          Auto width
        </Button>

        <Modal active={isModalHOn}>
          <Modal.Backdrop>
            <Modal.Content>
              <Modal.Header>
                <h3 className="m-0">Modal with auto width</h3>
              </Modal.Header>

              <Modal.Body>
                {dummyText}
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalHOn(!isModalHOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalHOn(!isModalHOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          className="mr-2 mb-2"
          onClick={() => setIsModalLOn(!isModalLOn)}
        >
          Nested Modals
        </Button>

        <Modal active={isModalLOn}>
          <Modal.Backdrop>
            <Modal.Content size="lg">
              <Modal.Header>
                <h3 className="m-0">Nested Modals</h3>
              </Modal.Header>

              <Modal.Body>
                <p>{dummyText}</p>

                <Button
                  className="mr-2 mb-2"
                  onClick={() => setIsModalMOn(!isModalMOn)}
                >
                  Nested Modal
                </Button>

                <Modal
                  active={isModalMOn}
                  disableScroll={false}
                >
                  <Modal.Backdrop
                    onClick={() => {
                      if (isModalMOn) {
                        setIsModalMOn(false);
                      }
                    }}
                  >
                    <Modal.Content
                      size="sm"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Modal.Header>
                        <h3 className="m-0">Nested Modal</h3>
                      </Modal.Header>

                      <Modal.Body>
                        {dummyText}
                      </Modal.Body>

                      <Modal.Footer>
                        <ButtonGroup align="right">
                          <Button
                            type="text"
                            onClick={() => setIsModalMOn(!isModalMOn)}
                          >
                            Cancel
                          </Button>

                          <Button onClick={() => setIsModalMOn(!isModalMOn)}>
                            OK
                          </Button>
                        </ButtonGroup>
                      </Modal.Footer>
                    </Modal.Content>
                  </Modal.Backdrop>
                </Modal>
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalLOn(!isModalLOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalLOn(!isModalLOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>

        <Button
          type="text"
          className="mr-2 mb-2"
          outlined
          onClick={() => setIsModalNOn(!isModalNOn)}
        >
          Smooth Backdrop
        </Button>

        <Modal
          active={isModalNOn}
          onEscape={() => {
            if (isModalNOn) {
              setIsModalNOn(false);
            }
          }}
        >
          <Modal.Backdrop style={{ transition: smoothTransition }}>
            <Modal.Content size="sm">
              <Modal.Header>
                <h3 className="m-0">Smooth Backdrop</h3>
              </Modal.Header>

              <Modal.Body>
                {dummyText}
              </Modal.Body>

              <Modal.Footer>
                <ButtonGroup align="right">
                  <Button
                    type="text"
                    onClick={() => setIsModalNOn(!isModalNOn)}
                  >
                    Cancel
                  </Button>

                  <Button onClick={() => setIsModalNOn(!isModalNOn)}>
                    OK
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Backdrop>
        </Modal>
      </Card>
    </div>
  );
};

export default Modals;
