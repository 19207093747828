import React, { useState, useRef } from 'react';
import { Card, Button, Table, Modal, Grid } from 'react-fidelity-ui';
import modalApi from '../../../config/components/modalApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const ModalShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const refModal = useRef(null);
  const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Modal</h2>

        <small>
          <span>Composite stateless functional component</span>
          <br />
          <pre>
            {`import { Modal } from 'react-fidelity-ui';
            
const { Backdrop, Content, Header, Body, Footer } = Modal;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          Customizable modal component with five derivative components that come with it -
          {' '}
          <code>Backdrop</code>
          {', '}
          <code>Content</code>
          {', '}
          <code>Header</code>
          {', '}
          <code>Body</code>
          {' and '}
          <code>Footer</code>
          . To create a usable modal you will need at least
          {' '}
          <code>Modal</code>
          {', '}
          <code>Backdrop</code>
          {' and '}
          <code>Content</code>
          .
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsAOn(!isAOn)}>
                Simple Modal
              </Button>

              <Modal active={isAOn}>
                <Modal.Backdrop>
                  <Modal.Content size="md">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsAOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Toggle Modal
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="md">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsBOn(!isBOn)}>
                Smooth Backdrop
              </Button>

              <Modal active={isBOn}>
                <Modal.Backdrop style={{ transition: smoothTransition }}>
                  <Modal.Content size="lg">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsBOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';
...

<Button onClick={toggle}>
  Close on Backdrop Click
</Button>

<Modal active={active}>
  <Modal.Backdrop style={{ transition: smoothTransition }}>
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>

          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsCOn(!isCOn)}>
                Close on Backdrop Click
              </Button>

              <Modal active={isCOn}>
                <Modal.Backdrop
                  onClick={() => {
                    if (isCOn) {
                      setIsCOn(false);
                    }
                  }}
                >
                  <Modal.Content
                    size="lg"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsCOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Close on Backdrop Click
</Button>
            
<Modal active={active}>
  <Modal.Backdrop onClick={deactivate}>
    <Modal.Content 
      size="lg"
      onClick={(event) => event.stopPropagation()}
    >
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button
                onClick={() => {
                  const nextIsDOn = !isDOn;
                  setIsDOn(nextIsDOn);

                  if (nextIsDOn) {
                    refModal.current.firstChild.focus();
                  }
                }}
              >
                Close on Escape
              </Button>

              <div ref={refModal}>
                <Modal
                  active={isDOn}
                  onKeyDown={(event) => {
                    if (event.key === 'Escape' && isDOn) {
                      setIsDOn(false);
                    }
                  }}
                >
                  <Modal.Backdrop>
                    <Modal.Content size="lg">
                      <Modal.Body>
                        <p>{dummyTextShort}</p>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button onClick={() => setIsDOn(false)}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal.Content>
                  </Modal.Backdrop>
                </Modal>
              </div>

              <pre>
                {`import React, { useRef } from 'react';
...

const refModal = useRef(null);
...

<Button
  onClick={() => {
    const nextActive = !active;
    setActive(nextActive);
    
    if (nextActive) {
      refModal.current.firstChild.focus();
    }
  }}
>
  Close on Escape
</Button>
         
<div ref={refModal}>   
  <Modal
    ref={refModal}
    active={active}
    onKeyDown={(event) => {
      if (event.key === 'Escape') {
        deactivate();
      }
    }}
  >
    <Modal.Backdrop>
      <Modal.Content size="lg">
        <Modal.Body>
          <p>Lorem ipsum dolor sit amet...</p>
        </Modal.Body>
                   
        <Modal.Footer> 
          <Button onClick={disable}>Close</Button>
        </Modal.Footer>  
      </Modal.Content>
    </Modal.Backdrop>
  </Modal>
</div>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={modalApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ModalShowcase;
