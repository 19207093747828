import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Card, ButtonGroup, Button, Divider, Icon, Image, Badge } from 'react-fidelity-ui';
import avatar1 from '../../../images/avatar-1.png';
import avatar2 from '../../../images/avatar-2.png';
import avatar3 from '../../../images/avatar-3.png';
import avatar4 from '../../../images/avatar-4.png';

const TopStories = () => {
  const articles = [{
    id: 1,
    title: 'Multitasking Can Make You Less Productive',
    updatedAt: '24 minutes ago',
    image: avatar1,
    likes: 32,
    comments: 24
  }, {
    id: 2,
    title: 'The Ultimate Guide to Learning Anything Faster',
    updatedAt: '43 minutes ago',
    image: avatar2,
    likes: 72,
    comments: 54
  }, {
    id: 3,
    title: 'Why I Love Night Photography',
    updatedAt: '43 minutes ago',
    image: avatar3,
    likes: 12,
    comments: 4
  }, {
    id: 4,
    title: 'These 39 Sites Have Amazing Stock Photos You Can Use For Free',
    updatedAt: '43 minutes ago',
    image: avatar1,
    likes: 122,
    comments: 44
  }];

  return (
    <div className="top-stories grid-box">
      <div className="height-100">
        <Card
          className="grid-cell"
          padded={false}
          hovered
          bordered={false}
        >
          <div className="bg-image tablet-bg">
            <Badge
              className="color--primary mr-1"
              size="md"
              outlined
              fab={false}
              squared={false}
            >
              {'Management'}
            </Badge>
          </div>

          <div className="mt-2 card--padding pt-0">
            <span className="h3">
              {'Why DatChat Will Be Great for B2B Companies'}
            </span>

            <p className="mt-2 mb-5">
              {'I don\'t understand how people don\'t see the obvious, common trend of what happens to popular social networks like...'}
            </p>

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  src={avatar4}
                  size="sm"
                  className="mr-1"
                />

                <span>
                  {'Symbiosys'}
                </span>
              </div>

              <div className="d-flex justify-content-between">
                <div className="mr-2">
                  <Icon id="ion-heart" />{' '}
                  <small>
                    <strong>{86}</strong>
                  </small>
                </div>

                <div>
                  <Icon id="ion-chatbubble-working" />{' '}
                  <small>
                    <strong>{14}</strong>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="d-flex flex-column grid-cell align-items-stretch bg-image lake-bg color--white"
          hovered
          bordered={false}
        >
          <div>
            <Badge
              className="bg--primary color--primary mr-1"
              size="md"
              outlined
              fab={false}
              squared={false}
            >
              {'Management'}
            </Badge>
          </div>

          <div className="mt-10">
            <span className="h3 color--white font-weight">
              {'Millions of Teachers Minting Millionaire Teachers'}
            </span>
          </div>

          <div className="d-flex align-items-center mt-4">
            <Image
              src={avatar1}
              size="sm"
              className="mr-1"
            />
            <span>
              {'John Doe'}
            </span>
          </div>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="d-flex flex-column grid-cell align-items-stretch bg--danger color--white"
          hovered
          bordered={false}
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <Icon id="ion-android-time" />{' '}
              <small>{'31 Jan, 2019'}</small>
            </div>

            <div>
              <Icon id="ion-chatbubble-working" />{' '}
              <small>{12}</small>
            </div>
          </div>

          <span className="h3 color--white font-weight mt-10">
            {'AI is changing the world. For the better.'}
          </span>

          <div className="d-flex justify-content-between">
            <div>
              <Icon id="ion-heart" />{' '}
              <small>
                <strong>{86}</strong>
              </small>
            </div>

            <div>
              <Icon id="ion-chatbubble-working" />{' '}
              <small>
                <strong>{14}</strong>
              </small>
            </div>

            <div>
              <Icon id="ion-clock" />{' '}
              <small>
                <strong>{'2 hours ago'}</strong>
              </small>
            </div>
          </div>
        </Card>
      </div>

      <div className="height-100">
        <Card
          className="grid-cell"
          hovered
          padded={false}
          bordered={false}
        >
          <div className="h5 pt-2 pr-2 pb-1 pl-2 mb-0">
            {'Top Stories'}
          </div>
          <Divider className="mt-0" />

          {articles.map((article, index) => {
            const isLastItem = index === articles.length - 1;
            const dividerClassName = classNames({
              'mb-0': isLastItem
            });

            return (
              <Fragment key={`article-${article.id}`}>
                <div className="article-item">
                  <div className="d-flex justify-content-start align-items-start">
                    {!isLastItem ? (
                      <Image
                        src={article.image}
                        size="sm"
                        className="mr-1"
                      />
                    ) : (
                      <Badge
                        size="sm"
                        className="bg--secondary mr-1"
                        fab
                      >
                        {'JD'}
                      </Badge>
                    )}
                    <span className="h5">
                      {article.title}
                    </span>
                  </div>

                  <div className="d-flex justify-content-start ml-5">
                    <div className="mr-2">
                      <Icon id="ion-heart" />{' '}
                      <small>{article.likes}</small>
                    </div>

                    <div>
                      <Icon id="ion-chatbubble-working" />{' '}
                      <small>{article.comments}</small>
                    </div>
                  </div>
                </div>

                <Divider
                  size="sm"
                  className={dividerClassName}
                />
              </Fragment>
            );
          })}

          <ButtonGroup
            align="center"
            className="mt-0 pr-1 pl-1"
          >
            <Button
              type="text"
              expanded
            >
              {'See All Stories'}
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    </div>
  );
};

export default TopStories;
