export default {
  name: 'Fidelity UI',
  slogan: 'High Fidelity UI Pack for React',
  description: 'Equipped with live theming capabilities like changing color on the fly',
  creator: 'Neven Dyulgerov',
  creatorLink: 'https://github.com/nevendyulgerov',
  githubLink: 'https://github.com/nevendyulgerov/react-fidelity-ui',
  npmLink: 'https://www.npmjs.com/package/react-fidelity-ui',
  googleAnalyticsKey: 'UA-153069812-1'
};
