import React, { useState } from 'react';
import { InputGroup, Card, Email,Icon, Button } from 'react-fidelity-ui';

const CreateNewPassword = () => {
  const [email, setEmail] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Create New Password'}
      </h2>

      <form>
        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            rounded
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <Button
          className="mb-2"
          expanded
          rounded
          onClick={onClick}
        >
          {'Restore Password'}
        </Button>
      </form>
    </Card>
  )
};

export default CreateNewPassword;
