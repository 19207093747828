import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'bordered',
  description: 'applies border',
  type: 'boolean',
  default: 'true'
}, {
  property: 'flat',
  description: 'removes box shadow',
  type: 'boolean',
  default: 'true'
}, {
  property: 'footer',
  description: 'footer slot',
  type: 'node | null',
  default: 'null'
}, {
  property: 'header',
  description: 'header slot',
  type: 'node | null',
  default: 'null'
}, {
  property: 'hovered',
  description: 'adds more prominent box shadow on hover',
  type: 'boolean',
  default: 'false'
}, {
  property: 'rounded',
  description: 'applies large border radius',
  type: 'boolean',
  default: 'true'
}, {
  property: 'size',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('sm')}{' '}
      {renderBadge('lg')}{' '}
      {'or omitted/null for '}
      {renderBadge('md')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'text',
  description: 'adds description',
  type: 'node | string',
  default: null
}];
