import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Card, Grid, Icon } from 'react-fidelity-ui';
import AppLayout from '../../components/AppLayout';

const { Content } = Layout;
const { Row, Col } = Grid;

const NotFound = () => (
  <AppLayout>
    <div className="d-flex justify-content-center align-items-center width-100 height-100">
      <Content align="vcenter">
        <Grid>
          <Row>
            <Col>
              <Card
                hovered
                bordered={false}
                className="text-align--center"
              >
                <div className="d-flex align-items-center justify-content-center color--gray-9 mb-2">
                  <Icon
                    id="ion-android-sad"
                    size={40}
                  />

                  <h2 className="mb-0 ml-1">
                    Oops!
                  </h2>
                </div>

                <p>
                  {'The page you are looking for doesn\'t exist.'}
                </p>

                <p>
                  <Link
                    to="/"
                    type="primary"
                  >
                    Go back to home
                  </Link>
                </p>
              </Card>
            </Col>
          </Row>
        </Grid>
      </Content>
    </div>
  </AppLayout>
);

export default NotFound;
