export default [
  {
    name: 'intro',
    label: 'Introduction'
  },
  {
    name: 'technologies',
    label: 'Technologies'
  },
  {
    name: 'principles',
    label: 'Principles'
  }
];
