import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'aspectRatio',
  description: (
    <span>
      {'applies aspect ratio; can be set to '}
      {renderBadge('4-3')}{' '}
      {renderBadge('16-9')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'contained',
  description: 'applies max width of 100%',
  type: 'boolean',
  default: 'false'
}, {
  property: 'elevated',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies box shadow equal to '}
      {renderBadge('var(--image-shadow)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'expanded',
  description: 'expands the image to 100% of its parent width',
  type: 'boolean',
  default: 'false'
}, {
  property: 'hovered',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'adds more prominent box shadow; equal to '}
      {renderBadge('var(--image-shadow--hover)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'rounded',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies border radius equal to '}
      {renderBadge('var(--image-border-radius)')}{' with default value'}
      {renderBadge('50%')}
    </span>
  ),
  type: 'boolean',
  default: 'true'
}, {
  property: 'size',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('xs')}{' '}
      {renderBadge('sm')}{' '}
      {renderBadge('md')}{' '}
      {renderBadge('lg')}{' '}
      {renderBadge('xl')}{' '}{'; default value of '}
      {renderBadge('null')}{' equals '}
      {renderBadge('var(--image-size)')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'spinner',
  description: 'spinner slot; displayed when in loading state',
  type: 'node | null',
  default: 'null'
}];
