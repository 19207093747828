import React from 'react';
import { Card, CardDivider, Button, ButtonGroup, Table, Grid } from 'react-fidelity-ui';
import buttonGroupApi from '../../../config/components/buttonGroupApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const ButtonGroupShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          ButtonGroup
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {`import { ButtonGroup } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Exposes props for controlling the appearance and position of child buttons.'}
        </p>
      </Card>

      <h2>
        {'Alignment'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <ButtonGroup>
          <Button>
            {'Cancel'}
          </Button>
          <Button>
            {'OK'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
        </pre>

        <CardDivider />

        <ButtonGroup align="center">
          <Button>
            {'Cancel'}
          </Button>
          <Button>
            {'OK'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup align="center"> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
        </pre>

        <CardDivider />

        <ButtonGroup align="right">
          <Button>
            {'Cancel'}
          </Button>
          <Button>
            {'OK'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup align="right"> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
        </pre>
      </Card>

      <h2>
        {'States'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <ButtonGroup rounded>
          <Button>
            {'Cancel'}
          </Button>
          <Button>
            {'OK'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup rounded> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
        </pre>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['sm-12', 'md-6']}>
              <ButtonGroup wrapped>
                <Button>
                  {'Cancel'}
                </Button>
                <Button>
                  {'OK'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<ButtonGroup wrapped> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-6']}>
              <ButtonGroup wrapped={false}>
                <Button>
                  {'Cancel'}
                </Button>
                <Button>
                  {'OK'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<ButtonGroup wrapped={false}> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <ButtonGroup stacked>
          <Button>
            {'Cancel'}
          </Button>
          <Button>
            {'OK'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup stacked> \n <Button>Cancel</Button>\n <Button>OK</Button>\n</ButtonGroup>'}
        </pre>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <ButtonGroup rounded stacked>
          <Button outlined>
            {'Prev'}
          </Button>
          <Button>
            {'Cancel'}
          </Button>
          <Button outlined>
            {'Next'}
          </Button>
        </ButtonGroup>

        <pre>
          {'<ButtonGroup rounded stacked> \n <Button>Cancel</Button>\n <Button outlined>Prev</Button>\n <Button outlined>Next</Button>\n</ButtonGroup>'}
        </pre>

        <CardDivider />

        <Grid>
          <Row className="mb-1">
            <Col widths={['sm-12', 'md-3']}>
              <ButtonGroup stacked>
                <Button expanded>
                  {'Cancel'}
                </Button>
                <Button expanded>
                  {'OK'}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>

        <pre>
          {'<ButtonGroup stacked> \n <Button expanded>Cancel</Button>\n <Button expanded>OK</Button>\n</ButtonGroup>'}
        </pre>

        <CardDivider />

        <Grid>
          <Row className="mb-1">
            <Col widths={['sm-12', 'md-3']}>
              <ButtonGroup stacked rounded>
                <Button expanded>
                  {'Prev'}
                </Button>
                <Button expanded outlined>
                  {'Cancel'}
                </Button>
                <Button expanded>
                  {'Next'}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>

        <pre>
          {'<ButtonGroup stacked rounded> \n <Button expanded>Prev</Button>\n <Button expanded outlined>Cancel</Button> \n <Button expanded>Next</Button>\n</ButtonGroup>'}
        </pre>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={buttonGroupApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ButtonGroupShowcase;
