import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'bordered',
  description: 'adds border',
  type: 'boolean',
  default: 'true'
}, {
  property: 'elevated',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies box shadow equal to '}
      {renderBadge('var(--notification-box-shadow)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'hovered',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'adds more prominent box shadow on hover; equal to '}
      {renderBadge('var(--notification-shadow--hover)')}
    </span>
  ),
  type: 'boolean',
  default: 'false'
}, {
  property: 'icon',
  description: 'icon slot',
  type: 'node | null',
  default: 'null'
}, {
  property: 'prependIcon',
  description: 'prepends icon after children',
  type: 'boolean',
  default: 'true'
}, {
  property: 'rounded',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'applies border radius equal to '}
      {renderBadge('var(--notification-border-radius)')}
    </span>
  ),
  type: 'boolean',
  default: 'true'
}, {
  property: 'type',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('primary')}{' '}
      {renderBadge('secondary')}{' '}
      {renderBadge('info')}{' '}
      {renderBadge('warning')}{' '}
      {renderBadge('success')}{' '}
      {renderBadge('danger')}
    </span>
  ),
  type: 'string',
  default: 'info'
}];
