import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardDivider, Button, Icon, Input, InputGroup, utils } from 'react-fidelity-ui';
import { AppContext } from '../../context';

const { scrollTo } = window;
let localTheme = {};
const getTheme = () => new Promise(resolve => resolve(localTheme));
const updateTheme = theme => new Promise(resolve => {
  localTheme = theme;
  resolve();
});

const LiveTheming = () => {
  const { state } = useContext(AppContext);
  const [hasUpdatedTheme, setHasUpdatedTheme] = useState(false);
  const [theme, setTheme] = useState({
    '--color-secondary': ''
  });

  const onChangeThemeVar = (variable, value) => {
    const nextTheme = {
      ...theme,
      [variable]: value
    };

    setTheme(nextTheme);
  };

  const onUpdateTheme = () => {
    // update the theme object
    updateTheme(theme);
    setHasUpdatedTheme(true);

    // globally set css variables
    Object.keys(theme).forEach(
      variable => utils.setCssVar(variable, theme[variable])
    );
  };

  // run once on mount
  useEffect(() => {
    scrollTo(0, 0);

    // set default value
    setTheme(theme => ({
      ...theme,
      '--color-secondary': utils.getCssVar('--color-secondary')
    }));

    // get the saved theme object
    getTheme().then(savedTheme => {
      // get all stored theme variables
      const themeVariables = Object.keys(savedTheme);

      // if no variables are stored, end the execution
      if (themeVariables.length === 0) {
        return false;
      }

      setTheme(theme => ({
        ...theme,
        ...savedTheme
      }));

      setHasUpdatedTheme(true);

      // update each theme variable with its stored value
      return themeVariables.forEach(
        variable => utils.setCssVar(variable, savedTheme[variable])
      );
    });
  }, []);

  useEffect(() => () => {
    if (hasUpdatedTheme) {
      utils.setCssVar('--color-secondary', state.defaultSecondaryColor);
      utils.setCssVar('--color-secondary--light', state.defaultSecondaryColorLight);
      utils.setCssVar('--color-secondary--dark', state.defaultSecondaryColorDark);
    }
  }, [hasUpdatedTheme]);

  return (
    <div>
      <h1>Live Theming</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex">
          <Icon
            id="ion-erlenmeyer-flask"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Styles for Fidelity UI components can be customized runtime, using javascript.</strong>
            <br />
            This feature is achieved with the
            {' '}
            <a href="https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleDeclaration" target="_blank" rel="noopener noreferrer">
              CSSStyleDeclaration interface
            </a>
            {' '}
            and
            {' '}
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties" target="_blank" rel="noopener noreferrer">
              custom css variables
            </a>
            .
          </p>
        </div>
      </Card>

      <h2>Runtime Customizations</h2>
      <Card
        hovered
        bordered={false}
      >
        <p>
          <strong>Any custom css variable defined by Fidelity UI can be edited runtime</strong>
          .
          <br />
          This can be done using Fidelity UI
          {' '}
          <code>utils</code>
          {' '}
          object and its
          {' '}
          <code>getCssVar</code>
          {' '}
          and
          {' '}
          <code>setCssVar</code>
          {' '}
          methods. Under the hood, those methods are synchronous and they use the
          {' '}
          <a href="https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleDeclaration" target="_blank" rel="noopener noreferrer">
            CSSStyleDeclaration interface
          </a>
          .
        </p>

        <p className="mb-0">
          To get the value of a custom css variable, like
          {' '}
          <code>--color-secondary</code>
          {', '}
          <code>{'getCssVar(\'--color-secondary\')'}</code>
          {' '}
          is used. To set the value of that variable to
          {' '}
          <code>{'\'blue\''}</code>
          {', '}
          <code>{'setCssVar(\'--color-secondary\', \'blue\')'}</code>
          {' '}
          is used.
        </p>

        <CardDivider />

        <p className="mb-0">
          <strong>
            With the help of
            {' '}
            <code>getCssVar</code>
            {' '}
            and
            {' '}
            <code>setCssVar</code>
            {' '}
            adding theming capabilities to your app becomes trivial
          </strong>
          .
          <br />
          All you need is a UI exposing the desired css variables for edit to your users and some store to keep the updated values.
        </p>

        <CardDivider />

        <p className="mb-0">
          <strong>
            {'Here\'s an example of how to implement live theming for'}
            <code>--color-secondary</code>
          </strong>
          :
          <br />
        </p>

        <pre className="mt-1 mb-1">
          {`import React, { useState, useEffect } from 'react';
import { Input, InputGroup, utils } from 'react-fidelity-ui';

// create local theme store
let localTheme = {};
const getTheme = () => new Promise(resolve => resolve(localTheme));
const updateTheme = theme => new Promise(resolve => {
  localTheme = theme;
  resolve();
});

const App = () => {
  const [theme, setTheme] = useState({
    '--color-secondary': ''
  });

  const onChangeThemeVar = (variable, value) => {
    const nextTheme = {
      ...theme,
      [variable]: value
    };

    setTheme(nextTheme);
  };

  const onUpdateTheme = () => {
    // update the theme object
    updateTheme(theme);

    // globally set css variables
    Object.keys(theme).forEach(
      variable => utils.setCssVar(variable, theme[variable])
    );
  };

  // run once on mount
  useEffect(() => {
    // set default value
    setTheme({
      ...theme,
      '--color-secondary': utils.getCssVar('--color-secondary')
    });
    
    // get the saved theme object
    getTheme().then(savedTheme => {
      // get all stored theme variables
      const themeVariables = Object.keys(savedTheme);

      // if no variables are stored, end the execution
      if (themeVariables.length === 0) {
        return false;
      }
      
      // update theme with saved variables
      setTheme({
        ...theme,
        ...savedTheme
      });

      // update each theme variable with its stored value
      return themeVariables.forEach(
        variable => utils.setCssVar(variable, theme[variable])
      );
    });
  }, []);
  
  return (
    <>
      <InputGroup>
        <Input
          value={theme['--color-secondary']}
          label="Secondary color"
          onChange={({ target }) => {
            onChangeThemeVar('--color-secondary', target.value);
          }}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              onUpdateTheme();
            }
          }}
        />
      </InputGroup>

      <Button onClick={onUpdateTheme}>Save</Button>
    </>
  );
};`}
        </pre>

        <small>
          In the above code,
          {' '}
          <code>localTheme</code>
          {', '}
          <code>getTheme</code>
          {' '}
          and
          {' '}
          <code>updateTheme</code>
          {' '}
          should be replaced with methods from your front or backend API.
        </small>
        <br />

        <small>
          A similar code is used for the change color feature, available on the
          {' '}
          <Link to="/">homepage</Link>
          .
        </small>
        <br />

        <CardDivider />

        <p>The resulting UI from the code above is:</p>

        <div className="mb-2">
          <InputGroup>
            <Input
              value={theme['--color-secondary']}
              label="Secondary color"
              onChange={({ target }) => {
                onChangeThemeVar('--color-secondary', target.value);
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  onUpdateTheme();
                }
              }}
            />
          </InputGroup>

          <Button onClick={onUpdateTheme}>Save</Button>
        </div>

        <p className="mb-0">
          This UI changes the
          {' '}
          <code>--color-secondary</code>
          {' '}
          css variable globally for the entire app. However, when you navigate away from this page, the default value for
          {' '}
          <code>--color-secondary</code>
          {' '}
          will be set back. Similarly, when you revisit this page, the updated color will be used. This way the customized
          {' '}
          <code>--color-secondary</code>
          {' '}
          is applicable only to this page, which demonstrates how localized theming works.
        </p>
      </Card>
    </div>
  );
};

export default LiveTheming;
