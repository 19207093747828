import React, { useState } from 'react';
import { Card, Button, Table, Modal, Grid } from 'react-fidelity-ui';
import modalContentApi from '../../../config/components/modalContentApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';
import dummyText from '../../../mock/dummyText';

const { Row, Col } = Grid;

const ModalContentShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const [isEOn, setIsEOn] = useState(false);
  const [isFOn, setIsFOn] = useState(false);
  const [isGOn, setIsGOn] = useState(false);
  const [isHOn, setIsHOn] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Content</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Modal } from 'react-fidelity-ui';
            
const { Content } = Modal;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          A modal can have content.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Sizes</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsAOn(!isAOn)}>
                Auto
              </Button>

              <Modal active={isAOn}>
                <Modal.Backdrop>
                  <Modal.Content size="auto">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsAOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Auto
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="auto">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsBOn(!isBOn)}>
                Extra Small
              </Button>

              <Modal active={isBOn}>
                <Modal.Backdrop>
                  <Modal.Content size="xs">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsBOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Extra Small
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="xs">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsCOn(!isCOn)}>
                Small
              </Button>

              <Modal active={isCOn}>
                <Modal.Backdrop>
                  <Modal.Content size="sm">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsCOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Small
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="sm">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsDOn(!isDOn)}>
                Medium
              </Button>

              <Modal active={isDOn}>
                <Modal.Backdrop>
                  <Modal.Content size="md">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsDOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Medium
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="sm">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsEOn(!isEOn)}>
                Large
              </Button>

              <Modal active={isEOn}>
                <Modal.Backdrop>
                  <Modal.Content size="lg">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsEOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Large
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsFOn(!isFOn)}>
                Extra Large
              </Button>

              <Modal active={isFOn}>
                <Modal.Backdrop>
                  <Modal.Content size="xl">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsFOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Extra Large
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="xl">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsGOn(!isGOn)}>
                Fullscreen
              </Button>

              <Modal active={isGOn}>
                <Modal.Backdrop>
                  <Modal.Content size="fullscreen">
                    <Modal.Header>
                      <h3>Fullscreen</h3>
                    </Modal.Header>

                    <Modal.Body className="flex-grow-1">
                      <p>{dummyText}</p>
                      <p>{dummyText}</p>
                      <p>{dummyText}</p>
                      <p>{dummyText}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsGOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Fullscreen
</Button>
            
<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="fullscreen">
      <Modal.Header>
        <h3>Fullscreen</h3>
      </Modal.Header>
                    
      <Modal.Body className="flex-grow-1">
        <p>Lorem ipsum dolor sit amet...</p>
        <p>Lorem ipsum dolor sit amet...</p>
        <p>Lorem ipsum dolor sit amet...</p>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>
                 
      <Modal.Footer> 
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>  
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      {/*
      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsAOn(!isAOn)}>
                Extra Small
              </Button>

              <Modal active={isAOn}>
                <Modal.Backdrop>
                  <Modal.Content size="md">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsAOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`<Button onClick={toggle}>
  Extra Small
</Button>

<Modal active={active}>
  <Modal.Backdrop>
    <Modal.Content size="xs">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Button onClick={() => setIsBOn(!isBOn)}>
                Smooth Backdrop
              </Button>

              <Modal active={isBOn}>
                <Modal.Backdrop style={{ transition: smoothTransition }}>
                  <Modal.Content size="lg">
                    <Modal.Body>
                      <p>{dummyTextShort}</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setIsBOn(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Backdrop>
              </Modal>

              <pre>
                {`const smoothTransition = 'opacity cubic-bezier(0.55, 0, 0.1, 1) 0.5s';
...

<Button onClick={toggle}>
  Smooth Backdrop
</Button>

<Modal active={active}>
  <Modal.Backdrop style={{ transition: smoothTransition }}>
    <Modal.Content size="lg">
      <Modal.Body>
        <p>Lorem ipsum dolor sit amet...</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={disable}>Close</Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal.Backdrop>
</Modal>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>
      */}

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={modalContentApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ModalContentShowcase;
