import React from 'react';
import Feed from './Feed';
import PopularStories from './PopularStories';
import TopStories from './TopStories';
import HeroPost from './HeroPost';
import Categories from './Categories';

const ArticlesDemo = () => {
  return (
    <div>
      <div className="mb-3">
        <h2>
          {'Latest Posts'}
        </h2>

        <Feed />
      </div>

      <PopularStories />

      <div className="mb-3">
        <h2>
          {'Top Stories'}
        </h2>

        <TopStories />
      </div>

      <div className="mb-3">
        <h2>
          {'Hero Post'}
        </h2>

        <HeroPost />
      </div>

      <div className="mb-3">
        <h2>
          {'Categories'}
        </h2>

        <Categories />
      </div>
    </div>
  );
};

export default ArticlesDemo;
