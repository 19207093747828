import React from 'react';
import { Card, Table, Grid, Progress } from 'react-fidelity-ui';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const GridShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Grid
        </h2>

        <small>
          <span>
            Composite stateless functional component
          </span>
          <br />
          <pre>
            {`import { Grid } from 'react-fidelity-ui';

const { Row, Col } = Grid;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          Standard grid component based on <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/flex" rel="noopener noreferre">flex-box</a>. It exposes two derivative components - <code>Row</code> and <code>Col</code>. Of those three components only <code>Col</code> exposes props for positioning.
        </p>
      </Card>

      <h2>Examples</h2>

      <Grid>
        <Row>
          <Col widths={['sm-12', 'md-4', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col A
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col B
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col C
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col D
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col E
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-6', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col F
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col H
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col I
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-6', 'lg-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col H
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col I
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col J
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              Col K
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              Col L
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col M
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col N
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col O
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col P
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col Q
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col R
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col S
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col T
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col U
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-8']}>
            <Card
              hovered
              bordered={false}
            >
              Col V
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col W
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col X
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              Col Y
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col Z
            </Card>
          </Col>
        </Row>
      </Grid>

      <Card
        hovered
        bordered={false}
      >
        <pre>
          {`<Grid>
  <Row>
    <Col widths={['sm-12', 'md-4', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col A
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col B
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col C
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col D
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col E
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-6', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col F
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col H
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col I
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-6', 'lg-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col H
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col I
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col J
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6']}>
      <Card
        hovered
        bordered={false}
      >
        Col K
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6']}>
      <Card
        hovered
        bordered={false}
      >
        Col L
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col M
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col N
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col O
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col P
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col Q
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col R
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col S
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col T
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col U
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-8']}>
      <Card
        hovered
        bordered={false}
      >
        Col V
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col W
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col X
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6']}>
      <Card
        hovered
        bordered={false}
      >
        Col Y
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col Z
      </Card>
    </Col>
  </Row>
</Grid>`}
        </pre>
      </Card>
    </div>
  );
};

export default GridShowcase;
