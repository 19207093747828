import React from 'react';
import { Card, Table, Grid } from 'react-fidelity-ui';
import colApi from '../../../config/components/colApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const ColShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Col
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <pre>
            {`import { Grid } from 'react-fidelity-ui';

const { Col } = Grid;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component is part of the <code>Grid</code> composite component. <code>Col</code> is always wrapped by a <code>Row</code> and it exposes props for positioning.
        </p>
      </Card>

      <h2>Widths</h2>

      <Grid>
        <Row>
          <Col widths={['sm-12', 'md-4', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col A
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col B
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-4']}>
            <Card
              hovered
              bordered={false}
            >
              Col C
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col D
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col E
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-6', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col F
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col H
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col I
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>
        </Row>
      </Grid>

      <Card
        hovered
        bordered={false}
      >
        <pre>
          {`<Grid>
  <Row>
    <Col widths={['sm-12', 'md-4', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col A
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col B
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-4']}>
      <Card
        hovered
        bordered={false}
      >
        Col C
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col D
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col E
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-6', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col F
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col H
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col I
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>
  </Row>
</Grid>`}
        </pre>
      </Card>

      <h2>Orders</h2>

      <Grid>
        <Row>
          <Col
            widths={['sm-12', 'md-4', 'lg-2']}
            orders={[2]}
          >
            <Card
              hovered
              bordered={false}
            >
              Col A - Order 2
            </Card>
          </Col>

          <Col
            widths={['sm-12', 'md-4', 'lg-2']}
            orders={[0]}
          >
            <Card
              hovered
              bordered={false}
            >
              Col B - Order 0
            </Card>
          </Col>

          <Col
            widths={['sm-12', 'md-4']}
            orders={[1]}
          >
            <Card
              hovered
              bordered={false}
            >
              Col C - Order 1
            </Card>
          </Col>

          <Col
            widths={['sm-12', 'md-6', 'lg-2']}
            orders={[3]}
          >
            <Card
              hovered
              bordered={false}
            >
              Col D - Order 3
            </Card>
          </Col>

          <Col
            widths={['sm-12', 'md-6', 'lg-2']}
            orders={[4]}
          >
            <Card
              hovered
              bordered={false}
            >
              Col E - Order 4
            </Card>
          </Col>
        </Row>

        <Row>
          <Col widths={['sm-12', 'md-6', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col F
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col H
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6', 'lg-2']}>
            <Card
              hovered
              bordered={false}
            >
              Col I
            </Card>
          </Col>

          <Col widths={['sm-12', 'lg-3']}>
            <Card
              hovered
              bordered={false}
            >
              Col G
            </Card>
          </Col>
        </Row>
      </Grid>

      <Card
        hovered
        bordered={false}
      >
        <pre>
          {`<Grid className="mb-6">
  <Row>
    <Col
      widths={['sm-12', 'md-4', 'lg-2']}
      orders={[2]}
    >
      <Card
        hovered
        bordered={false}
      >
        Col A - Order 2
      </Card>
    </Col>

    <Col
      widths={['sm-12', 'md-4', 'lg-2']}
      orders={[0]}
    >
      <Card
        hovered
        bordered={false}
      >
        Col B - Order 0
      </Card>
    </Col>

    <Col
      widths={['sm-12', 'md-4']}
      orders={[1]}
    >
      <Card
        hovered
        bordered={false}
      >
        Col C - Order 1
      </Card>
    </Col>

    <Col
      widths={['sm-12', 'md-6', 'lg-2']}
      orders={[3]}
    >
      <Card
        hovered
        bordered={false}
      >
        Col D - Order 3
      </Card>
    </Col>

    <Col
      widths={['sm-12', 'md-6', 'lg-2']}
      orders={[4]}
    >
      <Card
        hovered
        bordered={false}
      >
        Col E - Order 4
      </Card>
    </Col>
  </Row>

  <Row>
    <Col widths={['sm-12', 'md-6', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col F
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col H
      </Card>
    </Col>

    <Col widths={['sm-12', 'md-6', 'lg-2']}>
      <Card
        hovered
        bordered={false}
      >
        Col I
      </Card>
    </Col>

    <Col widths={['sm-12', 'lg-3']}>
      <Card
        hovered
        bordered={false}
      >
        Col G
      </Card>
    </Col>
  </Row>
</Grid>`}
        </pre>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={colApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ColShowcase;
