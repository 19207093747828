import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'react-fidelity-ui';

const { scrollTo } = window;

const Introduction = () => {
  useEffect(() => {
    scrollTo(0, 0);
  });

  return (
    <div>
      <h1>Introduction</h1>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex align-items-center">
          <Icon
            id="ion-grid"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI is a collection of versatile React driven components.</strong>
            <br />
            Those components can be used as base UI blocks for creating
            {' '}
            <Link to="/assets">a wide array of front-ends</Link>
            .
          </p>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex align-items-center">
          <Icon
            id="ion-flash"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI components are performant, predictable and customizable.</strong>
            <br />
            They are powered by
            {' '}
            <a href="https://reactjs.org/docs/hooks-intro.html" target="_blank" rel="noopener noreferrer">
              React Hooks
            </a>
            {', '}
            <a href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">
              TypeScript
            </a>
            {' '}
            and latest CSS features like
            {' '}
            <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties" target="_blank" rel="noopener noreferrer">
              custom properties
            </a>
            .
          </p>
        </div>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <div className="d-flex align-items-center">
          <Icon
            id="ion-ios-analytics"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">
            <strong>Fidelity UI is agnostic in terms of design specifications.</strong>
            <br />
            This library works well with
            {' '}
            <a href="https://en.wikipedia.org/wiki/Flat_design" target="_blank" rel="noopener noreferrer">
              flat
            </a>
            {', '}
            <a href="https://en.wikipedia.org/wiki/Material_Design" target="_blank" rel="noopener noreferrer">
              material
            </a>
            {' '}
            or mixed design systems.
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Introduction;
