import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'bordered',
  description: 'adds border',
  type: 'boolean',
  default: 'true'
}, {
  property: 'flat',
  description: 'displays card without box shadow',
  type: 'boolean',
  default: 'false'
}, {
  property: 'hovered',
  description: 'adds more prominent box shadow on hover',
  type: 'boolean',
  default: 'false'
}, {
  property: 'loading',
  description: 'adds loading state',
  type: 'boolean',
  default: 'false'
}, {
  property: 'padded',
  description: 'adds content padding',
  type: 'boolean',
  default: 'false'
}, {
  property: 'paddingSize',
  description: (
    <span>
      {'can be set to '}
      {renderBadge('sm')}{' '}
      {renderBadge('lg')}{' '}
      {' or null/omitted for '}
      {renderBadge('md')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}, {
  property: 'rounded',
  description: 'applies border radius',
  type: 'boolean',
  default: 'true'
}];
