import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, Grid, Image, Empty, Icon, CardDivider, Badge, Button, ButtonGroup } from 'react-fidelity-ui';
import imageApi from '../../../config/components/imageApi';
import apiColumns from '../../../config/apiColumns';
import johnDoeAvatar from '../../../images/avatar-1.png';
import janeDoeAvatar from '../../../images/avatar-5.png';

const { Row, Col } = Grid;

const ImageShowcase = () => {
  const [isReloadingA, setIsReloadingA] = useState(false);
  const [isReloadingB, setIsReloadingB] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Image
        </h2>

        <small>
          <span>
            Stateful functional component
          </span>
          <br />
          <code>
            {'import { Image } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          Supports all native <code>img</code> attributes and adds several unique ones to augment image functionality. <code>Image</code> handles its loading state internally. This component also offers an intuitive way to handle errors by returning a React node from the native <code>onError</code> callback.
        </p>

        <CardDivider />

        <small>
          Some of the examples below use components <Link to="/components/icon"><code>Icon</code></Link> and <Link to="/components/empty"><code>Empty</code></Link>.
        </small><br />

        <small>
          Some of the examples below also use <code>{'import johnDoeAvatar from \'./images/avatar-2.png\''}</code> and <code>{'import janeDoeAvatar from \'./images/avatar-2.png\''}</code>. In case you want to copy-paste an example, make sure to replace that related image file with one from your project directory.
        </small>
      </Card>

      <h2>
        {'Sizes'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['sm-12', 'md-4']}
              className="mb-2"
            >
              <Image src={johnDoeAvatar} size="xs" />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="xs" \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-4']}
              className="mb-2"
            >
              <Image src={johnDoeAvatar} size="sm" />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="sm" \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-4']}
              className="mb-2"
            >
              <Image src={johnDoeAvatar} size="md" />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="md" \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image src={johnDoeAvatar} size="lg" />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="lg" \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image src={johnDoeAvatar} size="xl" />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="xl" \n/>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Aspect Ratios'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src={johnDoeAvatar}
                size="xl"
                aspectRatio="4-3"
                rounded={false}
              />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="xl" \n aspectRatio="4-3" \n rounded={false} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src={johnDoeAvatar}
                size="md"
                aspectRatio="16-9"
                rounded={false}
              />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n size="md" \n aspectRatio="16-9" \n rounded={false} \n/>'}
              </pre>
            </Col>
          </Row>

          <Row>
            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src={janeDoeAvatar}
                size="xl"
                aspectRatio="4-3"
                rounded={false}
              />

              <pre>
                {'<Image \n src={janeDoeAvatar} \n size="xl" \n aspectRatio="4-3" \n rounded={false} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src={janeDoeAvatar}
                size="lg"
                aspectRatio="16-9"
                rounded={false}
              />

              <pre>
                {'<Image \n src={janeDoeAvatar} \n size="lg" \n aspectRatio="16-9" \n rounded={false} \n/>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Fallbacks & Errors'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                onError={() => (
                  <Image src={janeDoeAvatar} size="lg" />
                )}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n onError={() => (\n  <Image src={janeDoeAvatar} size="lg" />\n )} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                onError={() => (
                  <Badge
                    size="lg"
                    className="bg--gray-2"
                  >
                    JD
                  </Badge>
                )}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n onError={() => (\n  <Badge \n   size="lg" \n   className="bg--gray-2" \n  >\n   JD \n  </Badge> \n )} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                alt="avatar"
                rounded={false}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n alt="avatar" \n rounded={false} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                alt="post-thumbnail"
                onError={() => (
                  <Empty
                    header={<Icon id="ion-image" size={44} />}
                    text="Avatar"
                    style={{ minHeight: '100%' }}
                  />
                )}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n onError={() => (\n  <Empty \n   header={<Icon id="ion-image" size={44} /> \n   text="Avatar" \n   style={{ minHeight: \'100%\' }}\n  />\n )} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="xl"
                aspectRatio="4-3"
                alt="avatar"
                rounded={false}
                onError={() => <Empty text="Avatar" />}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="xl" \n aspectRatio="4-3" \n alt="avatar" \n rounded={false} \n onError={() => <Empty text="Avatar" />} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                alt="post-thumbnail"
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n alt="post-thumbnail" \n/>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <div className="d-flex justify-content-between">
                <div style={{ minHeight: 'var(--image-size--lg)' }}>
                  {!isReloadingA && (
                    <Image
                      src="https://cdn.spacetelescope.org/archives/images/publicationjpg/heic1509a.jpg"
                      size="lg"
                      alt="hubble-image"
                      spinner={<Icon id="ion-load-c" size={64} spinning />}
                    />
                  )}
                </div>

                <ButtonGroup>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setIsReloadingA(true);
                      setTimeout(() => setIsReloadingA(false));
                    }}
                  >
                    Toggle loading
                  </Button>
                </ButtonGroup>
              </div>

              <pre>
                {'<Image \n src="https://cdn.spacetelescope.org/archives/images/publicationjpg/heic1509a.jpg" \n size="lg" \n alt="hubble-image" \n spinner={<Icon id="ion-load-c" size={64} spinning />} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                onError={() => (
                  <Image
                    src={janeDoeAvatar}
                    size="lg"
                    elevated
                    hovered
                  />
                )}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n onError={() => (\n  <Image \n   src={janeDoeAvatar} \n   size="lg" \n   elevated \n   hovered \n  />\n )} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src="/invalid-image"
                size="lg"
                onError={() => (
                  <Badge
                    type="info"
                    size="lg"
                    style={{ width: '100%', height: '100%' }}
                    rounded
                  >
                    Jane
                  </Badge>
                )}
              />

              <pre>
                {'<Image \n src="/invalid-image" \n size="lg" \n onError={() => (\n  <Badge \n   size="lg" \n   style={{ width: \'100%\', height: \'100%\' }} \n   rounded  \n  >\n   Jane \n  </Badge> \n )} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-2']}
              className="mb-2"
            >
              <Image
                src={janeDoeAvatar}
                size="xl"
                aspectRatio="16-9"
                contained
                rounded={false}
              />

              <pre>
                {'<Image \n src={janeDoeAvatar} \n size="xl" \n aspectRatio="16-9" \n contained \n rounded={false} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-4']}
              className="mb-2"
            >
              <div>
                <div style={{ minHeight: 'var(--image-size--xl)' }}>
                  {!isReloadingB && (
                    <Image
                      src="https://cdn.spacetelescope.org/archives/images/publicationjpg/opo0501a.jpg"
                      size="xl"
                      alt="spiral-galaxy"
                      expanded
                      rounded={false}
                      spinner={<Icon id="ion-load-a" size={56} spinning />}
                    />
                  )}
                </div>

                <ButtonGroup>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setIsReloadingB(true);
                      setTimeout(() => setIsReloadingB(false));
                    }}
                  >
                    Toggle loading
                  </Button>
                </ButtonGroup>
              </div>

              <pre>
                {'<Image \n src="https://cdn.spacetelescope.org/archives/images/publicationjpg/opo0501a.jpg" \n size="xl" \n alt="spiral-galaxy" \n expanded \n rounded={false} \n spinner={<Icon id="ion-load-c" size={64} spinning />} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-6']}
              className="mb-2"
            >
              <Image
                src={janeDoeAvatar}
                aspectRatio="16-9"
                expanded
                rounded={false}
              />

              <pre>
                {'<Image \n src={janeDoeAvatar} \n aspectRatio="16-9" \n expanded \n rounded={false} \n/>'}
              </pre>
            </Col>

            <Col
              widths={['sm-12', 'md-4']}
              className="mb-2"
            >
              <Image
                src={johnDoeAvatar}
                size="lg"
                aspectRatio="4-3"
                elevated
                hovered
                rounded={false}
              />

              <pre>
                {'<Image \n src={johnDoeAvatar} \n aspectRatio="4-3" \n size="lg" \n elevated \n hovered \n rounded={false} \n/>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={imageApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ImageShowcase;
