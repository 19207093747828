import React from 'react';
import { Card, Notification, Icon } from 'react-fidelity-ui';

const Notifications = () => {
  return (
    <div>
      <Card bordered={false}>
        <Notification
          type="success"
          icon={<Icon id="ion-android-done" size={48} />}
        >
          <span className="h3">Success</span>
          <p className="mb-0">Well done! You successfully read this important alert message.</p>
        </Notification>

        <Notification
          type="info"
          elevated
          hovered
          bordered={false}
        >
          Heads up! This alert needs your attention, but it's not super important.
        </Notification>

        <Notification
          type="warning"
          icon={<Icon id="ion-android-warning" size={48} />}
          hovered
          prependIcon={false}
        >
          <span className="h3">Warning</span>
          <p className="mb-0">Heads up! This alert needs your attention and is somewhat important.</p>
        </Notification>

        <Notification
          type="danger"
          hovered
        >
          Oh snap! Change a few things up and try submitting again.
        </Notification>
      </Card>
    </div>
  );
};

export default Notifications;
