import React from 'react';
import { Grid, Card, utils } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const Colors = () => {
  const colorPrimaryLight = utils.getCssVar('--color-primary--light');
  const colorPrimary = utils.getCssVar('--color-primary');
  const colorPrimaryDark = utils.getCssVar('--color-primary--dark');

  const colorSecondaryLight = utils.getCssVar('--color-secondary--light');
  const colorSecondary = utils.getCssVar('--color-secondary');
  const colorSecondaryDark = utils.getCssVar('--color-secondary--dark');

  const colorSuccessLight = utils.getCssVar('--color-success--light');
  const colorSuccess = utils.getCssVar('--color-success');
  const colorSuccessDark = utils.getCssVar('--color-success--dark');

  const colorDangerLight = utils.getCssVar('--color-danger--light');
  const colorDanger = utils.getCssVar('--color-danger');
  const colorDangerDark = utils.getCssVar('--color-danger--dark');

  const colorWarningLight = utils.getCssVar('--color-warning--light');
  const colorWarning = utils.getCssVar('--color-warning');
  const colorWarningDark = utils.getCssVar('--color-warning--dark');

  const colorInfoLight = utils.getCssVar('--color-info--light');
  const colorInfo = utils.getCssVar('--color-info');
  const colorInfoDark = utils.getCssVar('--color-info--dark');

  return (
    <Grid>
      <Row className="flex-wrap">
        <Col widths={['sm-12', 'md-6']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorPrimaryLight }}
              title={colorPrimaryLight}
            >
              {'Primary Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorPrimary }}
              title={colorPrimary}
            >
              {'Primary'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorPrimaryDark }}
              title={colorPrimaryDark}
            >
              {'Primary Dark'}
            </div>
          </Card>
        </Col>

        <Col widths={['sm-12', 'md-6']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorSecondaryLight }}
            >
              {'Secondary Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorSecondary }}
            >
              {'Secondary'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorSecondaryDark }}
            >
              {'Secondary Dark'}
            </div>
          </Card>
        </Col>

        <Col widths={['sm-12', 'md-3']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorSuccessLight }}
              title={colorSuccessLight}
            >
              {'Success Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorSuccess }}
              title={colorSuccess}
            >
              {'Success'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorSuccessDark }}
              title={colorSuccessDark}
            >
              {'Success Dark'}
            </div>
          </Card>
        </Col>

        <Col widths={['sm-12', 'md-3']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorDangerLight }}
              title={colorDangerLight}
            >
              {'Danger Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorDanger }}
              title={colorDanger}
            >
              {'Danger'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorDangerDark }}
              title={colorDangerDark}
            >
              {'Danger Dark'}
            </div>
          </Card>
        </Col>

        <Col widths={['sm-12', 'md-3']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorWarningLight }}
              title={colorWarningLight}
            >
              {'Warning Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorWarning }}
              title={colorWarning}
            >
              {'Warning'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorWarningDark }}
              title={colorWarningDark}
            >
              {'Warning Dark'}
            </div>
          </Card>
        </Col>

        <Col widths={['sm-12', 'md-3']}>
          <Card
            className="overflow-hidden"
            hovered
            padded={false}
            bordered={false}
          >
            <div
              className="app-color"
              style={{ backgroundColor: colorInfoLight }}
              title={colorInfoLight}
            >
              {'Info Light'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorInfo }}
              title={colorInfo}
            >
              {'Info'}
            </div>
            <div
              className="app-color"
              style={{ backgroundColor: colorInfoDark }}
              title={colorInfoDark}
            >
              {'Info Dark'}
            </div>
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default Colors;
