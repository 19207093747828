import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'orders',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge(0)}{' '}
      {renderBadge(1)}{' '}
      {renderBadge(2)}{' '}
      {renderBadge(3)}{' '}
      {renderBadge(4)}{' '}
      {renderBadge(5)}{' '}
      {renderBadge(6)}{' '}
      {renderBadge(7)}{' '}
      {renderBadge(8)}{' '}
      {renderBadge(9)}{' '}
      {renderBadge(10)}{' '}
      {renderBadge(11)}{' '}
      {renderBadge('sm-0')}{' '}
      {renderBadge('sm-1')}{' '}
      {renderBadge('sm-2')}{' '}
      {renderBadge('sm-3')}{' '}
      {renderBadge('sm-4')}{' '}
      {renderBadge('sm-5')}{' '}
      {renderBadge('sm-6')}{' '}
      {renderBadge('sm-7')}{' '}
      {renderBadge('sm-8')}{' '}
      {renderBadge('sm-9')}{' '}
      {renderBadge('sm-10')}{' '}
      {renderBadge('sm-11')}{' '}
      {renderBadge('md-0')}{' '}
      {renderBadge('md-1')}{' '}
      {renderBadge('md-2')}{' '}
      {renderBadge('md-3')}{' '}
      {renderBadge('md-4')}{' '}
      {renderBadge('md-5')}{' '}
      {renderBadge('md-6')}{' '}
      {renderBadge('md-7')}{' '}
      {renderBadge('md-8')}{' '}
      {renderBadge('md-9')}{' '}
      {renderBadge('md-10')}{' '}
      {renderBadge('md-11')}{' '}
      {renderBadge('lg-0')}{' '}
      {renderBadge('lg-1')}{' '}
      {renderBadge('lg-2')}{' '}
      {renderBadge('lg-3')}{' '}
      {renderBadge('lg-4')}{' '}
      {renderBadge('lg-5')}{' '}
      {renderBadge('lg-6')}{' '}
      {renderBadge('lg-7')}{' '}
      {renderBadge('lg-8')}{' '}
      {renderBadge('lg-9')}{' '}
      {renderBadge('lg-10')}{' '}
      {renderBadge('lg-11')}{' '}
      {renderBadge('xl-0')}{' '}
      {renderBadge('xl-1')}{' '}
      {renderBadge('xl-2')}{' '}
      {renderBadge('xl-3')}{' '}
      {renderBadge('xl-4')}{' '}
      {renderBadge('xl-5')}{' '}
      {renderBadge('xl-6')}{' '}
      {renderBadge('xl-7')}{' '}
      {renderBadge('xl-8')}{' '}
      {renderBadge('xl-9')}{' '}
      {renderBadge('xl-10')}{' '}
      {renderBadge('xl-11')}{' '}
      {'or omitted/null'}
    </span>
  ),
  type: 'string | number | null',
  default: 'null'
}, {
  property: 'widths',
  description: (
    <span style={{ lineHeight: 2.4 }}>
      {'can be set to '}
      {renderBadge('sm-1')}{' '}
      {renderBadge('sm-2')}{' '}
      {renderBadge('sm-3')}{' '}
      {renderBadge('sm-4')}{' '}
      {renderBadge('sm-5')}{' '}
      {renderBadge('sm-6')}{' '}
      {renderBadge('sm-7')}{' '}
      {renderBadge('sm-8')}{' '}
      {renderBadge('sm-9')}{' '}
      {renderBadge('sm-10')}{' '}
      {renderBadge('sm-11')}{' '}
      {renderBadge('sm-12')}{' '}
      {renderBadge('md-1')}{' '}
      {renderBadge('md-2')}{' '}
      {renderBadge('md-3')}{' '}
      {renderBadge('md-4')}{' '}
      {renderBadge('md-5')}{' '}
      {renderBadge('md-6')}{' '}
      {renderBadge('md-7')}{' '}
      {renderBadge('md-8')}{' '}
      {renderBadge('md-9')}{' '}
      {renderBadge('md-10')}{' '}
      {renderBadge('md-11')}{' '}
      {renderBadge('md-12')}{' '}
      {renderBadge('lg-1')}{' '}
      {renderBadge('lg-2')}{' '}
      {renderBadge('lg-3')}{' '}
      {renderBadge('lg-4')}{' '}
      {renderBadge('lg-5')}{' '}
      {renderBadge('lg-6')}{' '}
      {renderBadge('lg-7')}{' '}
      {renderBadge('lg-8')}{' '}
      {renderBadge('lg-9')}{' '}
      {renderBadge('lg-10')}{' '}
      {renderBadge('lg-11')}{' '}
      {renderBadge('lg-12')}{' '}
      {renderBadge('xl-1')}{' '}
      {renderBadge('xl-2')}{' '}
      {renderBadge('xl-3')}{' '}
      {renderBadge('xl-4')}{' '}
      {renderBadge('xl-5')}{' '}
      {renderBadge('xl-6')}{' '}
      {renderBadge('xl-7')}{' '}
      {renderBadge('xl-8')}{' '}
      {renderBadge('xl-9')}{' '}
      {renderBadge('xl-10')}{' '}
      {renderBadge('xl-11')}{' '}
      {renderBadge('xl-12')}{' '}
      {'or omitted/null for '}
      {renderBadge('100% width')}
    </span>
  ),
  type: 'string | null',
  default: 'null'
}];
