import React from 'react';
import { Card, Empty, Table, Grid, Icon, Button } from 'react-fidelity-ui';
import emptyApi from '../../../config/components/emptyApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const EmptyShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'Empty'}
        </h2>

        <small>
          <span>
            {'Stateless functional component'}
          </span>
          <br />
          <code>
            {`import { Empty } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Empty state placeholder used to inform that some content is unavailable.'}
        </p>
      </Card>

      <h2>
        {'Sizes'}
      </h2>

      <Grid>
        <Row className="mb-4">
          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>No results found</h4>}
                size="sm"
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>No results found</h4>} \n size="sm"\n/>'}
              </pre>
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>No results found</h4>}
                size="md"
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>No results found</h4> \n size="md"\n/>'}
              </pre>
            </Card>
          </Col>

          <Col>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>No results found</h4>}
                size="lg"
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>No results found</h4>} \n size="lg"\n/>'}
              </pre>
            </Card>
          </Col>
        </Row>
      </Grid>

      <h2>
        {'States'}
      </h2>

      <Grid>
        <Row>
          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>Rounded</h4>}
                rounded
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>Rounded</h4>} \n rounded\n/>'}
              </pre>
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>Hovered</h4>}
                hovered
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>Hovered</h4>} \n hovered\n/>'}
              </pre>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>Bordered</h4>}
                bordered
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>Bordered</h4>} \n bordered\n/>'}
              </pre>
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>Flat</h4>}
                flat
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>Flat</h4>} \n flat\n/>'}
              </pre>
            </Card>
          </Col>
        </Row>
      </Grid>

      <h2>
        {'Examples'}
      </h2>

      <Grid>
        <Row className="mb-4">
          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h4>No results found</h4>}
                size="sm"
                hovered
                bordered={false}
              />

              <pre className="mt-2">
                {'<Empty \n text={<h4>No results added</h4>} \n size="sm" \n hovered \n bordered={false}\n/>'}
              </pre>
            </Card>

            <Card
              hovered
              bordered={false}
            >
              <Empty
                header={<Icon id="ion-android-sad" size={32} />}
                text={<h4>No users found.</h4>}
                footer={<Button type="link">Go back to search</Button>}
                hovered
                flat={false}
              />

              <pre className="mt-2">
                {'<Empty \n header={<Icon id="ion-android-sad" />} \n text={<h4>No items added, yet.</h4>} \n footer={<Button type="link">Go back to search</Button>} \n hovered \n flat={false}\n/>'}
              </pre>
            </Card>
          </Col>

          <Col widths={['sm-12', 'md-6']}>
            <Card
              hovered
              bordered={false}
            >
              <Empty
                text={<h5>No items added, yet.</h5>}
                footer={<a href="#">Go back</a>}
                flat={false}
              />

              <pre className="mt-2">
                {'<Empty \n text={<h5>No items added, yet.</h5>} \n footer={<a href="#">Go back</a>} \n flat={false}\n/>'}
              </pre>
            </Card>

            <Card
              hovered
              bordered={false}
            >
              <Empty
                header={<Icon id="ion-android-cart" size={32} />}
                text={<h5>Your cart is empty.</h5>}
                footer={<Button type="text" size="sm">Start shopping</Button>}
              />

              <pre className="mt-2">
                {'<Empty \n header={<Icon id="ion-ios-cart-outline" size={32} />} \n text={<h5>Your cart is empty.</h5>} \n footer={<Button type="text" size="sm">Start shopping</Button>}\n/>'}
              </pre>
            </Card>
          </Col>
        </Row>
      </Grid>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={emptyApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default EmptyShowcase;
