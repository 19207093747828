import React, { useState } from 'react';
import { Card, Button, Table, Dropdown, Grid } from 'react-fidelity-ui';
import dropdownBodyApi from '../../../config/components/dropdownBodyApi';
import apiColumns from '../../../config/apiColumns';
import dummyText from '../../../mock/dummyText';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const DropdownBodyShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);
  const [isEOn, setIsEOn] = useState(false);
  const [isFOn, setIsFOn] = useState(false);
  const [isGOn, setIsGOn] = useState(false);
  const [isHOn, setIsHOn] = useState(false);
  const [isIOn, setIsIOn] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Body</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Dropdown } from 'react-fidelity-ui';
            
const { Body } = Dropdown;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component is part of the
          {' '}
          <code>Dropdown</code>
          {' '}
          composite component.
          {' '}
          <code>Body</code>
          {' '}
          can be used to wrap content within a dropdown.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Sizes</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isAOn}
                onClickOutside={() => {
                  if (isAOn) {
                    setIsAOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsAOn(!isAOn)}>
                  Auto
                </Button>

                <Dropdown.Body size="auto">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Auto
  </Button>

  <Dropdown.Body size="auto">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isBOn}
                onClickOutside={() => {
                  if (isBOn) {
                    setIsBOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsBOn(!isBOn)}>
                  Extra Small
                </Button>

                <Dropdown.Body size="xs">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Extra Small
  </Button>
  
  <Dropdown.Body size="xs">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isCOn}
                onClickOutside={() => {
                  if (isCOn) {
                    setIsCOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsCOn(!isCOn)}>
                  Small
                </Button>

                <Dropdown.Body size="sm">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Small
  </Button>
  
  <Dropdown.Body size="sm">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isDOn}
                onClickOutside={() => {
                  if (isDOn) {
                    setIsDOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsDOn(!isDOn)}>
                  Medium
                </Button>

                <Dropdown.Body size="md">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Medium
  </Button>
  
  <Dropdown.Body size="md">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col className="mb-2">
              <Dropdown
                active={isEOn}
                onClickOutside={() => {
                  if (isEOn) {
                    setIsEOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsEOn(!isEOn)}>
                  Large
                </Button>

                <Dropdown.Body size="lg">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Medium
  </Button>
  
  <Dropdown.Body size="lg">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col>
              <Dropdown
                active={isFOn}
                onClickOutside={() => {
                  if (isFOn) {
                    setIsFOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsFOn(!isFOn)}>
                  Extra Large
                </Button>

                <Dropdown.Body size="xl">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Medium
  </Button>
  
  <Dropdown.Body size="xl">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isGOn}
                onClickOutside={() => {
                  if (isGOn) {
                    setIsGOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsGOn(!isGOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  {dummyTextShort}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
    
  <Dropdown.Body size="md">
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isHOn}
                onClickOutside={() => {
                  if (isHOn) {
                    setIsHOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsHOn(!isHOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  <p>{dummyTextShort}</p>
                  <p>{dummyText}</p>
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
    
  <Dropdown.Body size="md">
    <p>Lorem ipsum dolor sit...</p>
    <p>Lorem ipsum dolor sit...</p>
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isIOn}
                onClickOutside={() => {
                  if (isIOn) {
                    setIsIOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsIOn(!isIOn)}>
                  Toggle body with max height of 10rem
                </Button>

                <Dropdown.Body
                  size="md"
                  style={{ maxHeight: '10rem' }}
                >
                  {dummyText}
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
    
  <Dropdown.Body
    size="md"
    style={{ maxHeight: '10rem' }}
  >
    Lorem ipsum dolor sit...
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown>
                <p>Dropdown without a body</p>
              </Dropdown>

              <pre>
                {`<Dropdown>
  <p>Dropdown without a body</p>
</Dropdown>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={dropdownBodyApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default DropdownBodyShowcase;
