import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout } from 'react-fidelity-ui';
import AppNav from './AppNav';
import AppLogo from './AppLogo';
import app from '../config/app';
import { isNonEmptyStr } from '../utils';

const { Header, Footer, Container } = Layout;

const AppLayout = ({ children, className }) => {
  const year = new Date().getFullYear();
  const componentClassName = classNames({
    'app-layout': true,
    [className]: isNonEmptyStr(className)
  });

  return (
    <Layout className={componentClassName}>
      <Header sticky>
        <Container className="justify-content-center">
          <div>
            <AppLogo />
            <AppNav />
          </div>
        </Container>
      </Header>

      <div className="app-container">
        <Container>
          {children}
        </Container>
      </div>

      <Footer className="app-footer">
        <div>
          <Container>
            <strong>
              <a
                href={app.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {app.name}
              </a>
              {' © '}
              {year}
            </strong>
            <br />
            <small>
              {'Created by '}
              <a
                href={app.creatorLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {app.creator}
              </a>
            </small>
          </Container>
        </div>
      </Footer>
    </Layout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

AppLayout.defaultProps = {
  className: null
};

export default AppLayout;
