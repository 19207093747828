import React, { useState } from 'react';
import { InputGroup, Email, Textarea, Icon, Button, Card, Checkbox } from 'react-fidelity-ui';

const SendFeedback = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2 className="text-align--center">
        {'Send Feedback'}
      </h2>

      <form>
        <InputGroup>
          <Textarea
            value={message}
            label="Your message"
            placeholder="Message"
            hovered
            onChange={({ target }) => {
              setMessage(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="E-mail"
            icon={<Icon id="ion-email" />}
            hovered
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Checkbox
            checked={isChecked}
            label="Send me a copy"
            onChange={({ target }) => {
              setIsChecked(target.checked);
            }}
          />
        </InputGroup>

        <Button
          type="text"
          className="mt-4"
          expanded
          onClick={onClick}
        >
          {'Send Feedback'}
        </Button>
      </form>
    </Card>
  )
};

export default SendFeedback;
