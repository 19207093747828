import React from 'react';
import { Card, CardDivider, Button, ButtonGroup, Grid, Table, Icon } from 'react-fidelity-ui';
import buttonApi from '../../../config/components/buttonApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const ButtonShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          {'Button'}
        </h2>

        <small>
          <span>
            Composite stateless functional component
          </span>
          <br />
          <code>
            {`import { Button } from 'react-fidelity-ui';`}
          </code>
        </small>

        <p className="mt-3 mb-0">
          {'Supports all native button attributes and adds several unique ones to augment button functionality.'}
        </p>
      </Card>

      <h2>
        {'Types'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button>
                  {'Default'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button>\n  Default\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="primary">
                  {'Primary'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="primary">\n  Primary\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="secondary">
                  {'Secondary'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="secondary">\n  Secondary\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="info">
                  {'Info'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="info">\n  Info\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="success">
                  {'Success'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="success">\n  Success\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="warning">
                  {'Warning'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="warning">\n  Warning\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="danger">
                  {'Danger'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="danger">\n  Danger\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="link">
                  {'Link'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="link">\n  Link\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="text">
                  {'Text'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="text">\n  Text\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="icon">
                  {'Icon'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="icon">\n  Icon\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="facebook">
                  {'Facebook'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="facebook">\n  Facebook\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-6']}>
              <ButtonGroup>
                <Button type="google">
                  {'Google'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button type="google">\n  Google\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Sizes'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button size="sm">
                  {'Small'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button size="sm">\n  Small\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button>
                  {'Normal'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button>\n  Normal\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button size="lg">
                  {'Large'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button size="lg">\n  Large\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'States'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button active>
                  {'Active'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button active>\n  Active\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button disabled>
                  {'Disabled'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button disabled>\n  Disabled\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button loading>
                  {'Loading'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button loading>\n  Normal\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button outlined>
                  {'Outlined'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button outlined>\n  Outlined\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button expanded>
                  {'Expanded'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button expanded>\n  Expanded\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button rounded>
                  {'Rounded'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button rounded>\n  Rounded\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button squared>
                  {'Squared'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button squared>\n  Squared\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button fab>
                  {'Fab'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button fab>\n  Fab\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button bordered>
                  {'Bordered'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button bordered>\n  Bordered\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button flat>
                  {'Flat'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button flat>\n  Flat\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button hovered>
                  {'Hovered'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button hovered>\n  Hovered\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button outlined dashed>
                  {'Dashed'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button outlined dashed>\n  Dashed\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'Examples'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button outlined disabled rounded>
                  {'Click me'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n outlined \n disabled \n rounded\n>\n  Click me\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  size="sm"
                  fab
                  hovered
                >
                  {'OK'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n size="sm" \n fab \n hovered\n>\n  OK\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="warning"
                  size="lg"
                  loading
                  outlined
                >
                  {'Loading'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="warning" \n size="lg" \n loading \n outlined\n>\n  Loading\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="danger"
                  size="sm"
                  rounded
                >
                  {'Submit'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="danger" \n size="sm" \n rounded\n>\n  Submit\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button
                  type="icon"
                  size="lg"
                >
                  <Icon id="ion-plus" />
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="icon" \n size="lg"\n>\n  <Icon id="ion-plus" />\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button
                  type="primary"
                  outlined
                  dashed
                >
                  {'Save'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="primary" \n outlined \n dashed\n>\n  Save\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-4']}>
              <ButtonGroup>
                <Button
                  type="warning"
                  expanded
                  rounded
                >
                  {'Confirm'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="warning" \n expanded \n rounded\n>\n  Confirm\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>

        <CardDivider />

        <Grid>
          <Row>
            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="primary"
                  size="lg"
                  flat={false}
                  hovered
                >
                  {'Agree'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="primary" \n size="lg" \n flat={false} \n hovered\n>\n  Agree\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="text"
                  size="lg"
                  outlined
                  fab
                >
                  {'Add'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="text" \n size="lg" \n outlined \n fab\n>\n  Add\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="info"
                  expanded
                  outlined
                >
                  {'Next'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="info" \n expanded \n outlined\n>\n  Next\n</Button>'}
              </pre>
            </Col>

            <Col widths={['md-12', 'lg-3']}>
              <ButtonGroup>
                <Button
                  type="success"
                  size="lg"
                  flat={false}
                  rounded
                  outlined
                  dashed
                  hovered
                >
                  {'Proceed'}
                </Button>
              </ButtonGroup>

              <pre>
                {'<Button \n type="success" \n size="lg" \n flat={false} \n rounded \n outlined \n dashed \n hovered\n>\n  Proceed\n</Button>'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={buttonApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default ButtonShowcase;
