import React, { useState } from 'react';
import { InputGroup, Input, Email, Password, Icon, Button, Divider, Select, Card } from 'react-fidelity-ui';
import { validateEmail } from '../../../utils/validators';
import countries from '../../../config/countries';

const SignUp = () => {
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('United Kingdom');
  const [age, setAge] = useState(18);
  const [isNicknameTouched, setIsNicknameTouched] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [isConfirmPasswordTouched, setIsConfirmPasswordTouched] = useState(false);
  const [isAgeTouched, setIsAgeTouched] = useState(false);
  const isEmailFilled = email !== '';
  const isValidEmail = validateEmail(email);
  const isNicknameFilled = nickname !== '';
  const isPasswordFilled = password !== '';
  const isConfirmPasswordFilled = confirmPassword !== '';
  const isConfirmPasswordSameAsPassword = confirmPassword === password;
  const isValidAge = age >= 18;
  const nicknameTitle = isNicknameTouched && !isNicknameFilled
    ? 'Nickname is required.'
    : null;
  const passwordTitle = isPasswordTouched && !isPasswordFilled
    ? 'Password is required.'
    : null;
  const confirmPasswordTitle = isConfirmPasswordTouched && !isConfirmPasswordFilled
    ? 'Confirm password is required.'
    : isConfirmPasswordTouched && !isConfirmPasswordSameAsPassword
      ? 'Confirm password must match password.'
      : null;
  const ageTitle = isAgeTouched && !isValidAge
    ? 'You must be at least 18 years old.'
    : null;
  const isDisabled = !isValidEmail
    || !isNicknameFilled
    || !isPasswordFilled
    || !isConfirmPasswordFilled
    || !isConfirmPasswordSameAsPassword
    || !isValidAge;

  const countryOptions = countries.map(country => ({
    value: country,
    label: country
  }));

  const ageOptions = Array.from({ length: 112 })
    .map((age, index) => ({
      value: index,
      label: index
    }))
    .filter((age, index) => index > 11);

  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Sign Up'}
      </h2>

      <form>
        <InputGroup>
          <Input
            value={nickname}
            label="Nickname"
            placeholder="Enter your nickname..."
            title={nicknameTitle}
            icon={<Icon id="ion-person" />}
            validated={isNicknameTouched}
            valid={isNicknameFilled}
            onChange={({ target }) => {
              setIsNicknameTouched(true);
              setNickname(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Email
            value={email}
            label="Your e-mail"
            placeholder="Enter your email..."
            icon={<Icon id="ion-email" />}
            validated={isEmailFilled}
            valid={!isEmailFilled || isValidEmail}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Password
            value={password}
            label="Password"
            placeholder="Enter your password..."
            title={passwordTitle}
            icon={<Icon id="ion-android-lock" />}
            validated={isPasswordTouched}
            valid={isPasswordFilled}
            onChange={({ target }) => {
              setIsPasswordTouched(true);
              setPassword(target.value);
            }}
          />
        </InputGroup>

        <InputGroup>
          <Password
            value={confirmPassword}
            label="Confirm Password"
            placeholder="Confirm your password..."
            title={confirmPasswordTitle}
            icon={<Icon id="ion-android-lock" />}
            validated={isConfirmPasswordTouched}
            valid={isConfirmPasswordFilled && isConfirmPasswordSameAsPassword}
            onChange={({ target }) => {
              setIsConfirmPasswordTouched(true);
              setConfirmPassword(target.value);
            }}
          />
        </InputGroup>

        <Divider />

        <InputGroup>
          <Select
            value={age}
            options={ageOptions}
            label="Your age"
            placeholder="Select age"
            title={ageTitle}
            validated={isAgeTouched}
            valid={isValidAge}
            onChange={({ target }) => {
              setIsAgeTouched(true);
              setAge(target.value)
            }}
          />
        </InputGroup>

        <InputGroup className="mb-4">
          <Select
            value={country}
            options={countryOptions}
            label="Country"
            placeholder="Select country"
            validated
            valid
            onChange={({ target }) => {
              setCountry(target.value)
            }}
          />
        </InputGroup>

        <Button
          type="primary"
          size="lg"
          expanded
          rounded
          disabled={isDisabled}
          onClick={onClick}
        >
          {'Sign Up'}
        </Button>
      </form>
    </Card>
  )
};

export default SignUp;
