import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavMenu, NavMenuItem, NavTrigger, Button, Icon } from 'react-fidelity-ui';
import AppLogo from './AppLogo';
import navItems from '../config/nav';

const { location } = window;

const AppNav = () => {
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isTriggerHovered, setIsTriggerHovered] = useState(false);
  const { pathname } = location;
  const onClick = () => {
    if (!isHovered && !isTriggerHovered) {
      setIsActive(false);
    }
  };
  const onKeyDown = ({ key }) => {
    if (key === 'Escape' && isActive) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  });

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  });

  return (
    <Nav active={isActive}>
      <NavTrigger
        renderTrigger={(className) => (
          <Button
            type="icon"
            className={className}
            onClick={() => {
              setIsActive(!isActive);
            }}
            onMouseEnter={() => {
              setIsTriggerHovered(true);
            }}
            onMouseLeave={() => {
              setIsTriggerHovered(false);
            }}
          >
            <Icon
              id="ion-android-menu"
              size={24}
            />
          </Button>
        )}
      />

      <NavMenu
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <NavMenuItem home>
          <AppLogo justifyCenter={false} />
        </NavMenuItem>

        {navItems.map(({ name, path, label }) => (
          <NavMenuItem
            key={`nav-item-${name}`}
            active={pathname.indexOf(path) > -1}
          >
            <Link to={path}>
              {label}
            </Link>
          </NavMenuItem>
        ))}
      </NavMenu>
    </Nav>
  );
};

export default AppNav;
