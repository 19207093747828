import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardDivider, Table, Grid, Icon } from 'react-fidelity-ui';
import iconApi from '../../../config/components/iconApi';
import apiColumns from '../../../config/apiColumns';

const { Row, Col } = Grid;

const IconShowcase = () => {
  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Icon
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {'import { Icon } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          SVG icon wrapper that uses the global <code>iconsSprite</code> state from the <Link to="/components/app"><code>App</code></Link> component.<br />
          To reference an icon from the provided <code>iconsSprite</code>, the prop <code>id</code> is used.
        </p>

        <CardDivider />

        <div className="d-flex align-items-center">
          <Icon
            id="ion-alert"
            size={48}
            className="color--secondary"
          />
          <p className="font-size--lg mb-0 ml-2">This demo uses <a href="https://ionicons.com/" target="_blank" rel="noopener noreferrer">ion icons</a> in a <a href="https://rastasheep.com/ionicons-sprite/" target="_blank" rel="noopener noreferrer">sprite format</a>.</p>
        </div>
      </Card>

      <h2>
        {'Sizes'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={4} />

              <pre>
                {'<Icon id="ion-music-note" size={4} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={8} />

              <pre>
                {'<Icon id="ion-music-note" size={8} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={12} />

              <pre>
                {'<Icon id="ion-music-note" size={12} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={16} />
              <small>(default)</small>

              <pre>
                {'<Icon id="ion-music-note" size={16} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={20} />

              <pre>
                {'<Icon id="ion-music-note" size={20} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={24} />

              <pre>
                {'<Icon id="ion-music-note" size={24} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={28} />

              <pre>
                {'<Icon id="ion-music-note" size={28} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={32} />

              <pre>
                {'<Icon id="ion-music-note" size={32} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={36} />

              <pre>
                {'<Icon id="ion-music-note" size={36} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={40} />

              <pre>
                {'<Icon id="ion-music-note" size={40} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={44} />

              <pre>
                {'<Icon id="ion-music-note" size={44} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={48} />

              <pre>
                {'<Icon id="ion-music-note" size={48} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={52} />

              <pre>
                {'<Icon id="ion-music-note" size={52} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={56} />

              <pre>
                {'<Icon id="ion-music-note" size={56} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={60} />

              <pre>
                {'<Icon id="ion-music-note" size={60} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" size={64} />

              <pre>
                {'<Icon id="ion-music-note" size={64} />'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>Rotate</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row className="mb-2">
            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={45} />

              <pre>
                {'<Icon id="ion-music-note" rotate={45} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={90} />

              <pre>
                {'<Icon id="ion-music-note" rotate={90} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={135} />

              <pre>
                {'<Icon id="ion-music-note" rotate={135} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={180} />

              <pre>
                {'<Icon id="ion-music-note" rotate={180} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={225} />

              <pre>
                {'<Icon id="ion-music-note" rotate={225} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={270} />

              <pre>
                {'<Icon id="ion-music-note" rotate={270} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={315} />

              <pre>
                {'<Icon id="ion-music-note" rotate={315} />'}
              </pre>
            </Col>

            <Col widths={['sm-12', 'md-4', 'lg-3']}>
              <Icon id="ion-music-note" rotate={360} />

              <pre>
                {'<Icon id="ion-music-note" rotate={360} />'}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>
        {'API'}
      </h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={iconApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default IconShowcase;
