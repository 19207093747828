import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Icon } from 'react-fidelity-ui';
import classNames from 'classnames';
import app from '../../config/app';
import logo from '../../images/logo-circle.png';

const AppLogo = ({ alignCenter, justifyCenter }) => {
  const linkClassName = classNames({
    'd-flex': true,
    'color--secondary': true,
    'align-items-center': alignCenter,
    'justify-content-center': justifyCenter
  });

  return (
    <div className="app-logo h2 mb-0 mb-lg-2">
      <Link
        to="/"
        className={linkClassName}
      >
        <Image
          src={logo}
          alt="logo"
          className="bg--white mr-2"
          spinner={(
            <Icon
              id="ion-load-c"
              className="color--secondary"
              spinning
            />
          )}
        />

        {app.name}
      </Link>
    </div>
  );
};

AppLogo.propTypes = {
  alignCenter: PropTypes.bool,
  justifyCenter: PropTypes.bool
};

AppLogo.defaultProps = {
  alignCenter: true,
  justifyCenter: true
};

export default AppLogo;
