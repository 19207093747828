import React, { useState } from 'react';
import { InputGroup, Input, Email, Password, Icon, Button, Card } from 'react-fidelity-ui';

const SignUpForFree = () => {
  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      paddingSize="lg"
      className="bg-image city-bg"
      hovered
      bordered={false}
    >
      <h2 className="text-align--center color--white">
        {'Sign Up For Free'}
      </h2>

      <form>
        <InputGroup isRow>
          <Input
            value={accountName}
            placeholder="Account name"
            icon={<Icon id="ion-person" />}
            onChange={({ target }) => {
              setAccountName(target.value);
            }}
          />

          <Email
            value={email}
            placeholder="E-mail"
            icon={<Icon id="ion-email" />}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
          />

          <Password
            value={password}
            placeholder="Password"
            icon={<Icon id="ion-android-lock" />}
            onChange={({ target }) => {
              setPassword(target.value);
            }}
          />

          <Button
            type="secondary"
            onClick={onClick}
          >
            {'Sign Up'}
          </Button>
        </InputGroup>
      </form>
    </Card>
  )
};

export default SignUpForFree;
