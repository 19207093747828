import React, { useState } from 'react';
import { Card, Button, Table, Dropdown, Grid } from 'react-fidelity-ui';
import dropdownTextApi from '../../../config/components/dropdownTextApi';
import apiColumns from '../../../config/apiColumns';
import dummyText from '../../../mock/dummyText';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const DropdownTextShowcase = () => {
  const [isAOn, setIsAOn] = useState(false);
  const [isBOn, setIsBOn] = useState(false);
  const [isCOn, setIsCOn] = useState(false);
  const [isDOn, setIsDOn] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>Text</h2>

        <small>
          <span>Stateless functional component</span>
          <br />
          <pre>
            {`import { Dropdown } from 'react-fidelity-ui';
            
const { Text } = Dropdown;`}
          </pre>
        </small>

        <p className="mt-3 mb-0">
          This component is part of the
          {' '}
          <code>Dropdown</code>
          {' '}
          composite component.
          {' '}
          <code>Text</code>
          {' '}
          can be used to wrap text nodes like
          {' '}
          <code>h2</code>
          {' '}
          and
          {' '}
          <code>p</code>
          {' '}
          that you may want to display within a dropdown.
        </p>
      </Card>

      <Card
        hovered
        bordered={false}
      >
        <p>The examples below use:</p>

        <pre>
          {`import { useState } from 'react';
import { Button } from 'react-fidelity-ui';
      
const [active, setActive] = useState(false);
const toggle = () => setActive(!active);
const activate = () => setActive(true);
const deactivate = () => {
  if (active) {
    setActive(false);
  }
};`}
        </pre>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <Grid>
          <Row>
            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isAOn}
                onClickOutside={() => {
                  if (isAOn) {
                    setIsAOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsAOn(!isAOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  <Dropdown.Text>
                    <h4 className="mb-0">Amazing Title</h4>
                  </Dropdown.Text>

                  <Dropdown.Text>
                    {dummyTextShort}
                  </Dropdown.Text>
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    <Dropdown.Text>
      <h4 className="mb-0">Amazing Title</h4>
    </Dropdown.Text>
  
    <Dropdown.Text>
      Lorem ipsum dolor sit...
    </Dropdown.Text>
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isBOn}
                onClickOutside={() => {
                  if (isBOn) {
                    setIsBOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsBOn(!isBOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  <Dropdown.Text>
                    <h4 className="mb-0">Brilliant Title</h4>
                  </Dropdown.Text>

                  <Dropdown.Text>
                    {dummyTextShort}
                  </Dropdown.Text>

                  <Dropdown.Text>
                    <h5 className="mb-0">Another Title</h5>
                  </Dropdown.Text>

                  <Dropdown.Text>
                    <p>{dummyTextShort}</p>

                    <Button type="info">Ok</Button>
                  </Dropdown.Text>
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    <Dropdown.Text>
      <h4 className="mb-0">Brilliant Title</h4>
    </Dropdown.Text>
  
    <Dropdown.Text>
      Lorem ipsum dolor sit...
    </Dropdown.Text>
    
    <Dropdown.Text>
      <h5 className="mb-0">Another Title</h5>
    </Dropdown.Text>
  
    <Dropdown.Text>
      <p>Lorem ipsum dolor sit...</p>

      <Button type="info">Ok</Button>
    </Dropdown.Text>
  </Dropdown.Body> 
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isCOn}
                onClickOutside={() => {
                  if (isCOn) {
                    setIsCOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsCOn(!isCOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  <Dropdown.Text>
                    <Dropdown.Text className="p-0 pb-2">
                      {dummyText}
                    </Dropdown.Text>

                    <Dropdown.Text className="p-0">
                      {dummyTextShort}
                    </Dropdown.Text>
                  </Dropdown.Text>
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
    
  <Dropdown.Body size="md">
    <Dropdown.Text>
      <Dropdown.Text className="p-0 pb-2">
        Lorem ipsum dolor sit...
      </Dropdown.Text>
  
      <Dropdown.Text className="p-0">
        Lorem ipsum dolor sit...
      </Dropdown.Text>
    </Dropdown.Text>
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>

            <Col
              widths={['md-12', 'lg-6']}
              className="mb-2"
            >
              <Dropdown
                active={isDOn}
                onClickOutside={() => {
                  if (isDOn) {
                    setIsDOn(false);
                  }
                }}
              >
                <Button onClick={() => setIsDOn(!isDOn)}>
                  Toggle
                </Button>

                <Dropdown.Body size="md">
                  <Dropdown.Text>
                    {dummyTextShort}
                  </Dropdown.Text>
                </Dropdown.Body>
              </Dropdown>

              <pre>
                {`<Dropdown 
  active={active}
  onClickOutside={deactivate}
>
  <Button onClick={toggle}>
    Toggle
  </Button>
  
  <Dropdown.Body size="md">
    <Dropdown.Text>
      Lorem ipsum dolor sit...
    </Dropdown.Text>
  </Dropdown.Body>
</Dropdown>`}
              </pre>
            </Col>
          </Row>
        </Grid>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={dropdownTextApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default DropdownTextShowcase;
