import React, { useState } from 'react';
import { InputGroup, Card, Button, Input, Tag, Empty, Icon, Fillable } from 'react-fidelity-ui';

const Skills = () => {
  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([]);
  const isSkillFilled = skill !== '';
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const nextSkills = [...skills, skill];
    setSkills(nextSkills);
    setSkill('');
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Skills'}
      </h2>

      <form>
        {skills.length === 0 ? (
          <Empty
            size="sm"
            text={
              <h4>{'No skills added'}</h4>
            }
          />
        ) : (
          <Fillable>
            {skills.map(skill => (
              <Tag
                key={`skill-${skill}`}
                label={skill}
                className="mr-1 mb-1"
                removeIcon={<Icon id="ion-android-close" size={16} />}
                onRemove={() => {
                  const nextSkills = skills.filter(s => s !== skill);
                  setSkills(nextSkills);
                }}
              />
            ))}
          </Fillable>
        )}

        <InputGroup
          className="mt-2"
          isRow
        >
          <Input
            value={skill}
            placeholder="New Skill"
            className="mr-0"
            onChange={({ target }) => {
              setSkill(target.value);
            }}
          />

          <Button
            type="info"
            disabled={!isSkillFilled}
            onClick={onClick}
          >
            {'Add'}
          </Button>
        </InputGroup>
      </form>
    </Card>
  );
};

export default Skills;
