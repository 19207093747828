import React from 'react';
import { Grid, Card } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const Typography = () => {
  return (
    <Grid>
      <Row className="flex-wrap">
        <Col>
          <Card
            hovered
            bordered={false}
          >
            <small>
              {'Font family'}
            </small>
            <h1>
              {'Montserrat'}
            </h1>
            <small>
              {'Heading 1'}
            </small>
            <h1>
              {'Say Hello to Your New Site!'}
            </h1>
            <small>
              {'Heading 2'}
            </small>
            <h2>
              {'It was some time before he obtained any...'}
            </h2>
            <small>
              {'Heading 3'}
            </small>
            <h3>
              {'At length one of them called out in a clear, polite, smooth dialect, not unlike...'}
            </h3>
            <small>
              {'Heading 4'}
            </small>
            <h4>
              {'Just then her head struck against the roof of the hall: in fact she was now more than nine feet high, and...'}
            </h4>
            <small>
              {'Paragraphs'}
            </small>
            <p>
              {'Alice wondered a little at this, but she was too much in awe of the Queen to disbelieve it. \'I\'ll try it when I go home,\' she thought to herself, \'the next time I\'m a little late for dinner.\' She glanced rather shyly at the real Queen as she said this, but her companion only smiled pleasantly, and said, \'That\'s easily managed. You can be the White Queen\'s Pawn, if you like, as Lily\'s too young to play; and you\'re in the Second Square to begin with: when you get to the Eighth Square you\'ll be a Queen - \' Just at this moment, somehow or other, they began to run.'}
            </p>

            <p>
              {'There have not been any since we have lived here, said my mother. We thought - Mr. Copperfield thought - it was quite a large rookery; but the nests were very old ones, and the birds have deserted them a long while. My aunt said never a word, but took her bonnet by the strings, in the manner of a sling, aimed a blow at Mr. Chillip\'s head with it, put it on bent, walked out, and never came back . She vanished like a discontented fairy; or like one of those supernatural beings, whom it was popularly supposed I was entitled to see; and never came back any more.'}
            </p>

            <p style={{ fontStyle: 'italic' }}>
              {'This sounded a very good reason, and Alice was quite pleased to know it. \'I never thought of that before!\' she said.\tSo Alice picked him up very gently, and lifted him across more slowly than she had lifted the Queen, that she mightn\'t take his breath away: but, before she put him on the table, she thought she might as well dust him a little, he was so covered with ashes.'}
            </p>
          </Card>
        </Col>
      </Row>
    </Grid>
  );
};

export default Typography;
