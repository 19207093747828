import React from 'react';
import { Card, Button, Grid, Transition, ButtonGroup } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const CardA = () => {
  return (
    <div className="bg-image e-commerce-a-bg mb-3">
      <Card
        paddingSize="lg"
        className="bg--transparent color--white mb-0"
        hovered
        bordered={false}
      >
        <Transition type="fade-in-right">
          <Grid>
            <Row>
              <Col widths={['md-12', 'lg-10']}>
                <h1>
                  {'Raglan Skeeve T-shirt'}
                </h1>

                <p className="color--black mb-10">
                  {'As we passed, I remarked a beautiful church-spire rising above some elms in the park.'}
                </p>

                <ButtonGroup stacked rounded>
                  <Button
                    type="text"
                    outlined
                  >
                    {'$29.99'}
                  </Button>
                  <Button>
                    {'Buy Now'}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Grid>
        </Transition>
      </Card>
    </div>
  );
};

export default CardA;
