import React, { useState } from 'react';
import { Card, Table, Collapse, Button, Grid } from 'react-fidelity-ui';
import collapseApi from '../../../config/components/collapseApi';
import apiColumns from '../../../config/apiColumns';
import dummyTextShort from '../../../mock/dummyTextShort';

const { Row, Col } = Grid;

const CollapseShowcase = () => {
  const [isActiveA, setIsActiveA] = useState(false);
  const [isActiveB, setIsActiveB] = useState(false);
  const [isActiveC, setIsActiveC] = useState(false);
  const [isActiveD, setIsActiveD] = useState(false);

  return (
    <div className="mt-2">
      <Card
        hovered
        bordered={false}
      >
        <h2>
          Collapse
        </h2>

        <small>
          <span>
            Stateless functional component
          </span>
          <br />
          <code>
            {'import { Collapse } from \'react-fidelity-ui\';'}
          </code>
        </small>

        <p className="mt-3 mb-0">
          Standard collapse component that toggles the visibility of child nodes.
        </p>
      </Card>

      <h2>Examples</h2>

      <Card
        hovered
        bordered={false}
      >
        <div className="mb-2">
          <Button onClick={() => setIsActiveA(!isActiveA)}>
            Toggle on click
          </Button>

          <Collapse active={isActiveA}>
            {dummyTextShort}
          </Collapse>

          <pre className="mt-1">
            {`const [active, setActive] = useState(false);

<Button onClick={() => setActive(!active)}>
  Toggle on click
</Button>

<Collapse active={active}>
  ${dummyTextShort.substr(0, 20)}...
</Collapse>`}
          </pre>
        </div>

        <div className="mb-2">
          <Button
            onMouseEnter={() => setIsActiveB(true)}
            onMouseLeave={() => setIsActiveB(false)}
          >
            Toggle on hover
          </Button>

          <Collapse active={isActiveB}>
            {dummyTextShort}
          </Collapse>

          <pre className="mt-1">
            {`const [active, setActive] = useState(false);
<Button
  onMouseEnter={() => setActive(true)}
  onMouseLeave={() => setActive(false)}
>
  Toggle on hover
</Button>

<Collapse
  active={active}
>
  ${dummyTextShort.substr(0, 20)}...
</Collapse>`}
          </pre>
        </div>

        <div className="mb-2">
          <Grid>
            <Row>
              <Col widths={['sm-12', 'md-6']}>
                <Button onClick={() => setIsActiveC(!isActiveC)}>
                  Toggle on click
                </Button>
              </Col>

              <Col widths={['sm-12', 'md-6']}>
                <Collapse active={isActiveC}>
                  {dummyTextShort}
                </Collapse>
              </Col>
            </Row>
          </Grid>

          <pre className="mt-1">
            {`import { Grid } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const [active, setActive] = useState(false);

<Grid>
  <Row>
    <Col widths={['sm-12', 'md-6']}>
      <Button onClick={() => setActive(!active)}>
        Toggle on click
      </Button>
    </Col>
    
    <Col widths={['sm-12', 'md-6']}>
      <Collapse active={active}>
        {dummyTextShort}
      </Collapse>
    </Col>
  </Row>
</Grid>`}
          </pre>
        </div>

        <div className="mb-2">
          <Grid>
            <Row>
              <Col widths={['sm-12', 'md-6']}>
                <Button
                  onMouseEnter={() => setIsActiveD(true)}
                  onMouseLeave={() => setIsActiveD(false)}
                >
                  Toggle on hover
                </Button>
              </Col>

              <Col widths={['sm-12', 'md-6']}>
                <Collapse active={isActiveD}>
                  {dummyTextShort}
                </Collapse>
              </Col>
            </Row>
          </Grid>

          <pre className="mt-1">
            {`import { Grid } from 'react-fidelity-ui';

const { Row, Col } = Grid;

const [active, setActive] = useState(false);

<Grid>
  <Row>
    <Col widths={['sm-12', 'md-6']}>
      <Button
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        Toggle on click
      </Button>
    </Col>
    
    <Col widths={['sm-12', 'md-6']}>
      <Collapse active={active}>
        {dummyTextShort}
      </Collapse>
    </Col>
  </Row>
</Grid>`}
          </pre>
        </div>
      </Card>

      <h2>API</h2>

      <Card
        hovered
        bordered={false}
      >
        <Table
          items={collapseApi}
          columns={apiColumns}
          itemId="property"
          sort={{
            name: 'id',
            asc: true
          }}
        />
      </Card>
    </div>
  );
};

export default CollapseShowcase;
