import React, { useState } from 'react';
import { InputGroup, Card, Button, Radio, Checkbox, Divider } from 'react-fidelity-ui';

const NotificationCenter = () => {
  const [radioValue, setRadioValue] = useState('a');
  const [isChecked, setIsChecked] = useState(true);
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card
      hovered
      bordered={false}
    >
      <h2>
        {'Notification Center'}
      </h2>

      <form>
        <div className="h4 mb-4">
          {'When you\'re on site'}
        </div>

        <div className="mr-2 ml-2">
          <InputGroup>
            <Radio
              label="Get notifications from everyone"
              value="a"
              checked={radioValue === 'a'}
              onChange={({ target }) => {
                setRadioValue(target.value);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Radio
              label="Only people you follow"
              value="b"
              checked={radioValue === 'b'}
              onChange={({ target }) => {
                setRadioValue(target.value);
              }}
            />
          </InputGroup>
        </div>

        <Divider />

        <div className="h4 mb-4">
          {'On your phone'}
        </div>

        <div className="mr-2 ml-2 mb-5">
          <Button
            type="info"
            rounded
            expanded
            outlined
            onClick={onClick}
          >
            {'Edit Notification Settings'}
          </Button>
        </div>

        <div className="h4 mb-4">
          {'On your desktop'}
        </div>

        <div className="mr-2 ml-2 mb-4">
          <InputGroup>
            <Checkbox
              label="Chrome Browser Notifications"
              checked={isChecked}
              isSwitch
              onChange={({ target }) => {
                setIsChecked(target.checked);
              }}
            />
          </InputGroup>
        </div>

        <div className="mr-2 ml-2">
          <Button
            type="info"
            rounded
            expanded
            onClick={onClick}
          >
            {'Save'}
          </Button>
        </div>
      </form>
    </Card>
  )
};

export default NotificationCenter;
