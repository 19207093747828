import React from 'react';
import { Badge } from 'react-fidelity-ui';

const renderBadge = text => (
  <Badge
    type="info"
    size="md"
    outlined
  >
    {text}
  </Badge>
);

export default [{
  property: 'children',
  description: 'content',
  type: 'node | string | number',
  default: null
}, {
  property: 'size',
  description: (
    <span>
      {'can be set to '}
      {renderBadge('auto')}
      {' '}
      {renderBadge('xs')}
      {' '}
      {renderBadge('sm')}
      {' '}
      {renderBadge('md')}
      {' '}
      {renderBadge('lg')}
      {' '}
      {renderBadge('xl')}
    </span>
  ),
  type: 'string',
  default: 'auto'
}];
